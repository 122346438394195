import { GoogleMap, Polygon } from "@react-google-maps/api"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"
import { Badge, Card, CardBody, Col, Row } from "reactstrap"
import {
  calclulateCenter,
  containerStyle,
  getZoneTypeColor,
  polygonOptions,
} from "./common"

const OperatingZoneListItem = ({ zone }) => {
  const center = calclulateCenter(zone.zoneBoundaries)
  const defaultCenter = center || zone.zoneBoundaries[0]
  return (
    <React.Fragment>
      <Row key={zone.zoneName}>
        <Card>
          <Link
            to={`/zone-detail/${zone.zoneName}?type=operation`}
            // target="_blank"
            // className="btn-md btn align-end"
          >
            <CardBody>
              <Row>
                <Col md={8} sm={12} xs={12} className={"mb-4"}>
                  <GoogleMap
                    center={defaultCenter}
                    mapContainerStyle={containerStyle}
                    zoom={13}
                    options={{
                      disableDefaultUI: true,
                    }}
                  >
                    <Polygon
                      paths={zone.zoneBoundaries || []}
                      options={polygonOptions}
                    />
                  </GoogleMap>
                </Col>
                <Col sm={12} md={4} className="px-3">
                  <Row className="d-flex flex-column h-100">
                    <div className="my-auto">
                      <div>
                        <h3>{zone.zoneName}</h3>
                        {/* <p className="fs-6 mb-1">Zone type: {zone.zoneType}</p> */}

                        <Badge
                          className="mb-3 font-size-12 p-2 text-capitalize"
                          color={getZoneTypeColor(zone.zoneType)}
                          pill
                          // style={{ width: "6rem" }}
                        >
                          {zone.zoneType} Zone
                        </Badge>
                      </div>
                      <div className="mt-4"></div>
                    </div>
                  </Row>
                  
                </Col>
              </Row>
            </CardBody>
          </Link>
        </Card>
      </Row>
    </React.Fragment>
  )
}

OperatingZoneListItem.propTypes = {
  zone: PropTypes.object,
  center: PropTypes.object,
}

export default OperatingZoneListItem
