import React, { useState, useRef } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter"
import { Card, CardBody, Col, Row, Badge, Button } from "reactstrap"
import Modal from "components/Common/Modal"
import ConfirmModal from "components/Common/ConfirmModal"
import FormBlockCustomer from "./form-block-customer"
import FormActivateEmployee from "./form-activate-employee"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import Restricted from "components/Common/Restricted"
import { showToastMessage } from "components/Common/ToastWrapper"

import useQuery from "hooks/useQuery"
import { useSelector, useDispatch } from "react-redux"
import { deactivateCustomer, editCustomer } from "store/actions"
import { updateCustomer, createCustomerBlockLog } from "helpers/backend_helper"

const CustomersTable = ({ customers, loading }) => {
  let url = new URL(window.location.href)
  const dispatch = useDispatch()
  const query = useQuery()
  const { account } = useSelector(state => ({
    account: state.Profile.account,
  }))

  const sizePerPage = 10
  const page = query.get("page") ? Number(query.get("page")) : 1
  const pageSize = query.get("pageSize")
    ? Number(query.get("pageSize"))
    : sizePerPage
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)
  const [customerBlockModal, setCustomerBlockModal] = useState(false)
  const [customerUnblockModal, setCustomerUnblockModal] = useState(false)
  const [activateModal, setActivateModal] = useState(false)
  const [deactivateModal, setDeactivateModal] = useState(false)
  const [user, setUser] = useState({})
  const [sortBy, setSortBy] = useState({
    field: null,
    order: null,
  })
  const { SearchBar } = Search
  var node = useRef()

  const handleSort = (field, order) => {
    setSortBy({
      field,
      order,
    })
    return true
  }

  const customTotal = (from, to, size) => (
    <TotalPreview
      from={from}
      to={to}
      loading={loading}
      size={!loading && size}
      entity="Employees"
    />
  )

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
          url.searchParams.set("pageSize", page)
          window.history.replaceState(null, null, url)
        }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )
  const pageListRenderer = ({ pages, onPageChange }) => {
    return (
      <div>
        {pages.map((p, i) => (
          <button
            key={i}
            onMouseDown={() => {
              e.preventDefault()
              onPageChange(p.page)
              url.searchParams.set("page", p.page)
              window.history.replaceState(null, null, url)
              setCurrentPage(p.page)
            }}
          >
            {p.page}
          </button>
        ))}
      </div>
    )
  }
  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: customers?.length,
    custom: true,
    paginationTotalRenderer: customTotal,
    sizePerPageOptionRenderer,
    pageListRenderer: pageListRenderer,
    page: currentPage,
  }

  const keyField = "contact"

  const userListColumns = [
    {
      dataField: "name",
      text: "Full Name",
      sort: handleSort,
      filter: textFilter({
        placeholder: "Search Name",
      }),
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            {user.userName} {user.userSurname}
          </h5>
        </>
      ),
    },
    {
      text: "Phone Number",
      dataField: "contact",
      sort: handleSort, // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            {user.countryCode} {user.contact}
          </h5>
        </>
      ),
      filter: textFilter(),
    },
    {
      text: "Status",
      dataField: "partiallyBlockedAt",
      sort: handleSort, // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) =>
        user?.partiallyBlockedAt?.includes(account?.b2bPartner) ? (
          <Badge color="danger">Blocked</Badge>
        ) : (
          <Badge color="success">Active</Badge>
        ),
      filterValue: (cellContent, user) =>
        `${user?.partiallyBlockedAt?.includes(account?.b2bPartner) ? 0 : 1}`,
      filter: selectFilter({
        options: {
          0: "Blocked",
          1: "Active",
        },
      }),
    },

    {
      dataField: "menu",
      isDummyField: true,
      text: "Actions",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user, rowIndex) => (
        <>
          <Restricted to="customers:VIEW_CUSTOMER" fallback={"-"}>
            <ul className="list-inline user-chat-nav mb-0">
              <li className="list-inline-item d-sm-inline-block">
                <a
                  type="button"
                  color="info"
                  className="btn btn-sm btn-soft-secondary"
                  onClick={() => {
                    openDeactivateModal(user)
                  }}
                  id={"deactivatetooltip"}
                >
                  <i className="mdi mdi-account-minus font-size-16" />{" "}
                  Deactivate
                </a>
              </li>

              <li className="list-inline-item d-sm-inline-block">
                {user?.partiallyBlockedAt?.includes(account.b2bPartner) ? (
                  <a
                    type="button"
                    color="info"
                    className="btn btn-sm btn-soft-success"
                    onClick={() => openUnblockModal(user)}
                    id={"blocktooltip"}
                  >
                    <i className="mdi mdi-account-check align-middle font-size-16" />{" "}
                    Unblock
                  </a>
                ) : (
                  <a
                    type="button"
                    color="info"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => openBlockModal(user)}
                    id={"blocktooltip"}
                  >
                    <i className="mdi mdi-account-cancel align-middle font-size-16" />{" "}
                    Block
                  </a>
                )}
              </li>
            </ul>
          </Restricted>
        </>
      ),
    },
  ]
  const openBlockModal = user => {
    setCustomerBlockModal(true)
    setUser(user)
  }
  const closeBlockModal = () => {
    setCustomerBlockModal(false)
    setUser({})
  }

  const openUnblockModal = user => {
    setCustomerUnblockModal(true)
    setUser(user)
  }

  const closeUnblockModal = () => {
    setCustomerUnblockModal(false)
    setUser({})
  }

  const closeDeactivateModal = () => {
    setDeactivateModal(false)
    setUser({})
  }

  const openDeactivateModal = user => {
    setDeactivateModal(true)
    setUser(user)
  }

  const handleActivateClick = () => {
    setActivateModal(true)
  }

  const unblockCustomer = async () => {
    const update = {
      ...user,
      partiallyBlockedAt: [
        ...user?.partiallyBlockedAt.filter(
          place => place !== account.b2bPartner
        ),
      ],
    }

    dispatch(editCustomer(update))
    const blockLog = {
      reason: "NIL",
      isPartial: true,
    }
    await createCustomerBlockLog(user.userId, blockLog)

    showToastMessage({
      success: true,
      message: "Employee Unblocked successfully",
    })
  }

  const deactivateEmployee = async () => {
    const update = {
      ...user,
      userConfig: {
        configType: "NIL",
        b2bPartner: "NIL",
      },
    }

    await updateCustomer(update.userId, update)
    showToastMessage({
      success: true,
      message: "Employee deactivated successfully",
    })
    dispatch(deactivateCustomer(user))
  }

  return (
    <Card>
      <CardBody>
        {/* Unblock modal */}
        <ConfirmModal
          show={customerUnblockModal}
          confirmContents={{
            action: "Unblock",
            resource: ` this employee?`,
          }}
          onConfirmClick={() => unblockCustomer()}
          onCloseClick={closeUnblockModal}
        />
        {/* Deactivate modal */}
        <ConfirmModal
          show={deactivateModal}
          confirmContents={{
            action: "Deactivate",
            resource: ` this employee?`,
          }}
          onConfirmClick={() => deactivateEmployee()}
          onCloseClick={closeDeactivateModal}
        />
        {/* Activate modal */}
        <Modal
          show={activateModal}
          onCloseClick={() => {
            setActivateModal(false)
          }}
          title={`Activate new employee`}
          size="md"
        >
          <FormActivateEmployee close={() => setActivateModal(false)} />
        </Modal>
        {/* Block modal */}
        <Modal
          show={customerBlockModal}
          onCloseClick={closeBlockModal}
          title={`Block employee?`}
          size="md"
        >
          <FormBlockCustomer
            user={user}
            refresh={() => {}}
            close={() => setCustomerBlockModal(false)}
            showToastMessage={showToastMessage}
          />
        </Modal>
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField={keyField}
          columns={userListColumns}
          // data={customers}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <ToolkitProvider
                keyField={keyField}
                data={customers}
                columns={userListColumns}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row> </Row>
                    <Row className="mb-2">
                      <Col sm="4" className="d-flex">
                        <div className="search-box ms-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <SearchBar {...toolkitProps.searchProps} />
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="dark"
                            className="btn-rounded mb-2"
                            onClick={handleActivateClick}
                          >
                            <i className="mdi mdi-plus me-1" />
                            Activate New Employee
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <PaginationTotalStandalone {...paginationProps} />
                      </Col>
                      <Col lg={6}>
                        <div className="float-end">
                          <SizePerPageDropdownStandalone {...paginationProps} />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive invert-scroll">
                          <BootstrapTable
                            keyField={keyField}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            // selectRow={selectRow}
                            sort={{
                              dataField: sortBy.field,
                              order: sortBy.order,
                            }}
                            classes={
                              "table align-middle table-nowrap table-hover top-horizontal-scroll"
                            }
                            bordered={false}
                            striped={false}
                            responsive
                            ref={node}
                            filter={filterFactory()}
                            filterPosition="top"
                            headerClasses="table-light"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="align-items-md-center mt-3">
                      <Col className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone {...paginationProps} />
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  )
}

CustomersTable.propTypes = {
  customers: PropTypes.array,
  history: PropTypes.object,
  loading: PropTypes.bool,
}

export default withRouter(CustomersTable)
