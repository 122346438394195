import React from "react"
import PropTypes from "prop-types"
import toastr from "toastr"
export const showToastMessage = ({ success, message }) => {
  toastr.options = {
    positionClass: "toast-bottom-center",
    showDuration: 200,
    timeOut: 3000,
    newestOnTop: true,
    closeButton: true,
  }
  if (success) {
    toastr.success(message, "Success")
  } else {
    toastr.error(message, "Error")
  }
}
const ToastWrapper = ({ children }) => {
  return <>{children}</>
}

ToastWrapper.propTypes = {
  children: PropTypes.any,
}

export default ToastWrapper
