/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import {
  Col,
  Card,
  CardBody,
  CardFooter,
  Table,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import SimpleBar from "simplebar-react"
import Modal from "components/Common/Modal"
//redux
import { vehicleStatus, vehicleFields } from "../../constants/vehicleStatus"
import { updateMultiVehicles } from "../../helpers/backend_helper"
import { showToastMessage } from "../../components/Common/ToastWrapper"
import LoadingButton from "components/Common/LoadingButton"
import Restricted from "components/Common/Restricted"
import { getVehicles as onGetVehicles } from "store/actions"

const getChartOptions = status => {
  const statusOptions = vehicleStatus.find(st => st.value == status)

  var options = {
    chart: { sparkline: { enabled: !0 } },
    dataLabels: { enabled: !1 },
    colors: [statusOptions?.colorHex || "#000"],
    plotOptions: {
      radialBar: {
        hollow: { margin: 0, size: "60%" },
        track: { margin: 0 },
        dataLabels: { show: !1 },
      },
    },
  }

  return options
}

const BulkView = ({ selectedVehicles, refetch }) => {
  // const dispatch = useDispatch()
  // const { vehicles: vehiclesList, loading } = useSelector(state => ({
  //   vehicles: state.vehicles.vehicles,
  //   loading: state.vehicles.loading,
  // }))

  const [other_Menu, setother_Menu] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [field, setField] = useState(vehicleFields[0].value)
  const [value, setValue] = useState(vehicleStatus[0].value)
  const [response, setResponse] = useState([])
  const [sortedVehicles, setSortedVehicles] = useState(selectedVehicles)
  const total = selectedVehicles.length
  const scooters = selectedVehicles.filter(
    vehicle => vehicle.vehicleType == "scooter"
  ).length
  const bikes = total - scooters

  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }
  const toggleEdit = () => {
    setEditModal(!editModal)
  }

  const handleFieldChange = e => {
    setField(e.target.value)
  }

  const handleValueChange = e => {
    setValue(e.target.value)
  }

  // const refresh = async () => {
  //   dispatch(onGetVehicles())
  // }

  const saveEdit = async () => {
    try {
      // await refresh()
      const ids = selectedVehicles
        .filter(vehicle => vehicle.status !== "LTD")
        .map(vehicle => vehicle.IMEI)
      const payload = {
        ids,
        field,
        value,
      }
      const { data } = await updateMultiVehicles(payload)
      setResponse(data)
      showToastMessage({
        success: true,
        message: `Successfully updated ${selectedVehicles.length} vehicles`,
      })
      toggleEdit()
      await refetch()
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }
  }

  useEffect(() => {
    const aggregated = {}
    for (let { status } of selectedVehicles)
      aggregated[status] = {
        status,
        count: aggregated[status] ? aggregated[status].count + 1 : 1,
      }

    let result = Object.values(aggregated).sort((a, b) => b.count - a.count)

    setSortedVehicles(result)
  }, [selectedVehicles])
  // const unique = [...new Set(selectedVehicles.map(item => item.group))]
  return (
    <div className="p-1">
      <Card className="p-3 mt-2 bg-light border rounded border-light">
        <Row>
          {/* <Col lg={4} sm={12}> */}

          {/* </Col> */}
        </Row>
        <div className="text-muted text-center">
          {/* <h4 className="card-title mb-4">Selected Vehicles</h4> */}
        </div>

        <Row className="text-center">
          <Col xs={4} className="mt-2">
            <i className={`mdi mdi-scooter font-size-18 text-primary mb-1`} />
            <div className="mt-2">
              <p className="text-muted mb-1">Scooters</p>
              <h5>{scooters}</h5>
            </div>
          </Col>

          <Col xs={4} className="mt-2">
            <i className={`mdi mdi-bike font-size-18 text-primary`} />
            <div className="mt-2">
              <p className="text-muted mb-1">Bikes</p>
              <h5>{bikes}</h5>
            </div>
          </Col>
          <Restricted to="vehicles:UPDATE_VEHICLES">
            <Col xs={4} className="mt-2">
              <ul className="list-inline user-chat-nav text-end mb-3">
                <li className="list-inline-item">
                  <Dropdown
                    isOpen={other_Menu}
                    toggle={toggleOther}
                    // disabled={loadingDetails || error.message}
                  >
                    {/* TODO extract into a component */}
                    <DropdownToggle className="btn btn-outline-dark" tag="p">
                      Actions
                      <i className="bx bx-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      {/* <DropdownMenu header>Hardware Actions</DropdownItem> */}

                      <DropdownItem onClick={toggleEdit} disabled={!total}>
                        Bulk Edit
                      </DropdownItem>
                      <DropdownItem disabled={true}>Send Command</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Restricted>
        </Row>

        {selectedVehicles.length ? (
          <>
            <hr />
            <div className="table-responsive">
              {/* <>
              <h5 className="mb-2 mt-2">Status</h5>
            </> */}
              <SimpleBar
                style={{ maxHeight: "300px", height: "300px" }}
                forceVisible="y"
                autoHide={false}
              >
                <Table className="table align-middle mb-0">
                  <tbody>
                    {sortedVehicles.map((data, key) => {
                      const options = getChartOptions(data.status)
                      return (
                        <tr key={key}>
                          <td>
                            <h5 className="font-size-14 mb-1">{data.status}</h5>
                            <p className="text-muted mb-0">
                              {data.count} vehicle(s)
                            </p>
                          </td>

                          <td>
                            <div id="radialchart-1">
                              <ReactApexChart
                                options={options}
                                series={[(data.count / total) * 100]}
                                type="radialBar"
                                height={60}
                                width={60}
                                className="apex-charts"
                              />
                            </div>
                          </td>
                          <td>
                            <h5 className="mb-0">
                              {((data.count / total) * 100).toFixed(0)}%
                            </h5>
                            <p className="text-muted mb-1">of {total} total</p>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </SimpleBar>
            </div>
            <hr />
          </>
        ) : (
          <></>
        )}
      </Card>

      <Modal
        show={editModal}
        onCloseClick={toggleEdit}
        title={`Bulk Edit Vehicles`}
        size="md"
      >
        <p className="text-muted">
          <span>
            <i className="text-info bx bxs-info-circle font-size-16 me-2" />
          </span>
          This action will update all the selected{" "}
          <strong>{total} vehicles </strong>
          with the new changes
        </p>
        <Row className="my-4">
          <label className="col-md-6 col-form-label">Field to Update</label>
          <div className="col-md-6">
            <select
              className="form-control"
              onChange={handleFieldChange}
              value={field}
            >
              {vehicleFields.map((s, i) => {
                return (
                  <option key={i} value={s.value}>
                    {s.label}
                  </option>
                )
              })}
            </select>
          </div>
        </Row>
        <Row className="my-2">
          <label className="col-md-6 col-form-label">New Value</label>
          <div className="col-md-6">
            <select
              className="form-control"
              onChange={handleValueChange}
              value={value}
            >
              {/* {vehicleFields
                .find(field => field.value === field)
                .options?.map((s, i) => {
                  return (
                    <option key={i} value={s.value}>
                      {s.label}
                    </option>
                  )
                })} */}
              {vehicleStatus.map((s, i) => {
                return (
                  <option key={i} value={s.value}>
                    {s.label}
                  </option>
                )
              })}
            </select>
          </div>
        </Row>
        <LoadingButton
          type="button"
          className="btn btn-info w-25 float-end my-3"
          onClick={() => saveEdit()}
        >
          Save
        </LoadingButton>
        {/* <button
          onClick={() => saveEdit()}
          disabled={loading}
        >
          {loading ? <Spinner size="sm" /> : "Save"}
        </button> */}
        <Row>{response?.length ? JSON.stringify(response) : <></>}</Row>
        {/* </Row> */}
      </Modal>
    </div>
  )
}

export default BulkView
