import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { Switch, BrowserRouter as Router, withRouter } from "react-router-dom"
import { connect, useSelector, useDispatch } from "react-redux"
import { compose } from "redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { getCurrentUser } from "store/actions"

// Import scss
import "./assets/scss/theme.scss"

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper"
import { initRtdbBackend } from "helpers/rtdb_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import WelcomeModal from "components/Auth/WelcomeModal"

// Activating fake backend
fakeBackend()

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}
const rtdbConfig = {
  ...firebaseConfig,
  databaseURL: process.env.REACT_APP_RTDB_DATABASEURL,
}

// init firebase backend
initFirebaseBackend(rtdbConfig) // TODO check this in depth
initRtdbBackend(rtdbConfig)

const App = props => {
  const dispatch = useDispatch()
  const [allAllowedRoutes, setAllAllowedRoutes] = useState(publicRoutes)
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  function getView(route) {
    const canView =
      allAllowedRoutes.find(allowedRoute =>
        route.path.includes(allowedRoute)
      ) || false
    return !!canView
  }

  const { allowedRoutes, loadingConfig, account, isLoggedIn } = useSelector(
    state => ({
      allowedRoutes: state.Profile.allowedRoutes,
      loadingConfig: state.Profile.loadingConfig,
      account: state.Profile.account,
      isLoggedIn: state.Login.isLoggedIn,
    })
  )
  // const permittedToView = () => {
  //   const allRoutes = [...publicRoutes, authProtectedRoutes]

  // }

  useEffect(() => {
    const path = window.location.pathname
    if (!allowedRoutes || (!allowedRoutes.length && path !== "/login")) {
      // if (!allowedRoutes || isLoggedIn) {
      dispatch(getCurrentUser(props.history))
    } else {
      let routes = []
      allowedRoutes.forEach(allowedRoute => {
        routes.push(allowedRoute.path)
        if (allowedRoute.children?.length) {
          const mapped = allowedRoute.children.map(
            childRoute => allowedRoute.path + childRoute.path
          )
          routes = routes.concat(mapped)
        }
      })
      setAllAllowedRoutes(prev => [...prev, ...routes])
    }
  }, [isLoggedIn, allowedRoutes])

  const Layout = getLayout()

  return (
    <React.Fragment>
      {account?.state === "pending" ? <WelcomeModal /> : null}
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isViewAllowed={true}
              loading={loadingConfig}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isViewAllowed={getView(route)}
              loading={loadingConfig}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default compose(withRouter, connect(mapStateToProps, null))(App)
