import React from "react"
import ContentLoader from "react-content-loader"
import { Card, CardBody, Spinner } from "reactstrap"

const ZoneListLoader = props => {
  return (
    <>
      <Card className="d-none d-lg-block">
        <CardBody>
          <ContentLoader
            viewBox="0 0 1200 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="5" y="5" rx="4" ry="4" width="900" height="300" />

            <rect x="930" y="100" rx="10" ry="10" width="200" height="19" />

            <rect x="930" y="135" rx="10" ry="10" width="100" height="19" />
          </ContentLoader>
        </CardBody>
      </Card>
      <Card className="d-none d-lg-block">
        <CardBody>
          <ContentLoader
            viewBox="0 0 1200 300"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
          >
            <rect x="5" y="5" rx="4" ry="4" width="900" height="300" />

            <rect x="930" y="100" rx="10" ry="10" width="200" height="19" />

            <rect x="930" y="135" rx="10" ry="10" width="100" height="19" />
          </ContentLoader>
        </CardBody>
      </Card>
    </>
  )
}

export default ZoneListLoader
