import React, { useState } from "react"
import PropTypes from "prop-types"
import LoadingButton from "components/Common/LoadingButton"
import { updateCustomer, searchCustomers } from "helpers/backend_helper"
import { Col, Row, Alert, Button } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import "react-phone-input-2/lib/high-res.css"
import PhoneInput from "react-phone-input-2"
import { activateCustomer } from "store/actions"
import { showToastMessage } from "components/Common/ToastWrapper"

const FormActivateEmployee = ({ close }) => {
  const dispatch = useDispatch()
  const [hasError, setHasError] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [errMessage, setErrMessage] = useState("")
  const [phone, setPhone] = useState("")
  const [code, setCode] = useState("")
  const [sameConfig, setSameConfig] = useState(false)
  const [otherConfig, setOtherConfig] = useState(false)

  const { account } = useSelector(state => ({
    account: state.Profile.account,
  }))

  const clearState = () => {
    setErrMessage("")
    setHasError(false)
    setSameConfig(false)
    setOtherConfig(false)
  }
  const handlePhoneChange = async (value, event) => {
    clearState()
    setPhone(value)
    setCode(event.dialCode)
  }
  const handleClick = async () => {
    clearState()
    setIsDisabled(true)

    try {
      const digits = phone.toString().substr(code.length)
      const { data } = await searchCustomers(digits, null)

      const sameConfig = data.userConfig?.b2bPartner === account.b2bPartner

      const otherConfig =
        data.userConfig?.b2bPartner !== "NIL" &&
        data.userConfig?.configType === "b2bPartner"

      if (sameConfig) {
        // alert("already configured")
        setSameConfig(true)
      } else if (otherConfig) {
        // alert("cannot config")
        setOtherConfig(true)
      } else {
        await activateEmployee(data)
      }
    } catch (error) {
      setHasError(true)
      if (error.response?.data?.status === 404) {
        setErrMessage("Phone number not registered")
      } else {
        setErrMessage("Something went wrong")
      }
    }

    setIsDisabled(false)
  }

  const activateEmployee = async user => {
    const update = {
      ...user,
      userConfig: {
        configType: "b2bPartner",
        b2bPartner: account.b2bPartner,
      },
    }

    // dispatch(editCustomer(update))
    await updateCustomer(update.userId, update)

    dispatch(activateCustomer(update))
    showToastMessage({
      success: true,
      message: `${code} ${phone
        .toString()
        .substr(code.length)} activated successfully`,
    })
    close()
  }

  const clearInput = () => {
    setPhone(code)
    // setCode("")
    clearState()
  }

  return (
    <>
      <Row>
        <p className="sub-header">Phone Number:</p>
        <PhoneInput
          masks={{ sa: ".. ... ...." }}
          country={"sa"}
          value={phone}
          onChange={handlePhoneChange}
          containerStyle={{ direction: "ltr" }}
          inputProps={{
            autoFocus: true,
          }}
          inputClass={"form-control"}
          inputStyle={{
            width: "100%",
            margin: 0,
            // height: "32px",
          }}
          placeholder={"+966 5xx xx xx"}
          defaultErrorMessage="Something went wrong, please try again"
        />
      </Row>
      <Row className="mt-4">
        {hasError && (
          <Col sm={12}>
            <Alert color="danger">{errMessage}</Alert>
          </Col>
        )}

        {sameConfig ? (
          <Col sm={12}>
            <Alert color="success">
              <i className="mdi mdi-check-all me-2"></i>
              This employee has already been activated
            </Alert>
          </Col>
        ) : (
          <></>
        )}

        {otherConfig ? (
          <Col sm={12}>
            <Alert color="danger">
              Employee belongs to another company and cannot be activated
            </Alert>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row>
        <Col className=" text-end">
          {sameConfig || otherConfig || errMessage ? (
            <Button className="btn btn-light me-2" onClick={clearInput}>
              Clear
            </Button>
          ) : (
            <></>
          )}
          <LoadingButton className="btn btn-success" onClick={handleClick}>
            Add
          </LoadingButton>
        </Col>
      </Row>
    </>
  )
}

FormActivateEmployee.propTypes = {
  close: PropTypes.func,
}

export default FormActivateEmployee
