export const vehicleStatus = [
  {
    label: "In Use",
    value: "InUse",
    color: "info",
    iconClassClass: "",
    borderClass: "",
    colorHex: "#f1b44c",
  },
  {
    label: "Available",
    value: "Available",
    color: "success",
    iconClass: "text-success bx bxs-circle bx-flashing",
    borderClass: "text-success",
    colorHex: "#00a82d",
  },

  {
    label: "Maintenance",
    value: "Maintenance",
    color: "secondary",
    iconClass: "",
    borderClass: "text-warning",
    colorHex: "#7ababb",
  },

  {
    label: "Repositioning",
    value: "Redistribute",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#FF8000",
  },

  {
    label: "Low Battery",
    value: "BatteryLow",
    color: "secondary",
    iconClass: "",
    borderClass: "",
    colorHex: "#000",
  },
]

export const vehicleFields = [
  {
    label: "Status",
    value: "status",
    options: vehicleStatus,
    isDisabled: false,
  },
  // {
  //   label: "Station",
  //   value: "stationId",
  //   options: {},
  //   isDisabled: true,
  // },
]
