import { GoogleMap, Polygon, useJsApiLoader } from "@react-google-maps/api"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import { getZoneDetails } from "store/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Switch from "react-switch"

import { updateZone as onUpdateZone } from "store/actions"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import { showToastMessage } from "components/Common/ToastWrapper"
import ZoneDetailsLoader from "./zone-details-loader"
import { calclulateCenter } from "components/Zones/common"
import useQuery from "hooks/useQuery"
const containerStyle = {
  width: "100%",
  height: "100%",
  border: "1px solid #ccc",
  borderRadius: "5px",
  minHeight: "300px",
}

const Offsymbol = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      ></div>
    </>
  )
}

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    ></div>
  )
}

const isValidNumber = num => {
  return !isNaN(num)
}

const ZoneDetails = props => {
  const dispatch = useDispatch()
  const query = useQuery()
  const type = query.get("type")
  const zoneUnder = query.get("zoneUnder")

  const {
    zone: stateZone,
    loadingDetails,
    error,
  } = useSelector(state => ({
    zone: state.zones.zone,
    loadingDetails: state.zones.loadingDetails,
    error: state.zones.error,
  }))

  const [zone, setZone] = useState({})
  const [parentZone, setParentZone] = useState({})

  const {
    match: { params },
    location: { state },
  } = props

  const { zoneName } = params

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  })
  const { success, message } = useSelector(state => ({
    success: state.zones.success,
    message: state.zones.message,
  }))

  const [path, setPath] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [controlZones, setControlZones] = useState([])
  const [updates, setUpdates] = useState({})
  const [isAllowEndTrip, setIsAllowEndTrip] = useState(null)
  const [speed, setSpeed] = useState(null)

  const getZoneTypeColor = type => {
    switch (type) {
      case "operation":
        return "dark"
      case "forbidden":
        return "danger"

      case "lowSpeed":
        return "warning"
      default:
        return "primary"
    }
  }

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null)
  const controlZonesRef = useRef([])
  const listenersRef = useRef([])
  const controlZoneListernersRef = useRef([])

  // Call setPath with new edited paths
  const onEdit = useCallback(() => {
    if (polygonRef.current && !zone?.zoneConfig?.isShared) {
      setIsEdit(true)
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })
      setPath(nextPath)

      if (type === "control") {
        const edited = controlZones.map(controlZone => {
          if (controlZone.zoneName === zone.zoneName) {
            zone.zoneBoundaries = nextPath
          }
          return zone
        })
        setControlZones(edited)
      }
    }
  }, [setPath, path])

  // EVENT HANDLERS

  const saveEdit = async () => {
    const zoneBoundaries = path.map(point => {
      return { latitude: point.lat, longitude: point.lng }
    })

    // const controlZonesBoundaries = controlZones.reduce((obj, item) => {
    //   const zoneBoundries = item.zoneBoundaries.map(point => {
    //     return { latitude: point.lat, longitude: point.lng }
    //   })
    //   obj[item.zoneName] = { ...item, zoneBoundaries: zoneBoundries }
    //   return obj
    // }, {})

    let updatedZone = {}
    if (type === "control") {
      const updatedControlZones = {}
      Object.keys(parentZone.controlZones).forEach(function (key) {
        const controlZone = parentZone.controlZones[key]
        if (controlZone.zoneName === zoneName) {
          updatedControlZones[key] = {
            ...controlZone,
            zoneBoundaries: zoneBoundaries,
            isAllowEndTrip,
            speed,
          }
        } else {
          updatedControlZones[key] = controlZone
        }
      })

      updatedZone = {
        ...parentZone,
        controlZones: updatedControlZones,
      }
    } else {
      updatedZone = {
        ...zone,
        zoneBoundaries,
      }
    }

    dispatch(onUpdateZone(parentZone.zoneName, updatedZone))
    setIsEdit(false)
  }

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove())
    polygonRef.current = null
    controlZonesRef.current = []
  }, [])

  const onCancel = useCallback(() => {
    setIsEdit(false)
    dispatch(getZoneDetails(zoneName, type, zoneUnder))
  }, [zone])

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      let center = {}
      if (type === "control") {
        const { lat, lng } = zone
        center = { lat, lng }
      } else {
        const { lat, lng } = parentZone
        center = { lat, lng }
      }

      polygonRef.current = polygon
      const path = polygon.getPath()
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      )
    },
    [onEdit]
  )

  const handleChecked = checked => {
    // TODO add a check for boundaries as well

    // if (checked === zone.isAllowEndTrip && speed === zone.speed) {
    //   setIsEdit(false)
    //   setIsAllowEndTrip(zone.isAllowEndTrip)
    // } else {
    setIsEdit(true)
    setIsAllowEndTrip(checked)
    // }
  }

  const handleCheckChange = e => {
    const speed = e.target.value.toString()
    // if (speed === zone.speed && e.target.checked === zone.isAllowEndTrip) {
    //   setIsEdit(false)
    //   setSpeed(zone.speed)
    // } else {
    setIsEdit(true)
    if (e.target.checked) {
      setSpeed(speed)
    }
    // }
  }

  // USE EFFECTS
  // Refetch zone details on zoneName change
  useEffect(() => {
    dispatch(getZoneDetails(zoneName, type, zoneUnder))
  }, [])

  useEffect(() => {
    if (stateZone) {
      setParentZone(stateZone)
      if (type === "control") {
        const controlZones = Object.values(stateZone.controlZones)
        const childZone = controlZones?.find(
          controlZone => controlZone.zoneName === zoneName
        )
        if (childZone) {
          setZone(childZone)
          setIsAllowEndTrip(childZone.isAllowEndTrip)
          setSpeed(childZone.speed)
        }
      } else {
        setZone(stateZone)
        setIsAllowEndTrip(stateZone.isAllowEndTrip)
        setSpeed(stateZone.speed)
      }
    }
  }, [stateZone])

  // Set path on zone change
  useEffect(() => {
    if (zone && zone.zoneBoundaries) {
      const zoneBoundaries = (zone.zoneBoundaries || []).map(boundry => {
        return {
          lat: boundry?.latitude || boundry.lat,
          lng: boundry?.longitude || boundry.lng,
        }
      })

      setPath(zoneBoundaries)
    }
    return () => {
      setPath([])
      const el = document.getElementById("google-map-script")
      el ? el.parentNode.removeChild(el) : false
    }
  }, [zone, dispatch])

  // Set control zones on zone change
  useEffect(() => {
    if (zone && zone.controlZones) {
      const cZones = Object.values(zone.controlZones).map(cZone => {
        return {
          ...cZone,
          zoneBoundaries: cZone.zoneBoundaries.map(boundry => {
            return { lat: boundry.latitude, lng: boundry.longitude }
          }),
        }
      })
      setControlZones(cZones)
    }
    return () => {
      setControlZones([])
      const el = document.getElementById("google-map-script")
      el ? el.parentNode.removeChild(el) : false
    }
  }, [zone])

  useEffect(() => {
    if (message) {
      showToastMessage({ success, message })
    }
    return () => {}
  }, [success, message])
  const renderMap = useCallback(() => {
    return (
      <GoogleMap
        center={calclulateCenter(path)}
        mapContainerStyle={containerStyle}
        zoom={15}
        options={{
          disableDefaultUI: false,
        }}
      >
        <Polygon
          onLoad={onLoad}
          path={path}
          editable={!zone?.zoneConfig?.isShared}
          draggable={!zone?.zoneConfig?.isShared}
          onMouseUp={onEdit}
          onUnmount={onUnmount}
          options={{
            fillColor: zone?.zoneColor,
            clickable: true,
          }}

          // onClick={e => {}}
        />
        {/* {controlZones.map((cZone, index) => {
          return (
            <Polygon
              key={index}
              onLoad={polygon => onLoadControlZones(polygon, cZone)}
              path={cZone.zoneBoundaries}
              editable
              draggable
              options={{
                fillColor: cZone.zoneColor,
              }}
              onClick={e => {}}
              onMouseUp={() => onEditControlZone(cZone)}
              onDragEnd={() => onEditControlZone(cZone)}
              onUnmount={onUnmount}
              onMouseOver={() => {}}
            />
          )
        })} */}
      </GoogleMap>
    )
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{zone?.zoneName || "Zone Details"} | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Zones"
            titleLink="/zones"
            breadcrumbItem="Zone Details"
          />
          <Restricted to="zones:VIEW_ZONE" fallback={<AccessDenied />}>
            {!loadingDetails && isLoaded ? (
              <>
                <section>
                  {error && error !== 404 && (
                    <Col lg={12}>
                      <div
                        className="alert alert-danger text-center mb-4 placeholder-glow"
                        role="alert"
                      >
                        An error has occured while updating zone details,{" "}
                        <a
                          className="alert-link"
                          onClick={() => window.location.reload()}
                        >
                          please try again.
                        </a>
                      </div>
                    </Col>
                  )}

                  {error == 404 && !zone ? (
                    <Col lg={12}>
                      <div
                        className="alert alert-danger text-center mb-4 placeholder-glow"
                        role="alert"
                      >
                        Zone not found,{" "}
                        <Link to={"/zones"} className="alert-link">
                          please try again.
                        </Link>
                        {/* <a
                          className="alert-link"
                          // href="/zones"
                          onClick={() => [window.history.pushState(null, null,"/zones"), window.history.go()]}
                        >
                        </a> */}
                      </div>
                    </Col>
                  ) : (
                    <Row key={zone?.zoneName}>
                      <Card className="h-100">
                        <CardBody>
                          <Row>
                            <Col
                              lg={8}
                              md={8}
                              sm={12}
                              xs={12}
                              style={{ height: "70vh" }}
                            >
                              {/* <Card className="h-100"> */}
                              {path.length > 0 && renderMap()}

                              {/* </Card> */}
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12}>
                              <div className="d-flex flex-column h-100">
                                <div className="my-auto">
                                  <h3>{zone?.zoneName}</h3>
                                  <Badge
                                    className="mb-3 font-size-12 p-2 text-capitalize"
                                    color={getZoneTypeColor(zone.zoneType)}
                                    pill
                                    // style={{ width: "6rem" }}
                                  >
                                    {zone.zoneType} Zone
                                  </Badge>

                                  {type && type === "control" ? (
                                    <>
                                      <hr />
                                      <Row>
                                        <dl className="text-muted row ">
                                          <dt className="col-sm-5">
                                            Zone Under
                                          </dt>
                                          <dd className="col-sm-7">
                                            <Link
                                              to={`/zone-detail/${zone.zoneUnder}?type=operation`}
                                              target="_blank"
                                            >
                                              {zone?.zoneUnder}
                                              <i className="bx bx-link-external ms-1 align-middle font-size-16" />
                                            </Link>
                                          </dd>

                                          <dt className="col-sm-5">
                                            Allow End Trip?
                                          </dt>
                                          <dd className="col-sm-7">
                                            <Switch
                                              uncheckedIcon={<Offsymbol />}
                                              checkedIcon={<OnSymbol />}
                                              className="me-1 mb-sm-8 mb-2"
                                              onColor="#00a82d"
                                              onChange={handleChecked}
                                              checked={isAllowEndTrip}
                                            />
                                          </dd>
                                          {zone.zoneType === "lowSpeed" ? (
                                            <>
                                              <dt className="col-sm-5">
                                                Speed
                                              </dt>
                                              <dd className="col-sm-7">
                                                <div className="d-flex flex-wrap gap-2">
                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    id="btncheck0"
                                                    autoComplete="off"
                                                    name="speed"
                                                    onChange={handleCheckChange}
                                                    value={"1"}
                                                  />
                                                  <label
                                                    className={`btn btn-md btn-rounded btn-${
                                                      speed === "1"
                                                        ? ""
                                                        : "outline-"
                                                    }secondary`}
                                                    htmlFor="btncheck0"
                                                  >
                                                    Low
                                                  </label>
                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    id="btncheck1"
                                                    autoComplete="off"
                                                    name="speed"
                                                    onChange={handleCheckChange}
                                                    value="2"
                                                  />
                                                  <label
                                                    className={`btn btn-md btn-rounded btn-${
                                                      speed === "2"
                                                        ? ""
                                                        : "outline-"
                                                    }secondary`}
                                                    htmlFor="btncheck1"
                                                  >
                                                    Medium
                                                  </label>

                                                  <input
                                                    type="radio"
                                                    className="btn-check"
                                                    id="btncheck2"
                                                    autoComplete="off"
                                                    name="speed"
                                                    onChange={handleCheckChange}
                                                    value="3"
                                                  />
                                                  <label
                                                    className={`btn btn-md btn-rounded btn-${
                                                      speed === "3"
                                                        ? ""
                                                        : "outline-"
                                                    }secondary`}
                                                    htmlFor="btncheck2"
                                                  >
                                                    High
                                                  </label>
                                                </div>
                                              </dd>
                                            </>
                                          ) : null}
                                        </dl>
                                      </Row>
                                    </>
                                  ) : (
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <td></td>
                                          <th>
                                            <i className="mdi mdi-human-scooter me-2 font-size-18 align-middle text-primary"></i>
                                            <span>Scooter </span>{" "}
                                          </th>
                                          <th>
                                            <i className="mdi mdi-bike-fast me-2 font-size-18 align-middle text-primary"></i>
                                            <span>Bike </span>{" "}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Initial Fare</td>
                                          <td id="scooterInitialFare">
                                            {(
                                              zone?.scooterInitialFare / 100
                                            ).toFixed(2)}{" "}
                                            <strong>SAR</strong>
                                          </td>
                                          <td id="bikeInitialFare">
                                            {(
                                              zone?.bikeInitialFare / 100
                                            ).toFixed(2)}{" "}
                                            <strong>SAR</strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Initial Time Block</td>
                                          <td id="scooterInitialTimeBlock">
                                            {zone?.scooterInitialTimeBlock /
                                              60000}{" "}
                                            min(s)
                                          </td>
                                          <td id="bikeInitialTimeBlock">
                                            {zone?.bikeInitialTimeBlock / 60000}{" "}
                                            min(s)
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Subsequent Fare </td>
                                          <td id="scooterSubsequentFare">
                                            {(
                                              zone?.scooterSubsequentFare / 100
                                            ).toFixed(2)}{" "}
                                            <strong>SAR</strong>
                                          </td>
                                          <td id="bikeSubsequentFare">
                                            {(
                                              zone?.bikeSubsequentFare / 100
                                            ).toFixed(2)}{" "}
                                            <strong>SAR</strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Subsequent Time Block </td>
                                          <td id="scooterSubsequentTimeBlock">
                                            {zone?.scooterSubsequentTimeBlock /
                                              60000}{" "}
                                            min(s)
                                          </td>
                                          <td id="bikeSubsequentTimeBlock">
                                            {zone?.bikeSubsequentTimeBlock /
                                              60000}{" "}
                                            min(s)
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                                <p className="text-muted">
                                  Begin editing the zone boundaries by dragging
                                  the vertices
                                </p>
                                {isEdit ? (
                                  <Alert
                                    color="secondary"
                                    isOpen={isEdit}
                                    className="bg-light bg-secondary m-2"
                                  >
                                    <div className="my-2 d-flex justify-content-between align-items-center gap-3">
                                      <button
                                        type="button"
                                        className="btn btn-secondary float-end m-0 w-50"
                                        onClick={onCancel}
                                      >
                                        Cancel
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-primary float-end m-0 w-50"
                                        onClick={() => saveEdit()}
                                      >
                                        {loadingDetails ? (
                                          <Spinner size="sm" />
                                        ) : (
                                          "Save Changes"
                                        )}
                                      </button>
                                    </div>
                                  </Alert>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Row>
                  )}
                </section>
              </>
            ) : (
              <ZoneDetailsLoader />
            )}
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

ZoneDetails.propTypes = {
  match: PropTypes.any,
  location: PropTypes.any,
}

export default withRouter(ZoneDetails)
