import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

import dashboardSaga from "./dashboard/saga"
import vehiclesSaga from "./vehicles/saga"
import tripsSaga from "./trips/saga"
import usersSaga from "./users/saga"
import customersSaga from "./customers/saga"
import zonesSaga from "./zones/saga"
import auditSage from "./audit/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(zonesSaga),
    fork(dashboardSaga),
    fork(vehiclesSaga),
    fork(tripsSaga),
    fork(usersSaga),
    fork(customersSaga),
    fork(auditSage)
  ])
}
