import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
  Spinner,
} from "reactstrap"
import { motion } from "framer-motion"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// import images
import logo from "assets/images/logo.png"

import CarouselPage from "pages/AuthenticationInner/CarouselPage"
import { loginUser } from "store/actions"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import useQuery from "hooks/useQuery"
import { getEmail } from "helpers/backend_helper"

const Login = props => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState("")
  // const [loading, setLoading] = useState(false)
  const query = useQuery()

  const { error, loading } = useSelector(state => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: email,
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      try {
        // setLoading(true)
        dispatch(loginUser(values, props.history))
      } catch (error) {
        console.log(error)
      }
      // setLoading(false)
    },
  })

  // PREFILL
  const getInitialEmail = async () => {
    if (query.get("token")) {
      try {
        const response = await getEmail(query.get("token"))
        const { email } = response
        setEmail(email)
        validation.setFieldValue("email", email)
      } catch (error) {
        // TODO handle error
      }
    }
  }

  const boxVariant = {
    // hidden: {
    //   x: "-100vw", //move out of the site
    // },
    visible: {
      x: 0, // bring it back to nrmal
      transition: {
        delay: 0.05,
        when: "beforeChildren", //use this instead of delay
        staggerChildren: 0.1, //apply stagger on the parent tag
      },
    },
  }

  const listVariant = {
    hidden: {
      x: 10, //move out of the site
      opacity: 0,
    },
    visible: {
      x: 0, // bring it back to nrmal
      opacity: 1,
    },
  }

  useEffect(() => {
    getInitialEmail()
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login - GAZAL B2B</title>
      </MetaTags>

      <div className="account-pages bg-white">
        <Container fluid className="p-0">
          {/* <Row>
            <div className="bg-primary">hi</div>
          </Row> */}
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4">
                      <span className="bg-light rounded-pill p-3 font-size-14 font-weight-semibold">
                        <i className="mdi mdi-web me-2 text-primary font-size-20 align-middle" />
                        B2B Portal
                      </span>
                    </div>
                    <div className="my-auto">
                      <div className="mb-4 mb-md-5">
                        <a
                          href="https://gazal.app"
                          className="d-block auth-logo"
                        >
                          <img
                            // style={{ float: "right" }}
                            src={logo}
                            alt=""
                            height="90"
                            className="auth-logo-dark mt-4"
                          />
                        </a>
                      </div>
                      <div>
                        <h5 className="text-primary">Welcome,</h5>
                        <p className="text-muted">
                          Please enter your information
                        </p>
                      </div>

                      {/* <CardBody className="pt-0"> */}
                      <div className="">
                        <motion.div
                          variants={boxVariant}
                          animate="visible"
                          initial="hidden"
                        >
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            {error ? (
                              <Alert color="danger">{error}</Alert>
                            ) : null}
                            <motion.div variants={listVariant}>
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </motion.div>
                            <motion.div variants={listVariant}>
                              <div className="mb-3">
                                <Label className="form-label">Password</Label>
                                <div className="input-group auth-pass-inputgroup">
                                  <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type={show ? "text" : "password"}
                                    placeholder="Enter Password"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password &&
                                      validation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  <button
                                    onClick={() => setShow(!show)}
                                    className="btn btn-light "
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className="mdi mdi-eye-outline"></i>
                                  </button>
                                </div>
                                {validation.touched.password &&
                                validation.errors.password ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.password}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </motion.div>
                            <motion.div variants={listVariant}>
                              <div className="mt-3 d-grid">
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <Spinner size="sm" className="me-2" />
                                  ) : (
                                    ""
                                  )}
                                  Log In
                                </button>
                              </div>
                            </motion.div>

                            {/* <div className="mt-4 text-center">
                            <h5 className="font-size-14 mb-3">Sign in with</h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={signInWithGoogle}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                               </li>
                            </ul>
                          </div> */}

                            <div className="mt-4 text-center">
                              <Link
                                to="/forgot-password"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock me-1" />
                                Forgot your password?
                              </Link>
                            </div>
                          </Form>
                        </motion.div>
                      </div>
                      {/* </CardBody> */}
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} GAZAL.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
