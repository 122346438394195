export const sidebarContent = [
  {
    path: "/dashboard",
    label: "Dashboard",
    icon: "bx bx-home-circle",
    priority: 1,
  },
  {
    path: "/map",
    label: "Map",
    icon: "mdi mdi-map",
    priority: 2,
  },
  {
    path: "/vehicles",
    label: "Vehicles",
    icon: "mdi mdi-car",
    priority: 3,
  },

  {
    path: "/customers",
    label: "Customers",
    icon: "mdi mdi-account-multiple",
    priority: 4,
  },
  {
    path: "/users",
    label: "Users",
    icon: "mdi mdi-account-group",
    priority: 4,
  },

  {
    path: "/trips",
    label: "Trips",
    icon: "mdi mdi-human-scooter",
    priority: 5,
  },

  {
    path: "/trips/active",
    label: "Active Trips",
    icon: "mdi mdi-human-scooter",
    priority: 5,
  },
  {
    path: "/trips/all",
    label: "All Trips",
    icon: "",
    priority: 5,
  },

  {
    path: "/zones",
    label: "Zones",
    icon: "mdi mdi-layers-search",
    priority: 6,
  },
  {
    path: "/employees",
    label: "Employees",
    icon: "mdi mdi-account-tie",
    priority: 7,
  },
]
