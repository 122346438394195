import React, { useState } from "react"
import PropTypes from "prop-types"
import StarRatings from "react-star-ratings"

import {
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Placeholder,
  CardTitle,
  Badge,
} from "reactstrap"
import {
  formatStandard,
  getTimeDifference,
  convertScooterBattery,
} from "../../utils"
import { tripStatus } from "../../constants/tripStatus"
import Restricted from "components/Common/Restricted"
import CopyToClipboard from "components/Common/CopyToClipboard"
import { getBatteryIconVariant } from "../../utils"

const CardUserTrip = ({ trip, openEndTrip, loading, vehicle }) => {
  const [isMenu, setisMenu] = useState(false)

  const ts = tripStatus.find(t => t.value === trip?.status)

  const toggleMenu = () => {
    setisMenu(!isMenu)
  }

  const active =
    trip?.status === "TRIP_STARTED" || trip?.status === "MULTI_TRIP_STARTED"

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col lg="12" xxl="9">
              <div className="d-flex">
                <div className="me-4">
                  <i className="mdi mdi-human-scooter text-primary h1" />
                </div>

                <div className="flex-grow-1">
                  <div className="text-muted">
                    <p className="text-muted mb-2">Trip ID</p>
                    {loading ? (
                      <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                        <br />
                      </Placeholder>
                    ) : (
                      <>
                        <CopyToClipboard text={trip?.tripId}>
                          <h5>{trip?.tripId}</h5>
                        </CopyToClipboard>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg="12"
              xxl="3"
              className="d-flex justify-content-end align-self-end"
            >
              <Dropdown isOpen={isMenu} toggle={toggleMenu} className="ms-2">
                <div>
                  <DropdownToggle className="btn btn-outline-dark" tag="p">
                    <i className="bx bx-chevron-down" />
                  </DropdownToggle>
                </div>
                <Restricted to="trips:END_TRIP">
                  <DropdownMenu>
                    <DropdownItem
                      disabled={!active}
                      onClick={() => openEndTrip()}
                    >
                      End Trip
                    </DropdownItem>
                  </DropdownMenu>
                </Restricted>
              </Dropdown>
            </Col>
          </Row>
        </CardBody>

        <CardBody className="border-top">
          <Row>
            <Col sm="6">
              <div>
                <p className="text-muted mb-2">Status</p>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    <h5
                      className={`badge rounded-pill ${ts?.badgeClass} font-size-16`}
                    >
                      <i className={"align-middle me-1 " + ts?.iconClass} />
                      {ts?.label}
                    </h5>
                  </>
                )}
              </div>
            </Col>
            {trip.status === "TRIP_STARTED" ||
            trip.status === "MULTI_TRIP_STARTED" ? (
              <></>
            ) : (
              <Col sm="6">
                <div>
                  <p className="text-muted mb-2">Rating</p>
                  {loading ? (
                    <Placeholder animation="glow">
                      <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                      <br />
                    </Placeholder>
                  ) : (
                    <>
                      <div className="text-muted float-start me-3 ">
                        <StarRatings
                          rating={trip.rating}
                          starRatedColor="#F1B44C"
                          starEmptyColor="#74788d"
                          numberOfStars={5}
                          name="rating"
                          starDimension="18px"
                          starSpacing="3px"
                          // className="align-middle"
                        />
                      </div>
                      <p className="text-muted mt-2 font-size-12 align-middle">
                        ( {trip.rating ? `${trip.rating} stars` : "Not Rated"} )
                      </p>
                    </>
                  )}
                </div>
              </Col>
            )}

            {/* <Col sm="6">
              <div className="text-sm-end mt-4 mt-sm-0">
                <p className="text-muted mb-2">Started At</p>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    <h5>{formatStandard(trip?.pickUpTime)}</h5>
                    <p className="text-muted">
                      ({getTimeDifference(trip?.pickUpTime)})
                    </p>
                  </>
                )}
              </div>
            </Col> */}
          </Row>
        </CardBody>

        <CardBody className="border-top">
          <Row>
            <Col sm="6">
              <div>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    <p className="text-muted mb-2"> Start Battery</p>
                    <h5>
                      <i
                        className={`${getBatteryIconVariant(
                          convertScooterBattery(trip.startBatteryLevel)
                        )} me-1`}
                      />
                      {convertScooterBattery(trip.startBatteryLevel)} %
                    </h5>
                  </>
                )}
              </div>
            </Col>
            <Col sm="6">
              <div className=" mt-4 mt-sm-0">
                {/* <p className="text-muted mb-2">End</p> */}
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    {trip.status === "TRIP_STARTED" ||
                    trip.status === "MULTI_TRIP_STARTED" ? (
                      <>
                        <p className="text-muted mb-2"> Current Battery</p>
                        <h5>
                          <i
                            className={`${getBatteryIconVariant(
                              convertScooterBattery(vehicle.Battery)
                            )} me-1`}
                          />
                          {convertScooterBattery(vehicle.Battery)} %
                        </h5>
                      </>
                    ) : (
                      <>
                        <p className="text-muted mb-2"> End Battery</p>
                        <h5>
                          <i
                            className={`${getBatteryIconVariant(
                              convertScooterBattery(trip.startBatteryLevel)
                            )} me-1`}
                          />
                          {convertScooterBattery(trip.endBatteryLevel)} %
                        </h5>
                      </>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody className="border-top">
          <Row>
            <Col sm="6">
              <div>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    <p className="text-muted mb-2"> Start Station</p>
                    <h5>
                      <i className="mdi mdi-map-marker me-1 text-primary" />
                      {trip?.pickUpStationId}
                    </h5>
                  </>
                )}
              </div>
            </Col>
            <Col sm="6">
              <div className=" mt-4 mt-sm-0">
                {/* <p className="text-muted mb-2">End</p> */}
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    <p className="text-muted mb-2"> End Station</p>
                    {trip.status === "TRIP_STARTED" ||
                    trip.status === "MULTI_TRIP_STARTED" ? (
                      "-"
                    ) : (
                      <h5>
                        {" "}
                        <i className="mdi mdi-map-marker-check me-1 text-primary" />
                        {trip?.dropOffZoneId}
                      </h5>
                    )}
                  </>
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody className="border-top">
          <Row>
            <Col sm="6">
              <div>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    <p className="text-muted mb-2"> Started At</p>
                    <>
                      <h5>
                        {" "}
                        <i className="bx bx-calendar me-1 text-primary" />
                        {formatStandard(trip?.pickUpTime)}
                      </h5>
                      <p className="text-muted">
                        ({getTimeDifference(trip?.pickUpTime)})
                      </p>
                    </>
                  </>
                )}
              </div>
            </Col>
            <Col sm="6">
              <div className=" mt-4 mt-sm-0">
                {/* <p className="text-muted mb-2">End</p> */}
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <>
                    <>
                      <p className="text-muted mb-2"> Ended At</p>
                      {trip.status === "TRIP_STARTED" ||
                      trip.status === "MULTI_TRIP_STARTED" ? (
                        "-"
                      ) : (
                        <>
                          <h5>
                            {" "}
                            <i className="bx bx-calendar-check me-1 text-primary" />
                            {formatStandard(trip?.dropOffTime)}
                          </h5>
                          <p className="text-muted">
                            ({getTimeDifference(trip?.dropOffTime)})
                          </p>
                        </>
                      )}
                    </>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
        {/*{trip.discountAmount && trip.status !== "TRIP_STARTED" ? (
        <CardBody className="border-top">
          <Row>
            <Col sm="6">
              <div>
                <p className="text-muted mb-2">Discount ID</p>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <h5>{trip?.discountId} </h5>
                )}
              </div>
            </Col>
            <Col sm="6">
              <div className="text-sm-end mt-4 mt-sm-0">
                <p className="text-muted mb-2">Discount Amount</p>
                {loading ? (
                  <Placeholder animation="glow">
                    <Placeholder xs={7} className="rounded-pill" /> <br />{" "}
                    <br />
                  </Placeholder>
                ) : (
                  <h5>
                    <Badge color="success" className="font-size-20">
                      {parseFloat(trip.discountAmount / 100).toFixed(2)} SAR
                      <span>
                        <i className="mdi mdi-sale font-size-20 text-white m-2" />
                      </span>
                    </Badge>
                  </h5>
                )}
              </div>
            </Col>
          </Row>
        </CardBody>
      ) : (
        <></>
      )} */}
      </Card>
    </>
  )
}

CardUserTrip.propTypes = {
  trip: PropTypes.object,
  openEndTrip: PropTypes.func,
  loading: PropTypes.bool,
  vehicle: PropTypes.object,
}

export default CardUserTrip
