import { call, put, takeEvery } from "redux-saga/effects"

// Zones Redux States
import { GET_ZONES, GET_ZONE_DETAILS, UPDATE_ZONE } from "./actionTypes"

import {
  getZoneDetailsFail,
  getZoneDetailsSuccess,
  getZonesFail,
  getZonesSuccess,
  updateZoneFail,
  updateZoneSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getZoneDetails,
  getZones,
  updateZone,
} from "../../helpers/backend_helper"

function* fetchZones() {
  try {
    const response = yield call(getZones)
    yield put(getZonesSuccess(response.data))
  } catch (error) {
    yield put(getZonesFail(error))
  }
}

function* fetchZoneDetails({ payload: { zoneId, type, zoneUnder } }) {
  try {
    const response = yield call(getZoneDetails, zoneId, type, zoneUnder)

    yield put(getZoneDetailsSuccess(response.data))
  } catch (error) {
    yield put(getZoneDetailsFail(error.response.status))
  }
}

function* updateZoneDetails({ zoneId, updates }) {
  try {
    const response = yield call(updateZone, zoneId, updates)
    yield put(updateZoneSuccess(zoneId, updates))
  } catch (error) {
    yield put(updateZoneFail(error))
  }
}

function* zonesSaga() {
  yield takeEvery(GET_ZONES, fetchZones)
  yield takeEvery(GET_ZONE_DETAILS, fetchZoneDetails)
  yield takeEvery(UPDATE_ZONE, updateZoneDetails)
}

export default zonesSaga
