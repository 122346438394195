import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  Alert,
  Spinner,
  Placeholder,
} from "reactstrap"

import GoogleMapReact from "google-map-react"
import { showToastMessage } from "components/Common/ToastWrapper"
import { useSelector, useDispatch } from "react-redux"
import {
  getVehicleDetails as onGetVehicleDetails,
  editVehicle as onEditVehicleDetails,
} from "store/vehicles/actions" //redux
import { getTrips as onGetTrips } from "store/trips/actions"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
// import TripsHistory from "./trips-history"
import bike from "../../../assets/images/bike.png"
import scooter from "../../../assets/images/scooter.png"
import { getBatteryVariant } from "../../../utils/colorVariants"
import LoadingTable from "components/Loading/loading-table"
import {
  lockVehicle,
  unlockVehicle,
  unlockVehicleBattery,
  buzzVehicle,
  updateVehicle,
  refreshBikeState,
  refreshScooterState,
  getAllStations,
  getZones,
} from "../../../helpers/backend_helper"
import { vehicleStatus } from "../../../constants"
import FormSendCommand from "components/Vehicle/form-send-command"
import TripsTable from "components/Trip/trips-table"
import Modal from "components/Common/Modal"
import { convertScooterBattery, convertBikeBattery } from "utils/convertBattery"
import { formatSeconds } from "../../../utils/formatDate"
import Editable from "react-bootstrap-editable"
import { isEmpty } from "lodash"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"

const VehicleDetail = props => {
  const dispatch = useDispatch()
  const {
    vehicle,
    error,
    loadingEdit,
    loadingDetails,
    loading: tripsLoading,
    trips,
    success,
    message,
  } = useSelector(state => ({
    vehicle: state.vehicles.vehicle,
    error: state.vehicles.error,
    loadingEdit: state.vehicles.loadingEdit,
    loadingDetails: state.vehicles.loadingDetails,
    loading: state.trips.loading,
    trips: state.trips.trips,
    success: state.vehicles.success,
    message: state.vehicles.message,
  }))
  const defaultCenter = { lat: 24.7136, lng: 46.6753 }
  const [sendCommandModal, setSendCommandModal] = useState(false)
  const [center, setCenter] = useState(defaultCenter)
  const [other_Menu, setother_Menu] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [stationId, setStationId] = useState("")
  const [status, setStatus] = useState("")
  const [loadingSavedChanges, setLoadingSavedChanges] = useState(false)
  const [map, setMap] = useState(null)
  const [stations, setStations] = useState([])

  const [widgets, setWidgets] = useState([
    {
      title: "Total Trips",
      iconClass: "mdi mdi-human-scooter",
      description: "-",
    },
    {
      title: "Total Revenue (SAR)",
      iconClass: "mdi mdi-cash",
      description: "-",
    },
    {
      title: "Total Usage Time (min)",
      iconClass: "mdi mdi-clock-time-eight-outline",
      description: "-",
    },
  ])

  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }

  const toggleAlert = () => {
    setStationId(vehicle.stationId)
    setStatus(vehicle.status)
    setIsEdit(!isEdit)
  }

  const saveEdit = async () => {
    setLoadingSavedChanges(true)
    try {
      if (status !== vehicle.status) {
        await updateVehicle(vehicle.IMEI, { status })
        // dispatch(onEditVehicleDetails(vehicle.IMEI, { status }))
      }

      if (stationId !== vehicle.stationId) {
        // dispatch(onEditVehicleDetails(vehicle.IMEI, { stationId }))
        await updateVehicle(vehicle.IMEI, { status })
      }
    } catch (error) {}

    setIsEdit(false)
    setLoadingSavedChanges(false)
    dispatch(onGetVehicleDetails(params.id))
  }

  const {
    match: { params },
  } = props

  useEffect(() => {
    if (params && params.id) {
      dispatch(onGetVehicleDetails(params.id))
    }
  }, [])

  const renderLocationMap = () => {
    const { lat, lng } = vehicle
    const position = {
      lat,
      lng,
    }
    setCenter(position)
    const contentStr =
      '<div id="content">' +
      '<div id="siteNotice">' +
      "</div>" +
      '<h5 id="firstHeading" class="firstHeading">Current Position</h5>' +
      "</div>" +
      "</div>"

    const markerWindow = new google.maps.InfoWindow({
      content: contentStr,
    })

    const firstMarker = new google.maps.Marker({
      position: position,
      title: "Current Location",
      // icon: svgMarker,
      icon: {
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
      },
    })
    firstMarker.addListener("click", () => {
      markerWindow.open({
        anchor: firstMarker,
        map,
        shouldFocus: false,
      })
    })
    firstMarker.setMap(map)
  }

  
  const fetchStations = async () => {
    const response = await getZones()
    const stations = response.data?.map(({ zoneName }) => ({
      label: zoneName,
      value: zoneName,
    })).sort((a, b) => {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
    setStations(stations)
  }

  useEffect(() => {
    fetchStations()
  }, [])

  useEffect(() => {
    if (!isEmpty(vehicle) && vehicle.scooterId == params.id) {
      setStationId(vehicle.stationId)
      setStatus(vehicle.status)
      const notVehicleTrips =
        trips.length && trips[0].scooterId !== vehicle.scooterId
      if (!trips.length || notVehicleTrips) {
        dispatch(onGetTrips(vehicle.IMEI))
      }
    }
  }, [vehicle])

  useEffect(() => {
    if (vehicle && map) {
      renderLocationMap()
    }
  }, [map, vehicle])

  useEffect(() => {
    if (!loadingDetails && trips.length) {
      const totalTrips = trips.length
      const totalRevenue = trips.reduce((acc, trip) => acc + trip.totalFare, 0)
      const totalUsageTime = trips.reduce(
        (acc, trip) => acc + trip.totalDuration,
        0
      )
      const widgets = [
        {
          title: "Total Trips",
          iconClass: "mdi mdi-human-scooter",
          description: totalTrips,
        },
        {
          title: "Total Revenue (SAR)",
          iconClass: "mdi mdi-cash",
          description: `${parseFloat(totalRevenue / 100).toFixed(2)}  `,
        },
        {
          title: "Total Usage Time (min)",
          iconClass: "mdi mdi-clock-time-eight-outline",
          description: formatSeconds(totalUsageTime / 1000),
        },
      ]

      setWidgets(widgets)
    }
  }, [trips])

  const apiIsLoaded = async (map, maps) => {
    setMap(map)
  }

  const placeholder = (
    // <p className="placeholder-wave">
    //   <span className="placeholder col-9"></span>
    // </p>
    <Placeholder animation="glow">
      <Placeholder xs={5} className="rounded-pill" />
    </Placeholder>
  )

  return (
    <React.Fragment>
      <Modal
        show={sendCommandModal}
        onCloseClick={() => setSendCommandModal(false)}
        title={`Send Command`}
      >
        <FormSendCommand
          vehicle={vehicle}
          close={() => setSendCommandModal(false)}
          showToastMessage={showToastMessage}
          refetch={() => dispatch(onGetVehicleDetails(vehicle.scooterId))}
        />
      </Modal>
      <div className="page-content">
        <MetaTags>
          <title>Vehicle Detail | Gazal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Vehicles"
            titleLink="/vehicles"
            breadcrumbItem="Vehicle Detail"
          />
          <Restricted to="vehicles:VIEW_VEHICLE" fallback={<AccessDenied />}>
            {
              <>
                <Row>
                  {error.message && (
                    <Col lg={12}>
                      <div
                        className="alert alert-danger text-center mb-4 placeholder-glow"
                        role="alert"
                      >
                        An error has occured fetching vehicle details,
                        <a
                          className="alert-link"
                          onClick={() => window.location.reload()}
                        >
                          {" "}
                          please try again.
                        </a>
                      </div>
                    </Col>
                  )}
                  {isEdit && (
                    <Alert color="info" isOpen={isEdit} toggle={toggleAlert}>
                      <div className="my-2">
                        <i className="mdi mdi-grease-pencil me-2"></i>
                        Currently in Editing mode.
                        <button
                          type="button"
                          className="btn btn-info float-end m-0"
                          onClick={() => saveEdit()}
                        >
                          {loadingEdit ? <Spinner size="sm" /> : "Save Changes"}
                        </button>
                      </div>
                    </Alert>
                  )}
                  <Col lg="3">
                    <Row>
                      <Card>
                        <CardBody>
                          <Col>
                            <ul className="list-inline user-chat-nav text-end mb-0">
                              <li className="list-inline-item">
                                <Dropdown
                                  isOpen={other_Menu}
                                  toggle={toggleOther}
                                  disabled={loadingDetails || error.message}
                                >
                                  {/* TODO extract into a component */}
                                  <DropdownToggle
                                    className="btn btn-outline-dark"
                                    tag="p"
                                  >
                                    Actions
                                    <i className="bx bx-chevron-down" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem header>
                                      Hardware Actions
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => {
                                        vehicle.vehicleType === "scooter"
                                          ? refreshScooterState(vehicle.IMEI)
                                          : refreshBikeState(vehicle.IMEI)
                                        dispatch(
                                          onGetVehicleDetails(vehicle.scooterId)
                                        )
                                      }}
                                    >
                                      Refresh State
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => lockVehicle(vehicle.IMEI)}
                                    >
                                      Lock
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        unlockVehicle(vehicle.IMEI)
                                      }
                                    >
                                      Unlock
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() =>
                                        unlockVehicleBattery(vehicle.IMEI)
                                      }
                                    >
                                      Battery Unlock
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => buzzVehicle(vehicle.IMEI)}
                                    >
                                      Buzz
                                    </DropdownItem>
                                    <DropdownItem
                                      onClick={() => setSendCommandModal(true)}
                                    >
                                      Send Command
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </li>
                            </ul>
                          </Col>
                          {/* <h4 className="card-title mb-4">Overview</h4> */}

                          <h4 className="text-center">
                            #{" "}
                            {loadingDetails
                              ? "-"
                              : error.message
                              ? "N/A"
                              : vehicle.scooterId}
                          </h4>

                          <div className="m-4 text-center">
                            {error.message ? (
                              <div className="ms-auto align-self-center">
                                <i className="bx bx-error display-1 text-light"></i>
                              </div>
                            ) : (
                              <img
                                src={
                                  vehicle.vehicleType === "scooter"
                                    ? scooter
                                    : bike
                                }
                                alt="logo"
                                height="150"
                              />
                            )}
                          </div>
                          <>
                            <Table className="table-responsive mb-0 table-bordered">
                              <tbody>
                                <tr key={10}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Status
                                  </th>
                                  <td>
                                    <select
                                      className="form-select form-select-sm"
                                      value={status}
                                      onFocus={() => setIsEdit(true)}
                                      onChange={e => {
                                        setStatus(e.target.value)
                                        setIsEdit(true)
                                      }}
                                    >
                                      <option
                                        value={vehicle.status}
                                        defaultValue
                                      >
                                        {vehicle.status}
                                      </option>
                                      {vehicleStatus.map((s, i) => {
                                        if (s.value !== vehicle.status) {
                                          return (
                                            <option key={i} value={s.value}>
                                              {s.label}
                                            </option>
                                          )
                                        }
                                      })}
                                    </select>
                                  </td>
                                </tr>
                                <tr key={12}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Station
                                  </th>
                                  <td>
                                    <select
                                      className="form-select form-select-sm"
                                      value={stationId}
                                      onFocus={() => setIsEdit(true)}
                                      onChange={e => {
                                        setStationId(e.target.value)
                                        setIsEdit(true)
                                      }}
                                    >
                                      <option
                                        value={vehicle.stationId}
                                        defaultValue
                                      >
                                        {vehicle.stationId}
                                      </option>
                                      {stations.map((s, i) => {
                                        if (s.value !== vehicle.stationId) {
                                          return (
                                            <option key={i} value={s.value}>
                                              {s.label}
                                            </option>
                                          )
                                        }
                                      })}
                                    </select>
                                  </td>
                                </tr>
                                <tr key={11}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Battery
                                  </th>
                                  <td>
                                    {/* <Badge bg="success">
                                    {`${vehicle.Battery}%`}
                                  </Badge> */}
                                    {/* TODO make this dynamic color */}

                                    {loadingDetails ? (
                                      placeholder
                                    ) : (
                                      <>
                                        <i
                                          className={`mdi mdi-circle text-${getBatteryVariant(
                                            vehicle.Battery
                                          )} align-middle me-1`}
                                        />
                                        {`${
                                          vehicle.vehicleType === "scooter"
                                            ? convertScooterBattery(
                                                vehicle.Battery
                                              )
                                            : convertBikeBattery(
                                                vehicle.lockBattery
                                              ) || "N/A"
                                        }%`}
                                      </>
                                    )}
                                  </td>
                                </tr>

                                <tr key={13}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    In Station
                                  </th>
                                  <td>
                                    {loadingDetails
                                      ? placeholder
                                      : error.message
                                      ? "N/A"
                                      : vehicle.inStation
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                                <tr key={14}>
                                  <th
                                    scope="row"
                                    style={{ width: "50%" }}
                                    className={"text-capitalize"}
                                  >
                                    Is Reserved
                                  </th>
                                  <td>
                                    {loadingDetails
                                      ? placeholder
                                      : error.message
                                      ? "N/A"
                                      : vehicle.status === "InUse"
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </>
                        </CardBody>
                      </Card>
                    </Row>
                    <Row>
                      <Card>
                        <CardBody>
                          {/* <div className="invoice-title"> */}
                          <h4 className="card-title mb-4">Location</h4>
                          <div style={{ height: "400px", width: "100%" }}>
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: process.env.REACT_APP_GOOGLE_MAP_KEY,
                              }}
                              defaultCenter={defaultCenter}
                              defaultZoom={16}
                              center={center}
                              yesIWantToUseGoogleMapApiInternals
                              onGoogleApiLoaded={({ map, maps }) => {
                                apiIsLoaded(map, maps)
                              }}
                            />
                          </div>
                          <br />
                          <div>
                            <div className="table-responsive">
                              <Table className="table mb-0 table-bordered">
                                <tbody>
                                  <tr key={6}>
                                    <th
                                      scope="row"
                                      style={{ width: "50%" }}
                                      className={"text-capitalize"}
                                    >
                                      Latitude
                                    </th>
                                    <td>
                                      {loadingDetails
                                        ? placeholder
                                        : vehicle.lat}
                                    </td>
                                  </tr>
                                  <tr key={7}>
                                    <th
                                      scope="row"
                                      style={{ width: "50%" }}
                                      className={"text-capitalize"}
                                    >
                                      Longitude
                                    </th>
                                    <td>
                                      {loadingDetails
                                        ? placeholder
                                        : vehicle.lng}
                                    </td>
                                  </tr>
                                  <tr key={8}>
                                    <th
                                      scope="row"
                                      style={{ width: "50%" }}
                                      className={"text-capitalize"}
                                    >
                                      Station
                                    </th>
                                    <td>
                                      {loadingDetails
                                        ? placeholder
                                        : vehicle.stationId}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Row>
                  </Col>
                  <Col lg="9">
                    <Row>
                      <Col xl="12">
                        <Row>
                          {/* Reports Render */}
                          {widgets.map((widget, key) => (
                            <Col md="4" key={"_col_" + key}>
                              <Card className="mini-stats-wid">
                                <CardBody>
                                  <div className="d-flex">
                                    <div className="flex-grow-1">
                                      <p className="text-muted fw-medium">
                                        {widget.title}
                                      </p>
                                      <h4 className="mb-0">
                                        {widget.description}
                                      </h4>
                                    </div>
                                    <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                      <span className="avatar-title rounded-circle bg-primary">
                                        <i
                                          className={
                                            widget.iconClass + " font-size-24"
                                          }
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                        <Card>
                          <CardBody>
                            <h4 className="card-title mb-4">Specifications</h4>
                            <div>
                              <div className="table-responsive">
                                <Table className="table mb-0 table-bordered">
                                  <tbody>
                                    <tr key={1}>
                                      <th
                                        scope="row"
                                        style={{ width: "400px" }}
                                        className={"text-capitalize"}
                                      >
                                        Vehicle ID
                                      </th>
                                      <td>
                                        {loadingDetails
                                          ? placeholder
                                          : vehicle.scooterId}
                                      </td>
                                    </tr>
                                    <tr key={2}>
                                      <th
                                        scope="row"
                                        style={{ width: "400px" }}
                                        className={"text-capitalize"}
                                      >
                                        Vehicle Type
                                      </th>
                                      <td className={"text-capitalize"}>
                                        {loadingDetails
                                          ? placeholder
                                          : vehicle.vehicleType}
                                      </td>
                                    </tr>
                                    <tr key={3}>
                                      <th
                                        scope="row"
                                        style={{ width: "400px" }}
                                        className={"text-capitalize"}
                                      >
                                        IMEI
                                      </th>
                                      <td>
                                        {loadingDetails
                                          ? placeholder
                                          : vehicle.IMEI}
                                      </td>
                                    </tr>
                                    <tr key={4}>
                                      <th
                                        scope="row"
                                        style={{ width: "400px" }}
                                        className={"text-capitalize"}
                                      >
                                        GPS Model
                                      </th>
                                      <td>
                                        {loadingDetails
                                          ? placeholder
                                          : vehicle.GPS_Model}
                                      </td>
                                    </tr>
                                    {/* {vehicle.specification &&
                                vehicle.specification.map(
                                  (specification, i) => (
                                    <tr key={i}>
                                      <th
                                        scope="row"
                                        style={{ width: "400px" }}
                                        className={"text-capitalize"}
                                      >
                                        {specification.type}
                                      </th>
                                      <td>{specification.value}</td>
                                    </tr>
                                  )
                                )} */}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <Card>
                          <CardBody>
                            <h4 className="card-title mb-4">Trips History</h4>
                            {/* <TripsHistory loading={tripsLoading} trips={trips} /> */}
                            {tripsLoading ? (
                              <LoadingTable />
                            ) : (
                              <TripsTable
                                trips={trips}
                                loading={tripsLoading}
                                stations={stations}
                              />
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            }
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

VehicleDetail.propTypes = {
  match: PropTypes.any,
}

export default withRouter(VehicleDetail)
