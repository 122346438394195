/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react"
import Select from "react-select"
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  InputGroup,
  Form,
  Badge,
  Collapse,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap"
import { vehicleStatus } from "../../constants/vehicleStatus"
import makeAnimated from "react-select/animated"
import Restricted from "components/Common/Restricted"
const MapSidebar = props => {
  const {
    city,
    vehicles,
    handleFilter,
    clusters,
    stations,
    loading,

    searchVehicle,
    refetch,
    resetView,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [filtersCount, setFiltersCount] = useState(0)
  const [scooterId, setScooterId] = useState("")
  const [searching, setSearching] = useState(false)
  const [batterySubmitDisabled, setBatterySubmitDisabled] = useState(true)
  const inputRef = useRef(null)
  const selectInputRef = useRef()
  // const [searchMenu, setSearchMenu] = useState(false)
  // const [settingsMenu, setSettingsMenu] = useState(false)
  // const [otherMenu, setOtherMenu] = useState(false)

  const defaultFilters = {
    vehicleType: "",
    isRatedOne: null,
    inStation: null,
    vehicleStatus: null,
    startBattery: 0,
    endBattery: 100,
  }

  const [filters, setFilters] = useState(defaultFilters)

  const toggle = () => setIsOpen(!isOpen)

  const filterData = newFilters => {
    const activeFilters = Object.fromEntries(
      Object.entries(newFilters).filter(([_, v]) => v != null)
    )

    setFiltersCount(Object.values(activeFilters).length)
    handleFilter(newFilters)
  }

  const handleScooterIdChange = e => {
    setScooterId(e.target.value)
  }

  const handleSearch = inputRef => {
    setSearching(true)
    searchVehicle(scooterId)
    setSearching(false)
  }

  // useEffect(() => {
  //

  //   document.addEventListener("keydown", keyDownHandler)
  //   return () => {
  //     document.removeEventListener("keydown", keyDownHandler)
  //   }
  // }, [])

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault()

      handleSearch(inputRef)
    }
  }
  const keyDownHandler = event => {
    if (event.key === "Enter") {
      event.preventDefault()

      handleSearch(inputRef)
    }
  }

  return (
    <div>
      {/* <Card className="h-100"> */}
      {/* <CardBody> */}
      <Row>
        <Col md="8" xs="6">
          <Restricted to="map:VIEW_DASHBOARD">
            <div className="mb-3">
              <h5 className="card-title mb-3">
                {" "}
                {/* {numVehicles ? `${numVehicles} vehicles in ${city}` : "All Vehicles"} */}
                Showing{" "}
                {clusters.reduce((acc, cluster) => {
                  if (cluster.properties.cluster) {
                    return acc + cluster.properties.point_count
                  } else {
                    return acc + 1
                  }
                }, 0)}{" "}
                Vehicles {city ? `in ${city}` : ""}
              </h5>
            </div>
          </Restricted>
        </Col>
        <Col md="4" xs="6" className="mb-4">
          <ul className="list-inline text-end mb-0">
            <li
              className="list-inline-item"
              onClick={refetch}
              id="refetchVehiclesTooltip"
            >
              <i className="mdi mdi-refresh btn btn-light rounded-circle nav-btn" />
            </li>
            <UncontrolledTooltip
              placement="top"
              target="refetchVehiclesTooltip"
            >
              Refetch Vehicles
            </UncontrolledTooltip>

            <li
              className="list-inline-item"
              id="resetViewTooltip"
              onClick={resetView}
            >
              <i className="mdi mdi-arrow-all btn btn-light rounded-circle nav-btn" />
            </li>
            <UncontrolledTooltip placement="top" target="resetViewTooltip">
              Reset View
            </UncontrolledTooltip>
          </ul>
        </Col>
      </Row>
      <Restricted to="map:VIEW_DASHBOARD">
        <Row className="text-center">
          <Col xs={4} className="mt-2">
            <i className={`mdi mdi-car-multiple font-size-18 text-primary`} />
            <div className="mt-2">
              <p className="text-muted mb-1">Total Vehicles</p>
              <h5>{loading ? <Spinner size="sm" /> : vehicles.length}</h5>
            </div>
          </Col>
          <Col xs={4} className="mt-2">
            <i className={`bx bx-map-pin font-size-18 text-primary mb-1`} />
            <div className="mt-2">
              <p className="text-muted mb-1">Total Zones</p>
              <h5>{loading ? <Spinner size="sm" /> : stations?.length}</h5>
            </div>
          </Col>

          <Col xs={4} className="mt-2">
            <i className={`mdi mdi-human-scooter font-size-18 text-primary`} />
            <div className="mt-2">
              <p className="text-muted mb-1">Active Trips</p>
              <h5>
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  vehicles.filter(v => v.status === "InUse").length
                )}
              </h5>
            </div>
          </Col>
        </Row>
        <hr />
      </Restricted>

      <Row className="text-center">
        <Col xl={12}>
          <Form>
            <div className="search-box mb-2">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control bg-light border-light rounded"
                  placeholder="Search by ID..."
                  value={scooterId}
                  onChange={handleScooterIdChange}
                  disabled={searching}
                  ref={inputRef}
                  // onSubmit={e => {
                  //   e.preventDefault()
                  // }}
                  onKeyDown={handleKeyDown}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  )
}

export default MapSidebar
