export default {
  "auth/user-not-found": "User not found",
  "auth/internal-error": "Something went wrong",
  "auth/invalid-credential": "Invalid credentials",
  "auth/user-not-found": "User not found",
  "auth/invalid-email": "Invalid Email",
  "auth/user-disabled": "Unauthorised access",
  "auth/wrong-password": "Current password is invalid, please try again",
  "new-password-duplicate": "New password cannot be the same as old password",
  "auth/too-many-requests": "Too many requests, please try again later",
  "auth/invalid-password": "Invalid current password",
  general: "Something went wrong",
  // CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "old-password-not-allowed",
}
