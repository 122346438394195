import React from "react"
import PropTypes from "prop-types"
import { UncontrolledTooltip } from "reactstrap"
import { getBatteryIconVariant } from "utils"
import { vehicleStatus } from "constants/vehicleStatus"
import "./markers.css"
import { convertScooterBattery } from "../../utils/convertBattery"

const Marker = ({ vehicle, onClick, ...props }) => {
  const handleClick = e => {
    e.stopPropagation()
    onClick()
  }
  return (
    <div onClick={handleClick} style={{ cursor: "pointer" }} {...props}>
      <div className="marker-container">
        {/* {vehicle.status === "InUse" ? (
          <>
            <div id="activeVehicleTooltip">
              <i
                className={`bx bxs-circle box-shadow text-white font-size-76 img-marker image-marker-shadow `}
              />
              <i
                className="bx bx-circle bx-flashing box-shadow font-size-76 img-marker"
                style={{ color: s.colorHex }}
              />
            </div>
            <UncontrolledTooltip placement="left" target="activeVehicleTooltip">
              Active Trip
            </UncontrolledTooltip>
          </>
        ) : ( */}
        <>
          {vehicleStatus.map((s, i) => {
            if (s.value === vehicle.status) {
              return (
                <>
                  <i
                    className={`bx bxs-circle box-shadow text-white font-size-76 img-marker image-marker-shadow `}
                  />
                  <i
                    className={`bx bx-circle ${
                      vehicle.status === "InUse" ? "bx-flashing " : ""
                    }box-shadow font-size-76 img-marker`}
                    style={{ color: s.colorHex }}
                  />
                </>
              )
            }
          })}
        </>
        {/* )} */}
        {vehicle.vehicleType === "scooter" ? (
          <i className="mdi mdi-scooter-electric scooter-img font-size-24 text-primary" />
        ) : (
          <i className="mdi mdi-bike bike-img font-size-24 text-primary" />
        )}
        <i
          className={`${getBatteryIconVariant(
            convertScooterBattery(vehicle.Battery)
          )} bg-white border rounded mdi-rotate-90 font-size-24 icon-badge`}
        />
      </div>
    </div>
  )
}

Marker.propTypes = {
  vehicle: PropTypes.object,
  onClick: PropTypes.func,
}

export default Marker
