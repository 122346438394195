import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

// actions
import defaultProfilePic from "../../assets/images/default-profile-pic.jpeg"
import WelcomeModal from "components/Auth/WelcomeModal"
import ChangePasswordForm from "../../components/Auth/ChangePasswordForm"
import ChangeUserDetailsForm from "components/Auth/ChangeUserDetailsForm"
import { showToastMessage } from "../../components/Common/ToastWrapper"
import { roles } from "constants/roles"

const UserProfile = props => {
  const { error, success, account, successPassword } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
    account: state.Profile.account,
    successPassword: state.Profile.successPassword,
  }))

  useEffect(() => {
    if (success) {
      showToastMessage({ success: true, message: success })
    }

    if (successPassword) {
      showToastMessage({ success: true, message: successPassword })
    }
  }, [success, successPassword])

  useEffect(() => {
    if (error) {
      showToastMessage({ success: false, message: error })
    }
  }, [error])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | Gazal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Gazal" breadcrumbItem="Profile" />

          <Row>
            <Col lg="6" sm="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">
                    Account Details{" "}
                    <span
                      className={`font-size-12 ms-2 text-center  ${
                        roles.find(role => role.value === account.role)
                          ?.badgeClass
                      }`}
                    >
                      {roles.find(role => role.value === account.role)?.label}
                    </span>{" "}
                  </h4>
                  <Row>
                    {/* <div className="d-flex"> */}
                    <Col lg="2">
                      <div className="ms-3 me-3">
                        <img
                          src={defaultProfilePic}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail img-fluid"
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="flex-grow-1 align-self-center">
                        <ChangeUserDetailsForm />
                      </div>
                      {/* </div> */}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Change Password</h4>
                  <ChangePasswordForm />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
