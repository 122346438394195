import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useJsApiLoader } from "@react-google-maps/api"
import OperatingZoneListItem from "./operating-zone-list-item"
import ControlZoneListItem from "./control-zone-list-item"

const ZoneList = ({ zones = [], type = "operating" }) => {
  const [mappedZones, setMappedZones] = useState([])

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  })

  const getPath = () => {
    return zones.map(zone => {
      if (zone.zoneBoundaries?.length) {
        zone.zoneBoundaries = zone.zoneBoundaries.map(boundry => {
          return {
            lat: boundry.latitude || boundry.lat,
            lng: boundry.longitude || boundry.lng,
          }
        })
      }
      return zone
    })
  }

  useEffect(() => {
    if (!mappedZones.length && zones.length) {
      const mapped = getPath(zones)
      setMappedZones(mapped)
    }
  }, [zones, mappedZones])

  useEffect(() => {
    if (mappedZones.length) {
      setMappedZones(getPath(mappedZones))
    }
  }, [zones])

  return (
    <div>
      {isLoaded &&
        mappedZones.map(zone => {
          switch (type) {
            case "operation":
              return <OperatingZoneListItem key={zone.zoneName} zone={zone} />
            case "control":
              return <ControlZoneListItem key={zone.zoneName} zone={zone} />
            default:
              return <OperatingZoneListItem key={zone.zoneName} zone={zone} />
          }
        })}
    </div>
  )
}

ZoneList.propTypes = {
  zones: PropTypes.array.isRequired,
  type: PropTypes.string,
}

export default ZoneList
