/* CUSTOMERS */
export const GET_CUSTOMERS = "GET_CUSTOMERS"
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL"

export const EDIT_CUSTOMER = "EDIT_CUSTOMER"
export const EDIT_CUSTOMER_SUCCESS = "EDIT_CUSTOMER_SUCCESS"
export const EDIT_CUSTOMER_FAIL = "EDIT_CUSTOMER_FAIL"

export const DEACTIVATE_CUSTOMER = "DEACTIVATE_CUSTOMER"
export const ACTIVATE_CUSTOMER = "ACTIVATE_CUSTOMER"
