import React from "react"
import { Badge } from "reactstrap"
import { formatStandard } from "utils"
import { roles } from "constants/roles"
import { getAccountStateVariant } from "../../utils/colorVariants"

const Email = cell => {
  return cell.value ? cell.value : ""
}

const LastActive = cell => {
  return cell.value ? formatStandard(cell.value) : ""
}

const CreatedAt = cell => {
  return cell.value ? formatStandard(cell.value) : ""
}
const Role = cell => {
  return cell.value ? (
    <div
      // color="success"
      className={`${
        roles.find(role => role.value === cell.value)?.badgeClass || "light"
      } font-size-12 text-capitalize`}
    >
      {cell.value.split("_").join(" ")}
    </div>
  ) : (
    ""
  )
}

const State = cell => {
  return (
    <Badge
      color={getAccountStateVariant(cell.value)}
      className="text-capitalize"
    >
      {" "}
      {cell.value}{" "}
    </Badge>
  )
}

const FullName = cell => {
  return (
    <div>{`${cell.row.original.firstName || "-"} ${
      cell.row.original.lastName || "-"
    }`}</div>
  )
}

const Position = cell => {
  return cell.value ? cell.value : ""
}

export { Email, LastActive, CreatedAt, Role, FullName, Position, State }
