import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import jwt_decode from "jwt-decode"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  // GET_CURRENT_USER,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  // getCurrentUser,
  // getCurrentUserFail,
} from "./actions"
import { getCurrentUserSuccess } from "../profile/actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"

import { getLoggedInUser, updateUser } from "helpers/backend_helper"
import authMessages from "constants/authMessages"

function* loginUser({ payload: { user, history } }) {
  try {
    const fireBaseBackend = getFirebaseBackend()

    const response = yield call(
      fireBaseBackend.loginUser,
      user.email,
      user.password
    )
    const decoded = jwt_decode(response._delegate.accessToken)
    const { type, role } = decoded

    // history.push("/vehicles")
    const unauthorized = type && type !== "b2bPartner"
    if (!type || unauthorized) {
      // TODO - account still gets created in fb auth, find a way to delete it after throwing err
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
      throw Error("This account is not authorized")
    }
    localStorage.setItem(`type`, `${decoded.type}`)
    localStorage.setItem(`role`, `${decoded.role}`)
    localStorage.setItem("accessToken", response._delegate.accessToken)
    yield put(loginSuccess(decoded.user_id))

    const viewConfig = yield call(getLoggedInUser)
    yield put(getCurrentUserSuccess(viewConfig.data))

    const { roleData } = viewConfig.data

    history.push(roleData.defaultRoute)
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(
        apiError("Access denied to this account, please contact your admin")
      )
      yield put(logoutUserSuccess(response))
    } else {
      let errMessage = authMessages[error.code] || authMessages["general"]
      yield put(apiError(errMessage))
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const fireBaseBackend = getFirebaseBackend()

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    const response = yield call(fireBaseBackend.logout)
    yield put(logoutUserSuccess(response))
    // }
    // history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { history } }) {
  try {
    const fireBaseBackend = getFirebaseBackend()
    const response = yield call(fireBaseBackend.socialLoginUser)
    const decoded = jwt_decode(response._delegate.accessToken)

    // decoded.type = decoded.email.split("@")[1].split(".")[0]
    const { type, role } = decoded
    const unauthorized = type && type !== "gazal"
    if (!type || unauthorized) {
      // TODO - account still gets created in fb auth, find a way to delete it after throwing err
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
      throw Error("This account is not authorized")
    }
    localStorage.setItem(`type`, `${decoded.type}`)
    localStorage.setItem(`role`, `${decoded.role}`)
    localStorage.setItem("accessToken", response._delegate.accessToken)

    yield put(loginSuccess(decoded.user_id))

    const viewConfig = yield call(getLoggedInUser)
    // yield put(getCurrentUserSuccess(viewConfig.data))

    const { defaultRoute } = viewConfig.data.roleData

    history.push(defaultRoute)
  } catch (error) {
    const errorMessage = error || authMessages["general"]
    yield put(apiError(errorMessage))
  }
}

// TODO this should be in users the rest should be in customers
// function* getCurrentUserData({ payload: { history } }) {
//   try {
//     const accessToken = localStorage.getItem("accessToken")
//     const { role } = jwt_decode(accessToken)
//     const viewConfig = yield call(getLoggedInUser)
//     console.log(
//       "function*getCurrentUserData -> viewConfig.data",
//       viewConfig.data
//     )
//     yield put(getCurrentUserSuccess(viewConfig.data))
//     const { state } = viewConfig.data
//     if (state === "pending") {
//       history.replace("/profile")
//     }
//   } catch (error) {
//     // TODO refine
//     const errorMessage = "Unauthorized"
//     yield put(getCurrentUserFail(errorMessage))
//   }
// }

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  // yield takeEvery(GET_CURRENT_USER, getCurrentUserData)
}

export default authSaga
