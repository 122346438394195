import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import TripsTable from "components/Trip/trips-table"
import "react-datepicker/dist/react-datepicker.css"
import { useSelector, useDispatch } from "react-redux"
import {
  getTrips as onGetTrips,
  getActiveTrips as onGetActiveTrips,
} from "store/trips/actions"
import { getVehicles as onGetVehicles } from "store/vehicles/actions"
// import { trips } from "./tripsMock"
import TripsFilter from "./trips-filter"
import { getAllStations, getZones } from "helpers/backend_helper"
import LoadingTable from "components/Loading/loading-table"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"

const AllTripsList = () => {
  const dispatch = useDispatch()
  const [stations, setStations] = useState([])
  const [advancedFilters, setAdvancedFilters] = useState({})
  const [tripData, setTripData] = useState([])

  const { trips, loading, vehicles, activeTrips } = useSelector(state => ({
    trips: state.trips.trips,
    loading: state.trips.loading,
    activeTrips: state.trips.activeTrips,
    vehicles: state.vehicles.vehicles,
  }))
  // const loading = false
  // const activeTrips = []
  // const vehicles = []
  const reports = [
    {
      title: "Total Trips",
      iconClass: "mdi mdi-human-scooter",
      description: trips?.length.toLocaleString() || "-",
    },
    {
      title: "Active Trips",
      iconClass: "mdi mdi-bike-fast",
      description: activeTrips?.length || "-",
    },
    {
      title: "Total Vehicles",
      iconClass: "mdi mdi-car-multiple",
      description: vehicles.length || "-",
    },
  ]

  useEffect(() => {
    if (trips && !trips.length) {
      dispatch(onGetTrips())
    }

    if (vehicles && !vehicles.length) {
      dispatch(onGetVehicles())
    }

    if (activeTrips && !activeTrips.length) {
      dispatch(onGetActiveTrips())
    }
  }, [dispatch, trips, vehicles, activeTrips])

  useEffect(() => {
    if (trips.length && !tripData.length) {
      setTripData(trips)
    }
  }, [trips])
  const fetchStations = async () => {
    const response = await getZones()
    const stations = response.data?.map(({ zoneName }) => ({
      label: zoneName,
      value: zoneName,
    })).sort((a, b) => {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
    setStations(stations)
  }

  useEffect(() => {
    fetchStations()
  }, [])

  const isEmpty = object =>
    Object.values(object).every(
      x => x === null || x === "" || (typeof x === "object" && !x.length)
    )

  const searchAdvancedFilters = (reset = false) => {
    if (!isEmpty(advancedFilters)) {
      let filteredData = trips

      if (advancedFilters.startStation.length) {
        const stations = advancedFilters.startStation.map(
          station => station.value
        )

        filteredData = filteredData.filter(trip => {
          if (stations.indexOf(trip.pickUpStationId) > -1) {
            return trip
          }
        })
      }
      if (advancedFilters.endStation.length) {
        const stations = advancedFilters.endStation.map(
          station => station.value
        )

        filteredData = filteredData.filter(trip => {
          if (stations.indexOf(trip.dropOffStationId) > -1) {
            return trip
          }
        })
      }

      if (advancedFilters.tripStatus.length) {
        const tripStatus = advancedFilters.tripStatus.map(
          status => status.value
        )

        filteredData = filteredData.filter(trip => {
          if (tripStatus.indexOf(trip.status) > -1) {
            return trip
          }
        })
      }

      // if (advancedFilters.startDateTimestamp) {
      //   filteredData = filteredData.filter(trip => {
      //     if (stations.indexOf(trip.dropOffStationId) > -1) {
      //       return trip
      //     }
      //   })
      // }

      // if (advancedFilters.endDateTimestamp) {
      //   filteredData = filteredData.filter(trip => {
      //     if (stations.indexOf(trip.dropOffStationId) > -1) {
      //       return trip
      //     }
      //   })
      // }

      setTripData(filteredData)
    } else {
      setTripData(trips)
    }

    if (reset) {
      setTripData(trips)
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Trips | Gazal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Trips"
            titleLink="/trips/all"
            breadcrumbItem="All Trips"
          />
          <Restricted to="trips:SEARCH_TRIPS" fallback={<AccessDenied />}>
            <Row>
              <Col xl="12">
                <Row>
                  {/* Reports Render */}
                  {reports.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <p className="text-muted fw-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.description}</h4>
                            </div>
                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                              <span className="avatar-title rounded-circle bg-primary">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <TripsFilter
                  refetch={() => dispatch(onGetTrips())}
                  stations={stations}
                  setAdvancedFilters={setAdvancedFilters}
                  searchAdvancedFilters={searchAdvancedFilters}
                />
                <Card>
                  <CardBody>
                    {loading ? (
                      <LoadingTable />
                    ) : (
                      <TripsTable
                        trips={tripData}
                        loading={loading}
                        stations={stations}
                        tripFilters={advancedFilters}
                      >
                        <div className="search-box me-xxl-2 my-3 my-xxl-0 d-inline-block">
                          <div className="position-relative">
                            <label
                              htmlFor="search-bar-0"
                              className="search-label"
                            >
                              <span id="search-bar-0-label" className="sr-only">
                                Search this table
                              </span>
                              <input
                                onChange={e => {
                                  // setValue(e.target.value)
                                  // onChange(e.target.value)
                                }}
                                id="search-bar-0"
                                type="text"
                                className="form-control"
                                // placeholder={`${count} records...`}
                                // value={value || ""}
                              />
                            </label>
                            <i className="bx bx-search-alt search-icon"></i>
                          </div>
                        </div>
                      </TripsTable>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AllTripsList
