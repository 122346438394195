import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
  Row,
  Col,
  InputGroup,
  Label,
  Input,
  FormFeedback,
  Form,
  FormText,
  Button,
  Spinner,
} from "reactstrap"
import {
  getCustomerDetails,
  getVehicleDetails,
  createInvoice,
  endTrip,
  getZones,
  getB2bPartner,
} from "helpers/backend_helper"
// import * as Yup from "yup"
import { useFormik } from "formik"
import { isEmpty } from "lodash"
import { addToTime } from "utils"
import { getTripsByTripId } from "../../helpers/backend_helper"

const FormEndTrip = ({ close, user = {}, trip, showToastMessage }) => {
  const [stations, setStations] = useState([])
  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useState(user)
  const [vehicle, setVehicle] = useState({})

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      returnTime: "",
      endZone: trip.pickUpStationId,
    },

    onSubmit: values => {
      try {
        submitEndRide(values)
        // alert("hi")
      } catch (error) {
        showToastMessage({
          success: false,
          message: `Something went wrong`,
        })
      }
    },
  })
  const checkBypassInvoice = async () => {
    const response = await getB2bPartner()
    const { bypassCreditCheck } = response?.data
    return bypassCreditCheck
  }

  const addInvoice = async () => {
    const { isInvoiced, tripId } = trip

    const userId = userDetails.userId
    const user = await fetchUserDetails(userId)

    const tripResponse = await getTripsByTripId(tripId, userDetails.userId)
    const newTripData = tripResponse.data.find(t => t?.tripId === trip.tripId)

    const { totalFare } = newTripData

    const balance = parseFloat(user.credits / 100)

    // const totalFare = 0

    const invoiceAmount =
      totalFare - Math.abs(user.credits) <= 0
        ? totalFare / 100
        : Math.abs(user.credits) / 100

    if (!isInvoiced && invoiceAmount > 0 && balance < 0) {
      const data = [
        {
          amount: Math.abs(invoiceAmount),
          name: "Extra Usage",
        },
      ]

      await createInvoice(userId, data)
    } else {
      return
    }
  }

  const submitEndRide = async values => {
    setLoading(true)
    try {
      const { endZone } = values

      let returnTime = Date.now()

      const station = stations.find(st => {
        return st.zoneId === endZone
      })
      if (!station) {
        setLoading(false)
        showToastMessage({
          success: false,
          message: `Please choose a partner end station`,
        })
        return
      }
      const { lat, lng } = vehicle

      const payload = {
        userId: userDetails.userId,
        userLat: lat,
        userLng: lng,
        tripId: trip?.tripId || trip?.tripRefId,
        qrString: endZone,
        opsAccountGroup: true,
        returnTime: returnTime,
      }
      await endTrip(payload)
      const bypassCreditCheck = await checkBypassInvoice()
      if (!bypassCreditCheck) {
        await addInvoice()
      }
      showToastMessage({
        success: true,
        message: `Ride Ended successfully`,
      })
      close()
    } catch (error) {
      showToastMessage({
        success: false,
        message: `Something went wrong`,
      })
      close()
    }
    setLoading(false)
  }

  const fetchStations = async () => {
    const response = await getZones()
    const stations = response.data
    setStations(stations)
  }

  const fetchUserDetails = async userId => {
    try {
      const response = await getCustomerDetails(userId)
      const user = response.data
      setUserDetails(user)
      return response.data
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Error fetching user details",
      })
    }
  }

  const fetchVehicleDetails = async () => {
    try {
      const response = await getVehicleDetails(trip.scooterId)
      setVehicle(response.data)
    } catch (error) {
      showToastMessage(errorObj)
    }
  }

  useEffect(() => {
    fetchStations()
    fetchVehicleDetails()
    if (isEmpty(userDetails)) {
      fetchUserDetails(trip.userId)
    }
  }, [])
  return (
    <>
      <Row>
        <Col lg={12}>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              validation.handleSubmit()
              return false
            }}
          >
            <Label for="startStation">Start Station</Label>
            <InputGroup className="mb-3">
              <Input
                type="text"
                id="startStation"
                defaultValue={trip.pickUpStationId}
                disabled
              />
            </InputGroup>
            <Label>End Station :</Label>
            <InputGroup className="mb-3">
              <select
                name="endZone"
                className="form-select"
                value={validation.values.endZone}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                // invalid={
                //   validation.touched.endZone && validation.errors.endZone
                //     ? true
                //     : undefined
                // }
              >
                <option key={"endZoneDefault"} value={trip.pickUpStationId}>
                  {trip.pickUpStationId}
                </option>
                {stations
                  .filter(st => st.zoneId !== trip.pickUpStationId)
                  .map((station, i) => (
                    <option key={i} value={station.zoneId}>
                      {station.zoneId}
                    </option>
                  ))}
              </select>

              {validation.touched.endZone && validation.errors.endZone ? (
                <FormFeedback type="invalid">
                  {validation.errors.endZone}
                </FormFeedback>
              ) : null}
            </InputGroup>

            <div className="text-center">
              <Button
                type="submit"
                color="danger"
                className="w-md"
                disabled={loading}
                //
              >
                {loading ? <Spinner size="sm" /> : "End Ride"}
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      <Row></Row>
    </>
  )
}
FormEndTrip.propTypes = {
  close: PropTypes.func,
  user: PropTypes.object,
  trip: PropTypes.object,
  showToastMessage: PropTypes.func,
}

export default FormEndTrip
