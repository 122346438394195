import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"

import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

import TripDetails from "pages/Trips/trip-details"
import ActiveTripsList from "pages/Trips/active-trips-list"
import AllTripsList from "pages/Trips/all-trips-list"
import VehiclesList from "pages/Vehicles/vehicles-list"
import VehicleDetail from "pages/Vehicles/VehicleDetail/vehicle-detail"
// import UserDetails from "pages/Users/UserDetails/user-details"
// import UsersList from "pages/Users/users-list"
// import CustomersList from "pages/Customers/customers-list"
import ComingSoon from "pages/General/coming-soon"
import Map from "pages/Map"
import AccessDenied from "pages/Utility/access-denied"
import Home from "pages/Home/home"
import Zones from "pages/Zones"
import ZoneDetails from "pages/Zones/zone-details"
import UsersList from "pages/Users"
import CustomersList from "pages/Customers/customers-list"

import Dashboard from "pages/Dashboard"

const authProtectedRoutes = [
  { path: "/coming-soon", component: ComingSoon },
  { path: "/dashboard", component: Dashboard },
  { path: "/trips/active", component: ActiveTripsList },
  { path: "/trips", component: AllTripsList },
  { path: "/trip-detail", component: TripDetails },
  { path: "/map", component: Map },
  { path: "/zones", component: Zones },
  { path: "/zone-detail/:zoneName?", component: ZoneDetails },

  { path: "/vehicles", component: VehiclesList },
  { path: "/vehicle-detail/:id?", component: VehicleDetail },

  { path: "/customers", component: CustomersList },

  { path: "/access-denied", component: AccessDenied },

  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/users", component: UsersList },
  { path: "/employees", component: CustomersList },

  { path: "/", exact: true, component: Home },
]

const publicRoutes = [
  // { path: "/", exact: true, component: () => <Redirect to={redirectTo} /> },
  { path: "/", exact: true, component: Home },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: Recoverpw2 },
  { path: "/register", component: Register },
  { path: "/access-denied", component: AccessDenied },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { authProtectedRoutes, publicRoutes }
