import React from "react"
import { MetaTags } from "react-meta-tags"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Restricted from "components/Common/Restricted"
import Breadcrumbs from "components/Common/Breadcrumb"
import TopZones from "./top-zones"
import ResellerWidgets from "./reseller-widgets"
import ResellersPreview from "./resellers-preview"
import MonthlyEarning from "./monthly-earning"
import TransactionsPreview from "./transactions-preview"

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | B2B Gazal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="B2B" breadcrumbItem="Dashboard" />

          <Row>
            <Col xl="4">
              <TopZones />
            </Col>
            <Col xl="8">
              <ResellerWidgets />
              <MonthlyEarning />
            </Col>
          </Row>
          <Row>
            <ResellersPreview />
          </Row>
          <Row>
            <Col lg="12">
              <TransactionsPreview />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
