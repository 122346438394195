import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Input, Label, Form, Spinner, FormFeedback } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { editProfile } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

const ChangeUserDetailsForm = props => {
  const dispatch = useDispatch()

  const { account, loadingEdit, success } = useSelector(state => ({
    account: state.Profile.account,
    loadingEdit: state.Profile.loadingEdit,
    success: state.Profile.success,
  }))

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: account.firstName || "",
      lastName: account.lastName || "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
    }),
    onSubmit: values => {
      dispatch(editProfile(account.uid, values))
    },
  })

  useEffect(() => {
    if (success) {
      validation.resetForm()
      if (props.loadNextStep) {
        props.loadNextStep()
      }
    }
  }, [success])

  return (
    <div className="p-2">
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <Row>
          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input name="email" type="text" value={account.email} disabled />
          </div>
        </Row>
        <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label">First Name</Label>
              <Input
                name="firstName"
                type="text"
                placeholder="Enter First Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.firstName || ""}
                invalid={
                  validation.touched.firstName && validation.errors.firstName
                    ? true
                    : false
                }
              />
              {validation.touched.firstName && validation.errors.firstName ? (
                <FormFeedback type="invalid">
                  {validation.errors.firstName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label className="form-label">Last Name</Label>
              <Input
                name="lastName"
                type="text"
                placeholder="Enter Last Name"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.lastName || ""}
                invalid={
                  validation.touched.lastName && validation.errors.lastName
                    ? true
                    : false
                }
              />
              {validation.touched.lastName && validation.errors.lastName ? (
                <FormFeedback type="invalid">
                  {validation.errors.lastName}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>

        <div>
          <button
            className="btn btn-primary w-md float-end"
            type="submit"
            disabled={!validation.dirty || !validation.isValid || loadingEdit}
          >
            {loadingEdit ? <Spinner size="sm" /> : " "} Update Details
          </button>
        </div>
      </Form>
    </div>
  )
}

ChangeUserDetailsForm.propTypes = {
  loadNextStep: PropTypes.func,
}

export default ChangeUserDetailsForm
