import React from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"

//Import Countdown
import Countdown from "react-countdown"

//Import Images
import logo from "../../assets/images/logo-primary.png"
import maintanence from "../../assets/images/coming-soon.svg"

const ComingSoon = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Coming Soon | Gazal - B2B Dashboard</title>
        </MetaTags>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-white">
            <i className="fas fa-home h2" />
          </Link>
        </div>

        <div className="my-5 pt-sm-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center">
                  <Row className="justify-content-center mt-5">
                    <Link to="/dashboard" className="d-block auth-logo">
                      <img src={logo} alt="logo" height="100" />
                    </Link>
                  </Row>
                  <h4 className="mt-5">Coming Soon</h4>
                  <p className="text-muted">
                    Page under construction, check back soon!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ComingSoon
