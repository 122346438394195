import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"
import { useSelector } from "react-redux"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { sidebarContent } from "constants/sidebarContent"

const SidebarContent = props => {
  const ref = useRef()
  const [sidebarItems, setSidebarItems] = useState([])
  const { allowedRoutes } = useSelector(state => ({
    allowedRoutes: state.Profile.allowedRoutes,
  }))
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")

      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  useEffect(() => {
    const sidebarRoutes = allowedRoutes.filter(route => route.sidebar)
    const sidebarItems = sidebarRoutes.map(route => ({
      ...sidebarContent.find(content => content.path === route.path),
      ...route,
    }))

    const orderedRoutes = sidebarItems.sort((a, b) => a.priority - b.priority)

    setSidebarItems(orderedRoutes)
  }, [allowedRoutes])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    //
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sidebarItems.map((route, i) => (
              // <React.Fragment >
              <li key={"sidebarRoute" + i}>
                <Link
                  to={route.path}
                  className={`${route.children?.length ? "has-arrow" : ""}`}
                >
                  <i className={route.icon}></i>
                  <span>{props.t(route.label)}</span>
                </Link>

                <ul className="sub-menu">
                  {route.children?.map((childRoute, i) => (
                    <li key={"childSidebarRoute" + i}>
                      <Link
                        to={`${route.path}${childRoute.path}`}
                        aria-expanded="false"
                      >
                        {/* <i className={childRoute.icon} /> */}
                        {props.t(childRoute.label)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              // </React.Fragment>
            ))}
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-bitcoin"></i>
                <span>{props.t("Crypto")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/crypto-wallet">{props.t("Wallet")}</Link>
                </li>
                <li>
                  <Link to="/crypto-buy-sell">{props.t("Buy/Sell")}</Link>
                </li>
                <li>
                  <Link to="/crypto-exchange">{props.t("Exchange")}</Link>
                </li>
                <li>
                  <Link to="/crypto-lending">{props.t("Lending")}</Link>
                </li>
                <li>
                  <Link to="/crypto-orders">{props.t("Orders")}</Link>
                </li>
                <li>
                  <Link to="/crypto-kyc-application">
                    {props.t("KYC Application")}
                  </Link>
                </li>
                <li>
                  <Link to="/crypto-ico-landing">{props.t("ICO Landing")}</Link>
                </li>
              </ul>
            </li>

            {/* <li>
              <Link to="/coming-soon">
                <i className="mdi mdi-map"></i>
                <span>{props.t("Map")}</span>
              </Link>
            </li>
            <li>
              <Link to="/vehicles">
                <i className="mdi mdi-scooter"></i>
                <span>{props.t("Vehicles")}</span>
              </Link>
            </li>
            <li>
              <Link to="/users">
                <i className="mdi mdi-account-multiple"></i>
                <span>{props.t("Users")}</span>
              </Link>
            </li>
            <li>
              <Link to="/trips" className="has-arrow ">
                <i className="bx bx-map"></i>
                <span>{props.t("Trips")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/trips/active">{props.t("Active Trips")}</Link>
                </li>
                <li>
                  <Link to="/trips/all">{props.t("All Trips")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/coming-soon" className="has-arrow ">
                <i className="mdi mdi-cog-outline"></i>
                <span>{props.t("Settings")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/coming-soon">{props.t("General Settings")}</Link>
                </li>
                <li>
                  <Link to="/coming-soon">{props.t("Customers")}</Link>
                </li>
                <li>
                  <Link to="/coming-soon">{props.t("Developer Docs")}</Link>
                </li>
                <li>
                  <Link to="/coming-soon">{props.t("Locations & Zones")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/access-denied">
                <i className="mdi mdi-lock"></i>
                <span>{props.t("Access Denied")}</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
