import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"

import {
  Spinner,
  Col,
  Container,
  Row,
  Alert,
  CardBody,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Placeholder,
  Nav,
  CardTitle,
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"

import { useSelector, useDispatch } from "react-redux"
import { getUsers as onGetUsers } from "store/users/actions"
import { isEmpty } from "lodash"
import UsersTable from "./users-table"
import LoadingTable from "components/Loading/loading-table"
import Restricted from "components/Common/Restricted"
import AccessDenied from "pages/Utility/access-denied"
import useQuery from "hooks/useQuery"
import RolesTable from "./roles-table"
import { getAllRoles } from "helpers/backend_helper"

const UsersList = () => {
  const dispatch = useDispatch()

  const { users, error, loading } = useSelector(state => ({
    users: state.users.users,
    error: state.users.error,
    loading: state.users.loading,
  }))

  const fetchUsers = args => {
    if (!users.length || args?.force) {
      dispatch(onGetUsers())
    }
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <React.Fragment>
      <MetaTags>
        <title> Users Management | Gazal</title>
      </MetaTags>
      {/* <Col xl={8}> */}
      <Restricted to="users:VIEW_ALL_USERS" fallback={<AccessDenied />}>
        <Row>
          <Col lg={4}>
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Total Users</p>
                    <h5 className="mb-0">{users.length}</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="mdi mdi-account-group"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col lg={4}>
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Active Users</p>
                    <h5 className="mb-0">
                      {users.filter(user => user.state === "active").length}
                    </h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="mdi mdi-bookmark-check"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card className="blog-stats-wid">
              <CardBody>
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Awaiting Invites</p>
                    <h5 className="mb-0">
                      {users.filter(user => user.state === "pending").length}
                    </h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="mdi mdi-clock-time-four-outline"></i>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* </Col> */}

        <Col lg={12}>
          <Card>
            <CardBody>
              {loading ? (
                <LoadingTable />
              ) : (
                <>
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-sm me-3 ">
                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                        <i className={"mdi mdi-account-group"} />
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h5 className="header-title mb-0 mt-4">
                        User Management
                      </h5>
                      <p className="sub-header text-muted">
                        Manage acounts using your portal
                      </p>
                    </div>
                  </div>
                  {/* <h4 className="header-title mt-0 mb-1">User Management</h4> */}
                  <hr />
                  <UsersTable users={users} refetch={fetchUsers} />
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Restricted>
    </React.Fragment>
  )
}

UsersList.propTypes = {
  users: PropTypes.array,
}

export default UsersList
