import axios from "axios"
import {
  del,
  get,
  post,
  postArray,
  put,
  patch,
  mainClient,
  tcpServer,
  internalServer,
  appServer,
} from "./api_helper"

// VERIFY
export const getEmail = token =>
  get(`/users/verify?token=${token}&type=b2bPartner`)

// -----VEHICLES
export const getVehicles = () => get("/vehicles")

export const getVehicleDetails = async scooterId =>
  get(`/vehicles/${scooterId}`)

export const updateVehicle = (imei, data) => put(`/vehicles/${imei}`, data)
export const updateMultiVehicles = data => patch(`/vehicles`, data)

// VEHICLES-----

// -----TRIPS
export const getTrips = (IMEI, userId) =>
  get(`/trips/search?imei=${IMEI}&userId=${userId}`)
export const getTripsByTripId = (tripId, userId) =>
  get(`/trips/${tripId}?userId=${userId}`)
export const getActiveTrips = () => get(`/trips/active`)
export const endTrip = data => post(`/trips/end`, data)
export const getTripRoute = data => post(`/trips/route`, data)
export const updateTrip = (tripId, data) => put(`/trips/${tripId}`, data)
export const getTripLogs = tripId => get(`/trips/${tripId}/logs`)
// TRIPS-----

// -----USERS
export const getLoggedInUser = () => get(`/users/me`)
export const getAllUsers = () => get(`/users`)
export const createUser = data => post(`/users`, data)
export const updateUser = (uid, data) => put(`/users/${uid}`, data)
export const deleteUser = uid => del(`/users/${uid}`)
// USERS-----

// -----CUSTOMERS
export const getAllCustomers = (config = {}) => {
  return get(`/customers`, internalServer, config)
}

export const searchCustomers = (contact, userId) =>
  get(`/customers/search?contact=${contact}&userId=${userId}`)

export const getCustomerById = userId =>
  get(`/customers/${userId}`, internalServer)

export const getCustomerTransactions = userId =>
  get(`/customers/${userId}/transactions`)

export const getCustomerPayments = userId =>
  get(`/customers/${userId}/payments`)

export const getCustomerInvoices = userId =>
  get(`/customers/${userId}/invoices`)

export const getInvoicePdf = invoiceId => get(`/customers/${invoiceId}/pdf`)

export const getCustomerDetails = userId => get(`/customers/${userId}`)

export const getCustomerTrips = userId =>
  get(`/customers/${userId}/trips?type=b2bPartner`)

export const updateCustomer = (userId, updates) =>
  put(`/customers/${userId}`, updates, internalServer)

export const addCustomerBalance = data => post(`/customers/addBalance`, data)

export const addCustomerDiscount = (userId, data) =>
  put(`/customers/${userId}/discounts`, data)

export const createInvoice = (userId, data) =>
  postArray(`/customers/${userId}/invoices`, data)
// CUSTOMERS-----

// -----COMMANDS
export const refreshBikeState = imei =>
  post(`/commands/refreshBike?IMEI=${imei}&command=000000000000,D0`)

export const refreshScooterState = imei =>
  post(`commands/refreshScooter?IMEI=${imei}&command=D0%23`)

export const sendCommand = (imei, command) =>
  post(
    `/commands/sendCommand?IMEI=${imei}&command=${encodeURIComponent(command)}`
  )

export const lockVehicle = imei => post(`/commands/lock?IMEI=${imei}`)

export const unlockVehicle = imei => post(`/commands/unlock?IMEI=${imei}`)

export const unlockVehicleBattery = imei =>
  post(`/commands/unlockBattery?IMEI=${imei}`)

export const buzzVehicle = imei => post(`/commands/buzz?IMEI=${imei}`)
// COMMANDS-----

//-------STATIONS
export const getAllStations = () => get(`/stations`, internalServer)
export const getStationDetails = stationId =>
  get(`/stations/${stationId}`, internalServer)
//STATIONS-----

//-------CONFIG
export const getPackages = () => get(`/config/packages`, internalServer)
export const getRoleConfig = roleName => get(`/config/roles/${roleName}`)
export const getAllRoles = () => get(`/config/roles?type=b2bPartner`)
export const getAllPermissions = () => get(`/config/permissions`)
export const getLocations = () => get(`/config/locations`, internalServer)
export const getDiscounts = () => get(`/config/discounts`, internalServer)
export const createDiscount = data => post(`/config/discounts`, data)
export const getDiscount = discountId =>
  get(`/config/discounts/${discountId}`, internalServer)

export const updateDiscount = (discountId, updates) =>
  put(`/config/discounts/${discountId}`, updates)
export const deleteDiscount = discountId =>
  del(`/config/discounts/${discountId}`)

//CONFIG-----

// -----TRANSACTIONS
export const fixTransaction = transactionId =>
  post(`/transactions/${transactionId}/fix`, {}, internalServer)
// TRANSACTIONS-----

// -----ZONES

export const getZones = () => get("/zones", internalServer)
export const getZoneDetails = (zoneId, type = "operation", zoneUnder) =>
  get(`/zones/${zoneId}?type=${type}&zoneUnder=${zoneUnder}`, internalServer)
export const updateZone = (zoneId, data) =>
  put(`/zones?zoneName=${zoneId}`, data, internalServer)

// ZONES------------

// -----AUDIT

export const getLogs = () => get("/logs", internalServer)
export const getLogDetails = logId => get(`/logs/${logId}`, internalServer)

// AUDIT------------

// EXPORT -------------
export const getExport = (path, config) => {
  const url = `/exports/${path}`
  return get(url, internalServer, config)
}

// B2B PARTNER -------------
export const getB2bPartner = () => {
  const url = `/b2bPartners/profile`
  return get(url, internalServer)
}

export const getB2bPartnerCustomers = () => {
  const url = `/b2bPartners/customers`
  return get(url, internalServer)
}
// B2B PARTNER------------

// BLOCK REASONS -------------
export const getBlockReasons = () => get("/config/blockReasons", internalServer)

export const createCustomerBlockLog = (customerId, data) =>
  post(`/logs/customerBlockLogs/${customerId}`, data, internalServer)
