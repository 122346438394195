import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Input, Label, Form, FormFeedback, Spinner } from "reactstrap"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { editProfile, setPassword } from "../../store/actions"

//redux
import { useDispatch, useSelector } from "react-redux"

const ChangePasswordForm = props => {
  const dispatch = useDispatch()

  const { successPassword, loadingPassword } = useSelector(state => ({
    successPassword: state.Profile.successPassword,
    loadingPassword: state.Profile.loadingPassword,
  }))

  const conditions = {
    lowercase: /^(?=.*[a-z])/,
    uppercase: /^(?=.*[A-Z])/,
    digit: /^(?=.*[0-9])/,
    symbol: /^(?=.*[!@#\$%\^&_\*])/,
  }

  function strongPasswordMethod() {
    return this.test("strongPasswordTest", _, function (value) {
      const { path, createError } = this
      switch (Boolean(value)) {
        case !conditions.lowercase.test(value):
          return createError({
            path,
            message: "Password must include lowercase letter",
          })
        case !conditions.uppercase.test(value):
          return createError({
            path,
            message: "Password must include uppercase letter",
          })
        case !conditions.digit.test(value):
          return createError({ path, message: "Password must include digit" })
        case !conditions.symbol.test(value):
          return createError({
            path,
            message:
              "Password must include special character (!, @, #, $, %, ^, &, _)",
          })
        default:
          return true
      }
    })
  }

  Yup.addMethod(Yup.string, "strongPassword", strongPasswordMethod)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Current password is required"),
      password: Yup.string()
        .required("Password is required")
        .strongPassword()
        .min(8, "Password is too short - should be 8 chars minimum."),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(setPassword(values.currentPassword, values.password))
    },
  })

  useEffect(() => {
    if (successPassword) {
      validation.resetForm()
      if (props.loadNextStep) {
        props.loadNextStep()
      }
    }
  }, [successPassword])

  return (
    <div className="p-2">
      <Form
        className="form-horizontal"
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <div className="mb-3">
          <Label className="form-label">Current Password</Label>
          <Input
            name="currentPassword"
            placeholder="Enter Current Password"
            type={"password"}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.currentPassword || ""}
            invalid={
              validation.touched.currentPassword &&
              validation.errors.currentPassword
                ? true
                : false
            }
          />
          {validation.touched.currentPassword &&
          validation.errors.currentPassword ? (
            <FormFeedback type="invalid">
              {validation.errors.currentPassword}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">New Password</Label>
          <Input
            name="password"
            placeholder="Enter Password"
            type={"password"}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.password || ""}
            invalid={
              validation.touched.password && validation.errors.password
                ? true
                : false
            }
            valid={
              validation.values.password.length >= 8 &&
              conditions.digit.test(validation.values.password) &&
              conditions.lowercase.test(validation.values.password) &&
              conditions.uppercase.test(validation.values.password) &&
              conditions.symbol.test(validation.values.password)
            }
          />
          {validation.touched.password && validation.errors.password ? (
            <FormFeedback type="invalid">
              {validation.errors.password}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3">
          <Label className="form-label">Confirm New Password</Label>
          <Input
            name="confirmPassword"
            type={"password"}
            placeholder="Confirm Password"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.confirmPassword || ""}
            invalid={
              validation.touched.confirmPassword &&
              validation.errors.confirmPassword
                ? true
                : false
            }
            valid={
              validation.dirty &&
              validation.values.confirmPassword &&
              validation.values.confirmPassword === validation.values.password
            }
          />
          {validation.touched.confirmPassword &&
          validation.errors.confirmPassword ? (
            <FormFeedback type="invalid">
              {validation.errors.confirmPassword}
            </FormFeedback>
          ) : null}
        </div>

        <div>
          <button
            className="btn btn-primary w-md float-end"
            type="submit"
            disabled={
              !validation.dirty || !validation.isValid || loadingPassword
            }
          >
            {loadingPassword ? <Spinner size="sm" /> : ""} Update Password
          </button>
        </div>
      </Form>
    </div>
  )
}

ChangePasswordForm.propTypes = {
  user: PropTypes.object,
  loadNextStep: PropTypes.func,
}

export default ChangePasswordForm
