import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Row,
  Form,
  Label,
  Input,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Spinner,
  Badge,
} from "reactstrap"

import Select from "react-select"
import makeAnimated from "react-select/animated"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { tripStatus as tripStatusOptions } from "../../constants/tripStatus"
import useQuery from "hooks/useQuery"

const TripsFilter = ({
  refetch,
  stations = [],
  setAdvancedFilters,
  searchAdvancedFilters,
}) => {
  const animatedComponents = makeAnimated()

  const initialFilterState = {
    startStation: [],
    endStation: [],
    tripStatus: [],
    startDate: null,
    endDate: null,
    startDateTimestamp: null,
    endDateTimestamp: null,
  }

  const [filters, setFilters] = useState(initialFilterState)

  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [filtersCount, setFiltersCount] = useState(0)

  const query = useQuery()
  const queryStartStation = query.get("startStation")
  const queryEndStation = query.get("endStation")
  const queryStartDate = query.get("startDate")
  const queryEndDate = query.get("endDate")
  const queryTripStatus = query.get("tripStatus")
  const toggle = () => setIsOpen(!isOpen)

  const handleSelectChange = (e, { name }) => {
    setFilters(prev => ({
      ...prev,
      [name]: e,
    }))
  }

  const handleStartDateChange = date => {
    setFilters(prev => ({
      ...prev,
      startDate: date,
      startDateTimestamp: Date.now(new Date(date)),
    }))
  }

  const handleEndDateChange = date => {
    setFilters(prev => ({
      ...prev,
      endDate: date,
      endDateTimestamp: Date.now(new Date(date)),
    }))
  }

  const constructUrl = () => {
    const currentUrl = new URL(window.location.href)
    const {
      startStation,
      endStation,
      startDateTimestamp,
      endDateTimestamp,
      tripStatus,
    } = filters
    const paramsObj = {
      ...(startStation.length && {
        startStation: JSON.stringify(
          startStation.map(st => encodeURIComponent(st.value))
        ),
      }),
      ...(endStation.length && {
        endStation: JSON.stringify(
          endStation.map(st => encodeURIComponent(st.value))
        ),
      }),
      ...(tripStatus.length && {
        tripStatus: JSON.stringify(
          tripStatus.map(ts => encodeURIComponent(ts.value))
        ),
      }),
      ...(startDateTimestamp && { startDate: startDateTimestamp }),
      ...(endDateTimestamp && { endDate: endDateTimestamp }),
    }

    setFiltersCount(Object.values(paramsObj).length)

    const newParams = new URLSearchParams([
      ...Array.from(currentUrl.searchParams.entries()),
      ...Object.entries(paramsObj),
    ])

    for (const [key, value] of newParams.entries()) {
      currentUrl.searchParams.set(key, value)
    }

    window.history.replaceState(null, null, currentUrl)
  }

  const handleSearch = () => {
    setLoading(true)
    constructUrl()
    searchAdvancedFilters()
    setTimeout(() => setLoading(false), 1000)
  }

  const clearFilters = () => {
    if (filtersCount === 0) {
      setIsOpen(false)
    } else {
      setFilters(initialFilterState)
      setAdvancedFilters(initialFilterState)
      const currentUrl = new URL(window.location.href)
      for (const [key, value] of query.entries()) {
        if (key !== "page" || key !== "pageSize") {
          currentUrl.searchParams.delete(key)
        }
      }
      window.history.replaceState(null, null, currentUrl)
      setFiltersCount(0)
      setLoading(false)
      searchAdvancedFilters(true)
    }
  }

  const prefillSelect = query => {
    return decodeURIComponent(query.slice(1, -1))
      .split(",")
      .map(st => ({
        label: st.slice(1, -1),
        value: st.slice(1, -1),
      }))
  }

  const parseTimeStamp = timestamp => {
    return new Date(Number(timestamp))
  }

  useEffect(() => {
    const activeFilters = {}
    if (queryStartStation) {
      const prefilledStartStation = prefillSelect(queryStartStation)
      setFilters(prev => ({ ...prev, startStation: prefilledStartStation }))
      activeFilters.startStation = true
    }

    if (queryEndStation) {
      const prefilledEndStation = prefillSelect(queryEndStation)
      setFilters(prev => ({ ...prev, endStation: prefilledEndStation }))
      activeFilters.endStation = true
    }

    if (queryStartDate) {
      setFilters(prev => ({
        ...prev,
        startDate: parseTimeStamp(queryStartDate),
      }))
      activeFilters.startDate = true
    }

    if (queryEndDate) {
      setFilters(prev => ({ ...prev, endDate: parseTimeStamp(queryEndDate) }))
      activeFilters.endDate = true
    }

    if (queryTripStatus) {
      const prefilledTripStatus = prefillSelect(queryTripStatus)
      setFilters(prev => ({ ...prev, tripStatus: prefilledTripStatus }))
      activeFilters.tripStatus = true
    }

    setFiltersCount(Object.values(activeFilters).length)

    if (
      queryStartStation ||
      queryEndStation ||
      queryStartDate ||
      queryEndDate ||
      queryTripStatus
    ) {
      setIsOpen(true)
    }
  }, [])

  useEffect(() => {
    setAdvancedFilters(filters)
  }, [filters])

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="job-filter">
            <CardBody>
              <Form>
                <Row className="g-3 mb-3">
                  <div className="d-flex align-items-center">
                    <h5 className="card-title flex-grow-1">Trips List</h5>
                    <div className="flex-shrink-0">
                      {/* <Link to="#!" className="btn btn-light me-1"> */}
                      <a
                        href="#"
                        className="btn btn-light me-1"
                        onClick={refetch}
                      >
                        <i className="mdi mdi-refresh"></i>{" "}
                        <span className="d-none d-md-inline">Refresh</span>
                      </a>
                      {/* </Link> */}
                      {/* <a
                        href="#"
                        onClick={toggle}
                        className="btn btn-dark me-1"
                      >
                        <i className="bx bx-filter-alt align-middle"></i>{" "}
                        <span className="badge bg-danger rounded-pill">3</span>
                        <span className="d-none d-md-inline">Advance</span>
                      </a> */}
                      <button
                        type="button"
                        onClick={toggle}
                        className="btn btn-light position-relative me-1"
                      >
                        {filtersCount > 0 && (
                          <Badge className="bg-success ms-1">
                            {filtersCount}
                          </Badge>
                        )}
                        <i className="bx bx-filter-alt align-middle"></i>
                        <span className="d-none d-md-inline">Advance </span>
                      </button>
                    </div>
                  </div>
                </Row>
                <Row className="g-3">
                  <Collapse isOpen={isOpen} id="collapseExample">
                    <div>
                      <Row className="g-3 mb-3">
                        <Col xxl={4} lg={6}>
                          <div>
                            <Label
                              htmlFor="startStation"
                              className="form-label fw-semibold"
                            >
                              Start Station(s)
                            </Label>
                          </div>
                          {stations.length && (
                            <Select
                              options={stations}
                              isMulti
                              components={animatedComponents}
                              closeMenuOnSelect={false}
                              name="startStation"
                              value={filters["startStation"]}
                              key={`select-${filters["startStation"]}`}
                              // value={filters["startStation"] || ""}
                              onChange={handleSelectChange}
                              // defaultValue={
                              //   queryStartStation
                              //     ?
                              //     : ""
                              // }
                              // defaultValue={[stations[0]]}
                            />
                          )}
                        </Col>
                        <Col xxl={4} lg={6}>
                          <div>
                            <Label
                              htmlFor="endStation"
                              className="form-label fw-semibold"
                            >
                              End Station(s)
                            </Label>
                          </div>
                          <Select
                            options={stations}
                            isMulti
                            components={animatedComponents}
                            closeMenuOnSelect={false}
                            name="endStation"
                            key={`select-${filters["endStation"]}`}
                            // value={filters["endStation"] || ""}
                            value={filters["endStation"]}
                            onChange={handleSelectChange}
                          />
                        </Col>
                        <Col xxl={4} lg={6}>
                          <div>
                            <Label
                              htmlFor="jobType"
                              className="form-label fw-semibold"
                            >
                              Trip Status
                            </Label>
                          </div>
                          <Select
                            options={tripStatusOptions}
                            isMulti
                            components={animatedComponents}
                            closeMenuOnSelect={false}
                            name="tripStatus"
                            value={filters["tripStatus"]}
                            key={`select-${filters["tripStatus"]}`}
                            // value={filters["startStation"] || ""}
                            onChange={handleSelectChange}
                          />
                          {/* {tripStatus.map((status, i) => (
                            <div
                              key={"trip-status-select-" + status.value}
                              className="form-check form-check-inline"
                            >
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id={"trip-status-select-" + status.value}
                                value={status.value}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor={"trip-status-select-" + status.value}
                              >
                                {status.label}
                              </Label>
                            </div>
                          ))} */}
                        </Col>
                        {/* <Col xxl={3} lg={6}>
                          <div className="position-relative">
                            <Label
                              htmlFor="qualificationInput"
                              className="form-label fw-semibold"
                            >
                              Start Date
                            </Label>
                            <div className="position-relative">
                              <div id="datepicker1">
                                <DatePicker
                                  className="form-control"
                                  selected={filters["startDate"]}
                                  onChange={handleStartDateChange}
                                  placeholderText="Select start range"
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xxl={3} lg={6}>
                          <div className="position-relative">
                            <Label
                              htmlFor="qualificationInput"
                              className="form-label fw-semibold"
                            >
                              End Date
                            </Label>
                            <div className="position-relative">
                              <div id="datepicker2">
                                <DatePicker
                                  className="form-control"
                                  selected={filters["endDate"]}
                                  onChange={handleEndDateChange}
                                  placeholderText="Select end range"
                                />
                              </div>
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                      <Row className="g-3">
                        {/* <Col xxl={4} lg={6}>
                          <div>
                            <Label
                              htmlFor="rating"
                              className="form-label fw-semibold"
                            >
                              Rating
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox1"
                              value="option1"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="inlineCheckbox1"
                            >
                              1
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox2"
                              value="option1"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="inlineCheckbox2"
                            >
                              2
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox3"
                              value="option2"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="inlineCheckbox3"
                            >
                              3
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox4"
                              value="option2"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="inlineCheckbox4"
                            >
                              4
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox5"
                              value="option3"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="inlineCheckbox5"
                            >
                              5
                            </Label>
                          </div>
                        </Col> */}
                      </Row>
                      <Row className="g-3 my-3">
                        <div className="d-flex align-items-center">
                          <div className="card-title flex-grow-1"></div>
                          <div className="flex-shrink-0">
                            <a
                              href="#!"
                              className="btn btn-light me-1 text-decoration-underline"
                              onClick={clearFilters}
                            >
                              Clear
                            </a>
                            <a
                              href="#"
                              onClick={handleSearch}
                              className="btn btn-success me-1"
                            >
                              {loading ? (
                                <Spinner size="sm" />
                              ) : (
                                <>
                                  <i className="bx bx-search-alt align-middle" />
                                  <span className="d-none d-md-inline">
                                    Search Trips
                                  </span>
                                </>
                              )}
                            </a>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Collapse>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

TripsFilter.propTypes = {
  refetch: PropTypes.func,
  stations: PropTypes.array,
  setAdvancedFilters: PropTypes.func,
  searchAdvancedFilters: PropTypes.func,
}

export default TripsFilter
