import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  GET_CURRENT_USER,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  loading: false,
  account: {},
  defaultRoute: "",
  allowedRoutes: [],
  permissions: [],
  loadingConfig: false,
  loadingEdit: false,
  loadingPassword: false,
  successPassword: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = {
        ...state,
        success: "",
        error: "",
        loadingEdit: true,
      }
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: action.payload.msg,
        account: { ...state.account, ...action.payload.updates },
        loadingEdit: false,
      }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload, loadingEdit: false }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: "" }
      break
    case SET_PASSWORD:
      state = { ...state, loadingPassword: true }
      break
    case SET_PASSWORD_SUCCESS:
      state = {
        ...state,
        successPassword: action.payload,
        error: "",
        loadingPassword: false,
      }
      break
    case SET_PASSWORD_ERROR:
      state = {
        ...state,
        error: action.payload,
        success: "",
        loadingPassword: false,
      }
      break
    case GET_CURRENT_USER:
      state = { ...state, loadingConfig: true }
      break
    case GET_CURRENT_USER_SUCCESS:
      state = {
        ...state,
        defaultRoute: action.payload.roleData.defaultRoute,
        allowedRoutes: action.payload.roleData.allowedRoutes,
        permissions: action.payload.roleData.permissions,
        account: action.payload,
        loadingConfig: false,
      }
      break
    case GET_CURRENT_USER_FAIL:
      state = {
        ...state,
        error: action.payload,
        loadingConfig: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
