/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useRef } from "react"
import { withRouter } from "react-router-dom"

import {
  Col,
  Row,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  Card,
  CardHeader,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { motion } from "framer-motion/dist/es/index"
import { isEmpty } from "lodash"
import useSupercluster from "use-supercluster"
import useMobile from "hooks/useMobile"
import useVehicleMap from "hooks/useVehicleMap"

import { useSelector, useDispatch } from "react-redux"

import { isEmptyObject } from "utils"
import { convertBikeBattery, convertScooterBattery } from "utils"
import { getVehicles as onGetVehicles, changeDrawMode } from "store/actions"
import Restricted from "components/Common/Restricted"
import MapSidebar from "./MapSidebar"
import { SidenavHeader } from "./SidenavHeader"
import { CitySelect } from "./CitySelect"
import { FloatingSideButtons } from "./FloatingSideButtons"
import VehicleDetails from "./VehicleDetails"
import ZoneDetails from "./ZoneDetails"
import Marker from "./Marker"
import ClusterMarker from "./ClusterMarker"
import HelpPopover from "./HelpPopover"
import BulkView from "./BulkView"
import AdvancedFilter from "./AdvancedFilter"
import BaseMap from "./BaseMap"
import { MobileCanvas } from "./MobileCanvas"
import CardRemoveSelection from "./CardRemoveSelection"
import { defaultCenter, defaultZoom } from "./constants"

const Map = () => {
  const isMobile = useMobile()
  const [stations, locations, zones, vehiclesList, loading] = useVehicleMap()

  const dispatch = useDispatch()
  const { vehiclesView, stationsView, clusterView } = useSelector(state => ({
    vehiclesView: state.map.vehiclesView,
    stationsView: state.map.stationsView,
    clusterView: state.map.clusterView,
  }))
  const accordElem = useRef(null)

  // map
  const [map, setMap] = useState()
  const [maps, setMaps] = useState()
  const [drawingManager, setDrawingManager] = useState()
  const [zoom, setZoom] = useState(defaultZoom)

  const [bounds, setBounds] = useState(null)
  const [vehicles, setVehicles] = useState([])
  const [points, setPoints] = useState("")
  const [allPoints, setAllPoints] = useState("")

  const [polygons, setPolygons] = useState([])
  const [polygonDrawMode, setPolygonDrawMode] = useState(false)
  const [rectangleDrawMode, setRectangleDrawMode] = useState(false)
  const [drawnShape, setDrawnShape] = useState([])

  const [city, setCity] = useState("")
  const [history, setHistory] = useState([])

  const [selectedVehicles, setSelectedVehicles] = useState([])
  const [selectionBounds, setSelectionBounds] = useState()
  const [polygonPopup, setPolygonPopup] = useState(false)
  const [popupContents, setPopupContents] = useState(false)

  // mobile
  const [selectedVehicle, setSelectedVehicle] = useState({})
  const [selectedZone, setSelectedZone] = useState()
  const [canvasOpen, setCanvasOpen] = useState(false)

  const handleFilter = filters => {
    map.setZoom(6)
    let filteredPoints = allPoints
    let filteredVehicles = vehicles
    if (!isEmptyObject(filters)) {
      if (filters.vehicleType) {
        filteredPoints = filteredPoints.filter(
          point =>
            point.properties?.vehicle?.vehicleType === filters.vehicleType
        )
        filteredVehicles = filteredVehicles.filter(
          vehicle => vehicle?.vehicleType === filters.vehicleType
        )
      }
      if (filters.vehicleStatus?.length) {
        const vehicleStatus = filters.vehicleStatus.map(status => status.value)

        filteredPoints = filteredPoints.filter(point => {
          if (vehicleStatus.indexOf(point.properties?.vehicle?.status) > -1) {
            return point
          }
        })
        filteredVehicles = filteredVehicles.filter(vehicle => {
          if (vehicleStatus.indexOf(vehicle?.status) > -1) {
            return vehicle
          }
        })
      }

      if (filters.isRatedOne) {
        filteredPoints = filteredPoints.filter(
          point => point.properties?.vehicle?.isRatedOne
        )
        filteredVehicles = filteredVehicles.filter(
          vehicle => vehicle?.isRatedOne
        )
      }

      if (filters.inStation) {
        filteredPoints = filteredPoints.filter(
          point => point.properties?.vehicle?.inStation
        )
        filteredVehicles = filteredVehicles.filter(
          vehicle => vehicle?.inStation
        )
      }

      const defaultBatteryFilter =
        filters.startBattery === 0 && filters.endBattery === 100
      if (!defaultBatteryFilter) {
        filteredPoints = filteredPoints.filter(point => {
          const vehicle = point.properties?.vehicle
          let battery =
            vehicle.vehicleType === "scooter"
              ? convertScooterBattery(vehicle.Battery)
              : convertBikeBattery(vehicle.lockBattery)

          if (
            !isNaN(battery) &&
            battery >= filters.startBattery &&
            battery <= filters.endBattery
          ) {
            return point
          }
        })

        filteredVehicles = filteredVehicles.filter(vehicle => {
          let battery =
            vehicle.vehicleType === "scooter"
              ? convertScooterBattery(vehicle.Battery)
              : convertBikeBattery(vehicle.lockBattery)
          if (
            !isNaN(battery) &&
            battery >= filters.startBattery &&
            battery <= filters.endBattery
          ) {
            return vehicle
          }
        })
      }
    }
    setPoints(filteredPoints)
    if (selectedVehicles.length) {
      const pointsWithinBounds = []
      filteredVehicles.forEach(vehicle => {
        const { lat, lng } = vehicle
        const latLng = new google.maps.LatLng(parseFloat(lat), parseFloat(lng))

        if (selectionBounds.contains(latLng)) {
          pointsWithinBounds.push(vehicle)
        }
      })

      setSelectedVehicles(pointsWithinBounds)
    }

    initPoints(filteredVehicles)
  }
  const searchVehicle = scooterId => {
    let vehicle = vehicles.find(vehicle => vehicle.scooterId == scooterId)

    if (vehicle) {
      setSelectedVehicle(vehicle)
      setCanvasOpen(true)
      setHistory(prev => [
        ...prev,
        {
          state: vehicle,
          type: "vehicle",
          coords: { lat: vehicle.lat, lng: vehicle.lng },
        },
      ])
      map.setCenter({
        lat: vehicle.lat,
        lng: vehicle.lng,
      })
      map.panTo({
        lat: vehicle.lat,
        lng: vehicle.lng,
      })
      map.setZoom(16)
      if (isMobile) {
        toggleCanvas()
      }
      const filteredPoint = allPoints.filter(
        point => point.properties?.vehicle?.IMEI === vehicle.IMEI
      )
      setPoints(filteredPoint)
    } else {
      setSelectedVehicle()
      setHistory(prev => [
        ...prev,
        {
          state: {},
          type: "vehicle",
          coords: defaultCenter,
        },
      ])
      setPoints([])
    }
  }

  const initPoints = vehicles => {
    const points = vehicles.map(vehicle => ({
      type: "Feature",
      properties: {
        cluster: false,
        vehicle: vehicle,
      },
      geometry: {
        type: "Point",
        coordinates: [parseFloat(vehicle.lng), parseFloat(vehicle.lat)],
      },
    }))
    setPoints(points)
    setAllPoints(points)
  }

  const renderZonesAndPos = async (map, maps) => {
    const colorLegend = {
      operation: "#000000",
      forbidden: "#FF0000",
      lowSpeed: "#f1b44c",
    }
    zones.forEach(zone => {
      const zonePolygon = new maps.Polygon({
        paths: zone.zoneBoundaries.map(({ latitude, longitude }) => ({
          lat: latitude,
          lng: longitude,
        })),
        strokeColor: colorLegend[zone.zoneType],
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: colorLegend[zone.zoneType],
        fillOpacity: 0.35,
        // visibility: false,
      })

      zonePolygon.setMap(map)
      maps.event.addListener(zonePolygon, "click", function (event) {
        setSelectedZone(zone)
        setCanvasOpen(true)
        setSelectedVehicle()
        setHistory(prev => [
          ...prev,
          {
            state: zone,
            type: "zone",
            coords: {
              lat: zone.zoneBoundaries[0].latitude,
              lng: zone.zoneBoundaries[0].longitude,
            },
          },
        ])
      })
      setPolygons(prev => [...prev, zonePolygon])

      if (zone.pointOfInterest) {
        const { lat, lng, iconUrl } = zone.pointOfInterest
        console.log("renderZonesAndPos -> lat:", lat)
        const marker = new maps.Marker({
          icon: {
            url: iconUrl,
            scaledSize: new google.maps.Size(50, 50),
          },
          position: {
            lat,
            lng,
          },
          zIndex: 3,
        })
        marker.setMap(map)
        maps.event.addListener(marker, "click", function (event) {
          setSelectedZone(zone)
          setCanvasOpen(true)
          setSelectedVehicle()
          setHistory(prev => [
            ...prev,
            {
              state: zone,
              type: "zone",
              coords: {
                lat: zone.zoneBoundaries[0].latitude,
                lng: zone.zoneBoundaries[0].longitude,
              },
            },
          ])
        })
      }

      if (zone.controlZones) {
        const controlZoneValues = Object.values(zone.controlZones)
        controlZoneValues.forEach(controlZone => {
          const controlZonePolygon = new maps.Polygon({
            paths: controlZone.zoneBoundaries.map(
              ({ latitude, longitude }) => ({
                lat: latitude,
                lng: longitude,
              })
            ),
            strokeColor: colorLegend[controlZone.zoneType],
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: colorLegend[controlZone.zoneType],
            fillOpacity: 0.35,
          })

          controlZonePolygon.setMap(map)
          maps.event.addListener(controlZonePolygon, "click", function (event) {
            setSelectedZone(controlZone)
            setCanvasOpen(true)
            setSelectedVehicle()
            setHistory(prev => [
              ...prev,
              {
                state: controlZone,
                type: "zone",
                coords: {
                  lat: controlZone.zoneBoundaries[0].latitude,
                  lng: controlZone.zoneBoundaries[0].longitude,
                },
              },
            ])
          })
          setPolygons(prev => [...prev, controlZonePolygon])
        })
      }
    })
  }

  const apiIsLoaded = async (map, maps) => {
    setMap(map)
    setMaps(maps)

    const cityControl = document.getElementById("city-visibility")
    map.controls[maps.ControlPosition.TOP_CENTER].push(cityControl)

    const resourceControl = document.getElementById("resource-visibility")
    map.controls[maps.ControlPosition.TOP_RIGHT].push(resourceControl)

    const filterControl = document.getElementById("filter-button-accordion")
    map.controls[maps.ControlPosition.TOP_LEFT].push(filterControl)

    // const mobileDetails = document.getElementById("mobile-details")
    // map.controls[maps.ControlPosition.BOTTOM_CENTER].push(mobileDetails)

    // document.getElementById("close-mobile-details").onclick = function () {
    //   setSelectedVehicle(null)
    // }
  }

  const loadDrawingManager = async (type = 1) => {
    const drawingMode =
      type === 1
        ? google.maps.drawing.OverlayType.POLYGON
        : google.maps.drawing.OverlayType.RECTANGLE
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: drawingMode,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,

          google.maps.drawing.OverlayType.RECTANGLE,
        ],
      },
      polygonOptions: {
        strokeColor: "#0062cc",
        strokeOpacity: 0.5,
        strokeWeight: 3,
        fillColor: "#133c55",
        fillOpacity: 0.5,
        zIndex: 1,
      },
      rectangleOptions: {
        strokeColor: "#0062cc",
        strokeOpacity: 0.5,
        strokeWeight: 3,
        fillColor: "#133c55",
        fillOpacity: 0.5,
        zIndex: 1,
      },
    })
    const infoWindow = new maps.InfoWindow()
    // setInfoWindow(infoWindow)

    google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      async function (event) {
        const pointsWithinBounds = []
        let bounds = new google.maps.LatLngBounds()
        const shape = event.overlay
        if (event.type === "polygon") {
          const paths = shape.getPaths()
          paths.forEach(function (path) {
            path.forEach(function (latlng) {
              bounds.extend(latlng)
            })
          })
        }
        if (event.type === "rectangle") {
          bounds = shape.getBounds()
        }
        map.fitBounds(bounds)
        const points = await getPoints()

        points.forEach(point => {
          const [lng, lat] = point.geometry.coordinates
          const latLng = new google.maps.LatLng(
            parseFloat(lat),
            parseFloat(lng)
          )

          if (bounds.contains(latLng)) {
            pointsWithinBounds.push(point.properties.vehicle)
          }
        })

        setSelectedVehicles(pointsWithinBounds)
        setSelectionBounds(bounds)
        setDrawnShape(shape)
        drawingManager.setMap(null)
        setRectangleDrawMode(false)
        setPolygonDrawMode(false)

        google.maps.event.addListener(shape, "click", function (event) {
          const latLng = event.latLng
          setPolygonPopup(!polygonPopup)
          setPopupContents({ lat: latLng.lat(), lng: latLng.lng(), shape })
        })
      }
    )
    drawingManager.setMap(map)
    setDrawingManager(drawingManager)
  }

  const getPoints = async () => {
    return points
  }

  const setDrawingManagerType = type => {
    const drawingMode =
      type === 1
        ? google.maps.drawing.OverlayType.POLYGON
        : google.maps.drawing.OverlayType.RECTANGLE

    drawingManager.setDrawingMode(drawingMode)
    drawingManager.setMap(map)
  }

  const toggleRectangle = () => {
    if (drawingManager) {
      if (rectangleDrawMode) {
        drawingManager.setMap(null)
      } else {
        drawingManager.setMap(map)
      }
    }
    setRectangleDrawMode(!rectangleDrawMode)
  }
  const togglePolygon = () => {
    if (drawingManager) {
      if (polygonDrawMode) {
        drawingManager.setMap(null)
      } else {
        drawingManager.setMap(map)
      }
    }
    setPolygonDrawMode(!polygonDrawMode)
  }
  const handleSelectMultipleClick = async type => {
    if (!drawingManager) {
      await loadDrawingManager(type)
    } else {
      setDrawingManagerType(type)
    }

    if (type === 1) {
      togglePolygon()
      setRectangleDrawMode(false)
    } else {
      toggleRectangle()
      setPolygonDrawMode(false)
    }
  }

  const removeShape = () => {
    drawnShape.setMap(null)
    setDrawnShape()
  }

  const removeFromDrawHistory = index => {
    setSelectedVehicles([])
  }

  const showDefaultMapView = () => {
    map.panTo(defaultCenter)
    map.setZoom(defaultZoom)
    setCity("")
    setSelectedVehicle(null)
    setSelectedZone(null)
  }

  const showAllVehicles = () => {
    setVehicles(vehiclesList)
    initPoints(vehiclesList)
  }

  const removeSelection = () => {
    removeShape()
    removeFromDrawHistory()
    setPolygonPopup(false)
  }

  const handleVehicleMarkerClick = (vehicle, coordinates) => {
    setSelectedVehicle(vehicle)
    setCanvasOpen(true)
    setSelectedZone()
    const center = map.getCenter()
    setHistory(prev => [
      ...prev,
      {
        state: vehicle,
        type: "vehicle",
        coords: {
          lat: center.lat(),
          lng: center.lng(),
        },
      },
    ])
    map.setCenter({
      lat: coordinates.lat,
      lng: coordinates.lng,
    })
  }

  const handleCitySelect = e => {
    if (e.target.value) {
      const { lat, lon: lng, name_en } = JSON.parse(e.target.value)

      map.panTo({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      })
      map.setZoom(12)
      setCity(name_en)
    } else {
      map.setZoom(6)
      map.setCenter(defaultCenter)
      setCity("")
    }
  }

  const panToPrevious = () => {
    map.panTo(history[history.length - 1].coords)
  }

  const handleLocateMeClick = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const userLocation = {
          lat: position.coords.latitude, // ADDED
          lng: position.coords.longitude, // ADDED
        }
        map.setCenter(userLocation) // ADDED

        const blueDot = {
          path: maps.SymbolPath.CIRCLE,
          fillColor: "#4285F4",
          fillOpacity: 1,
          scale: 8,
          strokeColor: "white",
          strokeWeight: 2,
        }

        const outerBlueDot = {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: "#C8D6EC",
          fillOpacity: 0.7,
          scale: 16,
          strokeWeight: 0,
        }

        const marker = new maps.Marker({
          icon: blueDot,
          position: userLocation,
          zIndex: 3,
        })

        const outerMarker = new maps.Marker({
          icon: outerBlueDot,
          position: userLocation,
          title: "You are here!",
          zIndex: 2,
        })
        marker.setMap(map)
        outerMarker.setMap(map)
        map.setZoom(14)

        setCity("")
      })
    } else {
      // code for legacy browsers
    }
  }

  const handleClusterClick = cluster => {
    const [longitude, latitude] = cluster.geometry.coordinates
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.id),
      20
    )
    map.setZoom(expansionZoom)
    map.panTo({
      lat: latitude,
      lng: longitude,
    })
  }

  // MOBILE
  const toggleCanvas = () => {
    setCanvasOpen(!canvasOpen)
  }

  useEffect(() => {
    if (selectedVehicles.length) {
      dispatch(changeDrawMode(true))
    } else {
      dispatch(changeDrawMode(false))
    }
  }, [selectedVehicles])

  useEffect(() => {
    if (vehiclesList.length) {
      setVehicles(vehiclesList)
      initPoints(vehiclesList)
    }
  }, [vehiclesList])

  // useEffect(() => {
  //   initPoints(vehicles)
  // }, [props.location.pathname])

  useEffect(() => {
    if (vehiclesView) {
      if (!vehicles.length) {
        initPoints(vehiclesList)
      } else {
        initPoints(vehicles)
      }
    } else {
      setPoints([])
    }

    if (stationsView) {
      polygons.forEach(polygon => polygon.setVisible(true))
    } else {
      polygons.forEach(polygon => polygon.setVisible(false))
    }
  }, [vehiclesView, stationsView])

  useEffect(() => {
    if (!polygons.length && zones.length && map) {
      renderZonesAndPos(map, maps)
    }
  }, [zones])

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20, minPoints: 5 },
  })

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Map | Gazal - B2B Dashboard</title>
        </MetaTags>

        <Row className="page-content">
          <Col lg={12} className="p-0 m-0 d-flex align-items-center">
            <div
              style={{ height: "80vh", width: "100vw" }}
              id="#mainMap"
              className="map-container"
            >
              {
                <BaseMap
                  onGoogleApiLoaded={({ map, maps }) => {
                    apiIsLoaded(map, maps)
                  }}
                  onChange={({ zoom, bounds }) => {
                    setZoom(zoom)
                    setBounds([
                      bounds.nw.lng,
                      bounds.se.lat,
                      bounds.se.lng,
                      bounds.nw.lat,
                    ])
                  }}
                >
                  {/* <div id="mobile-details">
                    {!isEmpty(selectedVehicle) && isMobile ? (
                      <Card
                        className="my-2 box-shadow"
                        style={{
                          width: "60vw",
                          zIndex: 99999,
                          // marginBottom: "5rem",
                          margin: -100,
                          backgroundColor: "rgba(255, 255, 255, 0.97)",
                        }}
                      >
                        <CardHeader
                          className="text-end"
                          // onClick={() => alert("hi")}
                          // onMouseDown={() => alert("hi")}
                        >
                          <a
                            id="close-mobile-details"
                            // className="btn btn-sm"
                            // onClick={() => setSelectedVehicle(null)}
                            onClick={console.log}
                          >
                            <i className="mdi mdi-close" />
                          </a>
                        </CardHeader>
                        <VehicleDetails vehicle={selectedVehicle} />
                      </Card>
                    ) : (
                      <></>
                    )}

                    {!isEmpty(selectedZone) && isMobile ? (
                      <Card
                        className="my-2 box-shadow"
                        style={{
                          width: "60vw",
                          zIndex: 99999,
                          marginBottom: "5rem",
                          backgroundColor: "rgba(255, 255, 255, 0.97)",
                        }}
                      >
                        <CardHeader className="text-end">
                          <a onClick={() => setSelectedZone(null)}>
                            <i className="mdi mdi-close" />
                          </a>
                        </CardHeader>
                        <ZoneDetails zone={selectedZone} />
                      </Card>
                    ) : (
                      <></>
                    )}
                  </div> */}

                  {polygonPopup ? (
                    <div lat={popupContents.lat} lng={popupContents.lng}>
                      <CardRemoveSelection
                        numSelected={selectedVehicles.length}
                        removeSelection={removeSelection}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  {clusterView
                    ? clusters.map(cluster => {
                        const [longitude, latitude] =
                          cluster.geometry.coordinates
                        const {
                          cluster: isCluster,
                          vehicle,
                          point_count: pointCount,
                        } = cluster.properties

                        if (isCluster) {
                          return (
                            <ClusterMarker
                              key={`cluster-${cluster.id}`}
                              lat={latitude}
                              lng={longitude}
                              cluster={cluster}
                              ratio={pointCount / points.length}
                              handleClusterClick={handleClusterClick}
                            />
                          )
                        }

                        return (
                          <Marker
                            vehicle={vehicle}
                            key={`cluster-${vehicle.scooterId}-${cluster.id}`}
                            lat={latitude}
                            lng={longitude}
                            onClick={() =>
                              handleVehicleMarkerClick(vehicle, {
                                lat: vehicle.lat,
                                lng: vehicle.lng,
                              })
                            }
                          />
                        )
                      })
                    : points.map(point => (
                        <Marker
                          key={`point-${point.properties.vehicle.scooterId}`}
                          lat={point.geometry.coordinates[1]}
                          lng={point.geometry.coordinates[0]}
                          onClick={() =>
                            handleVehicleMarkerClick(point.properties.vehicle, {
                              lat: point.geometry.coordinates[1],
                              lng: point.geometry.coordinates[0],
                            })
                          }
                          vehicle={point.properties.vehicle}
                        />
                      ))}
                </BaseMap>
              }
            </div>
          </Col>
        </Row>

        {/* </Container> */}

        <div id="filter-button-accordion" className="d-none d-lg-block">
          <Row className="m-3">
            <Col>
              <UncontrolledAccordion
                className="bg-white rounded floating-shadow"
                flush
                // stayOpen
                defaultOpen={[1]}
                ref={accordElem}
              >
                <AccordionItem className="rounded">
                  <AccordionHeader targetId={1}>
                    <div className="font-size-16 mx-2 my-1">
                      <i className="mdi mdi-speedometer text-primary" /> Quick
                      View
                    </div>
                  </AccordionHeader>
                  <AccordionBody accordionId={1} className="bg-white">
                    <>
                      <div
                        style={{
                          minWidth: "20vw",
                          maxWidth: "20vw",
                        }}
                      >
                        {history.length ? (
                          <>
                            <SidenavHeader
                              history={history}
                              setHistory={setHistory}
                              showDefaultMapView={showDefaultMapView}
                              showAllVehicles={showAllVehicles}
                              panToPrevious={panToPrevious}
                            />
                            {history[history.length - 1].type === "vehicle" && (
                              <VehicleDetails
                                vehicle={history[history.length - 1].state}
                              />
                            )}
                            {history[history.length - 1].type === "zone" && (
                              <ZoneDetails
                                zone={history[history.length - 1].state}
                              />
                            )}
                          </>
                        ) : (
                          <MapSidebar
                            city={city}
                            vehicles={vehiclesList}
                            searchVehicle={searchVehicle}
                            handleFilter={handleFilter}
                            clusters={clusters}
                            stations={stations}
                            loading={loading}
                            points={points}
                            refetch={() => {
                              initPoints([])
                              dispatch(onGetVehicles())
                            }}
                            resetView={showDefaultMapView}
                          />
                        )}
                      </div>
                    </>
                  </AccordionBody>
                </AccordionItem>
                <Restricted to="vehicles:FILTER_VEHICLE">
                  <AccordionItem className="rounded">
                    <AccordionHeader targetId={2}>
                      <div className="font-size-16 mx-2 my-1">
                        <i className=" font-size-14 fas fa-sliders-h text-primary" />{" "}
                        Advanced Filters
                      </div>
                    </AccordionHeader>
                    <AccordionBody accordionId={2} className="bg-white">
                      <div
                        style={{
                          minWidth: "20vw",
                          maxWidth: "20vw",
                        }}
                      >
                        <AdvancedFilter
                          handleFilter={handleFilter}
                          points={points}
                        />{" "}
                      </div>
                    </AccordionBody>
                  </AccordionItem>
                </Restricted>
                {/* Additional Accordions */}
              </UncontrolledAccordion>
            </Col>
          </Row>
        </div>
        <div id="resource-visibility">
          <Row>
            <Col>
              <div
                id="bulk-button-accordion"
                style={{
                  minWidth: "20vw",
                }}
              >
                {selectedVehicles.length ? (
                  <Row className="m-3 rounded">
                    <Col>
                      <UncontrolledAccordion
                        className="bg-white rounded floating-shadow"
                        flush
                        stayOpen
                        defaultOpen={[3]}
                      >
                        <AccordionItem className="rounded">
                          <AccordionHeader targetId={3}>
                            <ul className="list-inline text-end m-0">
                              <li className="list-inline-item font-size-16 mx-2">
                                <span className="text-primary">
                                  <strong>{selectedVehicles.length}</strong>{" "}
                                </span>
                                Vehicles Selected
                              </li>
                              <li className="list-inline-item">
                                <button
                                  className="btn text-decoration-underline"
                                  onClick={e => {
                                    e.stopPropagation()
                                    removeShape()
                                    removeFromDrawHistory()
                                  }}
                                >
                                  Clear
                                </button>
                              </li>
                            </ul>
                          </AccordionHeader>

                          <AccordionBody accordionId={3} className="bg-white">
                            <div
                              style={{
                                minWidth: "20vw",
                                maxWidth: "20vw",
                              }}
                            >
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                  type: "spring",
                                  stiffness: 400,
                                  damping: 20,
                                  delay: Math.random() * 0.3,
                                }}
                              >
                                <BulkView
                                  selectedVehicles={selectedVehicles}
                                  refetch={() => {
                                    dispatch(onGetVehicles())
                                  }}
                                />

                                {/* )} */}
                              </motion.div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </div>
            </Col>
            <Col>
              <FloatingSideButtons
                handleLocateMeClick={handleLocateMeClick}
                handleSelectMultipleClick={handleSelectMultipleClick}
              />
            </Col>
          </Row>
        </div>

        <CitySelect handleCitySelect={handleCitySelect} locations={locations} />

        <HelpPopover />

        {isMobile && (
          <MobileCanvas canvasOpen={canvasOpen} toggleCanvas={toggleCanvas}>
            {history.length ? (
              <>
                <SidenavHeader
                  history={history}
                  setHistory={setHistory}
                  showDefaultMapView={showDefaultMapView}
                  showAllVehicles={showAllVehicles}
                  panToPrevious={panToPrevious}
                />
                {history[history.length - 1].type === "vehicle" && (
                  <VehicleDetails vehicle={history[history.length - 1].state} />
                )}
                {history[history.length - 1].type === "zone" && (
                  <ZoneDetails zone={history[history.length - 1].state} />
                )}
              </>
            ) : (
              <>
                <MapSidebar
                  city={city}
                  vehicles={vehiclesList}
                  searchVehicle={searchVehicle}
                  handleFilter={handleFilter}
                  clusters={clusters}
                  stations={stations}
                  loading={loading}
                  points={points}
                  refetch={() => {
                    initPoints([])
                    dispatch(onGetVehicles())
                  }}
                  resetView={showDefaultMapView}
                />

                <AdvancedFilter handleFilter={handleFilter} points={points} />
              </>
            )}
          </MobileCanvas>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(Map)
