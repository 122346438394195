import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GET_TOKEN,
  SET_TOKEN,
  // GET_CURRENT_USER,
  // GET_CURRENT_USER_SUCCESS,
  // GET_CURRENT_USER_FAIL,
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = history => {
  return {
    type: SOCIAL_LOGIN,
    payload: { history },
  }
}

export const getToken = () => {
  return {
    type: GET_TOKEN,
  }
}

export const setToken = token => {
  return {
    type: SET_TOKEN,
    payload: { token },
  }
}

// export const getCurrentUser = history => {
//   return {
//     type: GET_CURRENT_USER,
//     payload: { history },
//   }
// }

// export const getCurrentUserSuccess = config => {
//   return {
//     type: GET_CURRENT_USER_SUCCESS,
//     payload: config,
//   }
// }

// export const getCurrentUserFail = error => {
//   return {
//     type: GET_CURRENT_USER_SUCCESS,
//     payload: error,
//   }
// }
