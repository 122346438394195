import React from "react"
import PropTypes from "prop-types"

const TotalPreview = ({ from, to, size, entity, loading }) => {
  return (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of{" "}
      {loading ? (
        <i className="bx bx-hourglass bx-spin" />
      ) : (
        <strong>{size?.toLocaleString()}</strong>
      )}{" "}
      {entity}
    </span>
  )
}

TotalPreview.propTypes = {
  from: PropTypes.number,
  to: PropTypes.number,
  size: PropTypes.number,
  entity: PropTypes.string,
  loading: PropTypes.bool,
}

export default TotalPreview
