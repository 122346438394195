import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import authMessages from "constants/authMessages"
import jwt_decode from "jwt-decode"

// Login Redux States
import { SET_PASSWORD, EDIT_PROFILE, GET_CURRENT_USER } from "./actionTypes"
import {
  setPasswordSuccess,
  setPasswordError,
  profileSuccess,
  profileError,
  getCurrentUserFail,
  getCurrentUserSuccess,
} from "./actions"

import { updateUser, getLoggedInUser } from "helpers/backend_helper"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"

function* updatePassword({ payload: { currentPassword, newPassword } }) {
  const fireBaseBackend = getFirebaseBackend()

  try {
    const response = yield call(
      fireBaseBackend.setUserPassword,
      currentPassword,
      newPassword
    )
    yield put(setPasswordSuccess("Password Updated Successfully"))
  } catch (error) {
    let errMessage = authMessages[error.code] || authMessages["general"]

    yield put(setPasswordError(errMessage))
  }
}

function* updateProfile({ payload: { uid, updates } }) {
  try {
    yield call(updateUser, uid, updates)

    yield put(profileSuccess("Profile Updated Successfully", updates))
  } catch (error) {
    let errMessage = authMessages["general"]

    yield put(profileError(errMessage))
  }
}

function* getCurrentUserData({ payload: { history } }) {
  try {
    const accessToken = localStorage.getItem("accessToken")

    const { role } = jwt_decode(accessToken)
    const viewConfig = yield call(getLoggedInUser)

    yield put(getCurrentUserSuccess(viewConfig.data))
    const { state } = viewConfig.data
    if (state === "pending") {
      history.replace("/profile")
    }
  } catch (error) {
    // TODO refine
    const errorMessage = "Unauthorized"
    yield put(getCurrentUserFail(errorMessage))
  }
}

export function* watchProfile() {
  yield takeEvery(SET_PASSWORD, updatePassword)
  yield takeEvery(EDIT_PROFILE, updateProfile)
  yield takeEvery(GET_CURRENT_USER, getCurrentUserData)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
