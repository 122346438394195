import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Alert, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

import Restricted from "components/Common/Restricted"
import CustomersTable from "components/Customer/customers-table"
import AccessDenied from "pages/Utility/access-denied"
import { useSelector, useDispatch } from "react-redux"
import { getCustomers } from "store/actions"

const CustomersList = () => {
  const dispatch = useDispatch()

  const { error, loading, customers } = useSelector(state => ({
    error: state.customers.error,
    loading: state.customers.loading,
    customers: state.customers.customers,
  }))
  useEffect(() => {
    if (!customers.length) {
      dispatch(getCustomers())
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Employees List</title>
        </MetaTags>
        <Container fluid>
          <Restricted
            to="customers:VIEW_ALL_CUSTOMERS"
            fallback={<AccessDenied />}
          >
            <Breadcrumbs title="Employees" breadcrumbItem="Employees List" />
            {error && (
              <Alert color="danger" role="alert">
                {/* {JSON.stringify(error)} */}
                Something went wrong
              </Alert>
            )}
            {loading && (
              <Alert color="info">
                <i className="bx bx-hourglass bx-spin" /> Fetching rest of
                employees data
              </Alert>
            )}
            <Row>
              <Col lg="12">
                <CustomersTable customers={customers} loading={loading} />
              </Col>
            </Row>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomersList
