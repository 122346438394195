import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//Dashboard
import Dashboard from "./dashboard/reducer"

import vehicles from "./vehicles/reducer"

import trips from "./trips/reducer"

import users from "./users/reducer"
import customers from "./customers/reducer"
import zones from "./zones/reducer"
import audit from "./audit/reducer"
import map from "./map/reducer"
import tasks from "./tasks/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Dashboard,
  vehicles,
  trips,
  users,
  customers,
  zones,
  audit,
  map,
  tasks,
})

export default rootReducer
