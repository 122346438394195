import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
  Col,
  Row,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  InputGroup,
  FormGroup,
  Spinner,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import "react-datepicker/dist/react-datepicker.css"
import { getAllRoles, updateUser, createUser } from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
import { accountStates } from "utils/accountStates"
import { useSelector } from "react-redux"

const FormUser = ({ toggle, user, isEdit, refetch }) => {
  const [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(false)

  const { account } = useSelector(state => ({
    account: state.Profile.account,
  }))

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: (user && user.email) || "",
      role: (user && user.role) || "",
      state: (user && user.state) || "",
      sendInvite: true,
    },
    validationSchema: Yup.object().shape({
      role: Yup.string().required("Role is required"),
      // TODO refine validation
      email: Yup.string("Email must be valid"),
      state: Yup.string("State must be valid"),
    }),
    onSubmit: values => {
      values.email = values.email.trim()
      values.type = "b2bPartner"
      values.b2bPartner = account.b2bPartner

      // validation.resetForm()
      // }
      if (isEdit) {
        values.updatedAt = Date.now()

        submitEdit(values)
      } else {
        values.createdAt = Date.now()
        submitCreate(values)
      }
      // toggle()
    },
  })

  const submitEdit = async values => {
    setLoading(true)
    try {
      await updateUser(user.uid, values)
      showToastMessage({
        success: true,
        message: "User Updated",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Something went wrong",
      })
    }
    setLoading(false)
  }
  const submitCreate = async values => {
    setLoading(true)
    try {
      await createUser(values)

      showToastMessage({
        success: true,
        message: "Invitation Sent",
      })
      toggle()
      refetch({ force: true })
    } catch (error) {
      if (error.response?.data?.messages) {
        showToastMessage({
          success: false,
          message: error.response.data?.messages,
        })
      } else {
        showToastMessage({
          success: false,
          message: "Something went wrong",
        })
      }
    }
    setLoading(false)
  }

  const fetchRoles = async () => {
    if (!roles.length) {
      // TODO move it to dispatch
      const response = await getAllRoles()
      setRoles(Object.values(response.data))
    }
  }

  useEffect(() => {
    fetchRoles()
  }, [])
  return (
    <div>
      <Form
        onSubmit={e => {
          e.preventDefault()
          validation.handleSubmit()
          return false
        }}
      >
        <FormGroup>
          <h4 className="font-size-14 mb-3">Role and Credentials</h4>
          <Row>
            <div className="form-floating mb-3">
              <Input
                type="text"
                className="form-control"
                id="emailInput"
                name="email"
                placeholder="Email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
                disabled={isEdit}
              />
              <Label htmlFor="emailInput" className="ms-2">
                Email
              </Label>
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
          </Row>
          <Row>
            {/* <Col md={6}> */}
            <div className="form-floating mb-3">
              <select
                className="form-select text-capitalize"
                name="role"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.role || ""}
              >
                <option value="" label="Select Role">
                  Select Role
                </option>
                {roles.map((role, i) => (
                  <option value={role.name} className="text-capitalize" key={i}>
                    {role.name.split("_").join(" ")}
                    {/* {role.name} */}
                  </option>
                ))}
              </select>
              <Label htmlFor="floatingSelectGrid" className="ms-2">
                Role
              </Label>
              {validation.touched.role && validation.errors.role ? (
                <FormFeedback type="invalid">
                  {validation.errors.role}
                </FormFeedback>
              ) : null}
            </div>
            {/* </Col> */}
            {/* <Col lg="6"> */}

            {/* </Col> */}
          </Row>

          {isEdit ? (
            <Row>
              {" "}
              <div className="form-floating mb-3">
                <select
                  className="form-select text-capitalize"
                  name="state"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.state || ""}
                >
                  {/* <option value={validation.values.state} label="Select Status">
                    Select Status
                  </option> */}
                  {Object.values(accountStates).map((state, i) => (
                    <option value={state} className="text-capitalize" key={i}>
                      {state}
                      {/* {role.name} */}
                    </option>
                  ))}
                </select>
                <Label htmlFor="floatingSelectGrid" className="ms-2">
                  Status
                </Label>
                {validation.touched.state && validation.errors.state ? (
                  <FormFeedback type="invalid">
                    {validation.errors.state}
                  </FormFeedback>
                ) : null}
              </div>
            </Row>
          ) : null}
        </FormGroup>

        <Row>
          <Col>
            <div className="text-end">
              <Button
                type="submit"
                className="btn btn-dark save-user"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Submit"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

FormUser.propTypes = {
  toggle: PropTypes.any,
  refetch: PropTypes.func,
  user: PropTypes.any,
  isEdit: PropTypes.bool,
}

export default FormUser
