import {
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
} from "./actionTypes"

export const setPassword = (currentPassword, newPassword) => {
  return {
    type: SET_PASSWORD,
    payload: { currentPassword, newPassword },
  }
}

export const setPasswordSuccess = msg => {
  return {
    type: SET_PASSWORD_SUCCESS,
    payload: msg,
  }
}

export const setPasswordError = error => {
  return {
    type: SET_PASSWORD_ERROR,
    payload: error,
  }
}

export const editProfile = (uid, updates) => {
  return {
    type: EDIT_PROFILE,
    payload: { uid, updates },
  }
}

export const profileSuccess = (msg, updates) => {
  return {
    type: PROFILE_SUCCESS,
    payload: { msg, updates },
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getCurrentUser = history => {
  return {
    type: GET_CURRENT_USER,
    payload: { history },
  }
}

export const getCurrentUserSuccess = config => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: config,
  }
}

export const getCurrentUserFail = error => {
  return {
    type: GET_CURRENT_USER_FAIL,
    payload: error,
  }
}
