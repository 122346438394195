/* eslint-disable react/prop-types */
import React from "react"
import { CardBody, Row, Badge, Col } from "reactstrap"
import { getZoneTypeColor } from "components/Zones/common"
const ZoneDetails = ({ zone }) => {
  const pos = [
    {
      title: "Scooters",
      bgColor: "bg-primary bg-soft",
      iconClass: "mdi-human-scooter",
      description: zone.pointOfInterest?.numScooters || 0,
    },
    {
      title: "Bicycles",
      bgColor: "bg-primary bg-soft",
      iconClass: "mdi-bike",
      description: zone.pointOfInterest?.numBicycles || 0,
    },
  ]
  return (
    <>
      {/* <CardBody> */}
      {/* <div> */}
      <CardBody>
        <Row className="mb-3">
          <h2 className="text-center">{zone.zoneName}</h2>
          <h5 className="text-muted text-center text-capitalize">
            <Badge color={getZoneTypeColor(zone.zoneType)}>
              {zone.zoneType} Zone
            </Badge>
          </h5>
          {zone.zoneUnder && (
            <p className="text-muted text-center text-capitalize ">
              Zone Under: {zone.zoneUnder}
            </p>
          )}
        </Row>
        {zone.pointOfInterest && (
          <Row className="m-4">
            {pos.map((point, key) => (
              <Col className="col-6" key={"_li_" + key}>
                <div className="social-source text-center mt-3">
                  <div className="avatar-sm mx-auto mb-3">
                    <span
                      className={
                        "avatar-title rounded-circle " +
                        point.bgColor +
                        " font-size-24"
                      }
                    >
                      <i
                        className={"mdi " + point.iconClass + " text-primary"}
                      ></i>
                    </span>
                  </div>
                  <h5 className="font-size-15">
                    {point.description} {point.title}
                  </h5>
                </div>
              </Col>
            ))}
          </Row>
        )}
      </CardBody>
      {/* </div> */}
      {/* </CardBody> */}
    </>
  )
}

export default ZoneDetails
