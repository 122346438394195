import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"

import useQuery from "hooks/useQuery"
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import UsersList from "./users-list"

import { getDiscounts } from "helpers/backend_helper"

const Settings = () => {
  const query = useQuery()
  const queryTab = query.get("vtab") || "user-management"
  const [verticalActiveTab, setverticalActiveTab] = useState(queryTab)

  const [discounts, setDiscounts] = useState([])
  const [tabLoading, setTabLoading] = useState(false)

  const updateUrl = tab => {
    let url = new URL(window.location.href)
    url.searchParams.set("vtab", tab)
    window.history.replaceState(null, null, url)
  }

  useEffect(() => {
    switch (verticalActiveTab) {
      case "user-management":
        // return fetchUsers()
        return null

      // case "roles":
      //   return fetchRoles()
      // case "invitations":
      //   return null
    }
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Settings | Gazal</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title={verticalActiveTab.split("-").join(" ")}
            breadcrumbItem="Settings"
          />
          <Row>
            <Col lg={12}>
              <UsersList />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Settings
