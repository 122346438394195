export const roles = [
  {
    label: "Admin",
    value: "b2b_partner_admin",
    color: "dark",
    iconClass: "bx bxs-check-circle text-dark",
    badgeClass: "badge badge-soft-dark",
  },
  {
    label: "Operator",
    value: "b2b_partner_operator",
    color: "info",
    iconClass: "bx bxs-check-circle text-info",
    badgeClass: "badge badge-soft-primary",
  },
  {
    label: "User",
    value: "b2b_partner_user",
    color: "info",
    iconClass: "bx bxs-check-circle text-warning",
    badgeClass: "badge badge-soft-primary",
  },
  {
    label: "Tester",
    value: "b2b_partner_tester",
    color: "info",
    iconClass: "bx bxs-check-circle text-secondary",
    badgeClass: "badge badge-soft-primary",
  },
]
