/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import {
  CardBody,
  Row,
  Col,
  DropdownItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import scooter from "../../assets/images/scooter.png"
import bike from "../../assets/images/bike.png"
import {
  convertBikeBattery,
  convertScooterBattery,
  getBatteryVariant,
} from "utils"
import { isEmpty } from "lodash"
import EmptyState from "components/Common/EmptyState"
import { vehicleStatus } from "../../constants/vehicleStatus"
import { updateVehicle } from "helpers/backend_helper"
import { showToastMessage } from "components/Common/ToastWrapper"
import useMobile from "hooks/useMobile"
import { getBatteryIconVariant } from "utils"
import { buzzVehicle, lockVehicle, unlockVehicle } from "helpers/backend_helper"
import { useDispatch } from "react-redux"
import { getVehicles as onGetVehicles } from "store/actions"
import Restricted from "components/Common/Restricted"
import LoadingButton from "components/Common/LoadingButton"
import { getCustomerDetails } from "helpers/backend_helper"

const VehicleDetails = ({ vehicle }) => {
  const isMobile = useMobile()
  // const [fetchVehicles] = useVehicleMap()
  const dispatch = useDispatch()

  const [other_Menu, setother_Menu] = useState(false)
  const [status, setStatus] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [user, setUser] = useState({})
  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }

  const saveEdit = async e => {
    const status = e.target.value

    try {
      // if (status !== vehicle.status) {
      await updateVehicle(vehicle.IMEI, { status })
      // await fetchVehicles()
      dispatch(onGetVehicles())
      setStatus(status)
      // dispatch(onEditVehicleDetails(vehicle.IMEI, { status }))
      showToastMessage({
        success: true,
        message: "State updated successfully",
      })
      // }
    } catch (error) {
      showToastMessage({ success: false, message: "Something went wrong" })
    }

    setIsEdit(false)
  }

  const fetchUserDetails = async userId => {
    // setTimeout(() => {
    try {
      const response = await getCustomerDetails(userId)
      const user = response.data
      setUser(user)
      setShowDetails(true)
    } catch (error) {
      showToastMessage({
        success: false,
        message: "Error fetching user details",
      })
    }
    // }, 1000)
  }

  const showUser = () => (
    <>
      <dt className="col-sm-5">Last User</dt>
      <dd className="col-sm-7 ">
        {user.userName} {user.userSurname}
      </dd>

      <dt className="col-sm-5">Last User Phone</dt>
      <dd className="col-sm-7 ">
        <a href={`tel:${user.countryCode} ${user.contact}`}>
          {user.countryCode} {user.contact}
        </a>
      </dd>
    </>
  )

  useEffect(() => {
    setShowDetails(false)
    setUser({})
  }, [vehicle])
  return (
    <>
      {/* <CardBody> */}
      {/* <div> */}
      {isEmpty(vehicle) ? (
        <EmptyState text="No vehicle found, try another search" />
      ) : (
        <CardBody className="p-4">
          <Row>
            <Col lg={6} xs={8}>
              <h5 className="card-title ">Vehicle Details</h5>
            </Col>
            <Col lg={6} xs={4}>
              <ul className="list-inline user-chat-nav text-end mb-0">
                <li className="list-inline-item">
                  <Dropdown
                    isOpen={other_Menu}
                    toggle={toggleOther}
                    // disabled={loadingDetails || error.message}
                  >
                    {/* TODO extract into a component */}
                    <DropdownToggle
                      className="btn btn-outline-dark"
                      tag="p"
                      onClick={e => e.stopPropagation()}
                    >
                      <span className="d-none d-md-inline">Actions</span>
                      <i className="bx bx-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem header>Hardware Actions</DropdownItem>
                      <Restricted to="commands:LOCK_VEHICLE">
                        <DropdownItem onClick={() => lockVehicle(vehicle.IMEI)}>
                          Lock
                        </DropdownItem>
                      </Restricted>
                      <Restricted to="commands:UNLOCK_VEHICLE">
                        <DropdownItem
                          onClick={() => unlockVehicle(vehicle.IMEI)}
                        >
                          Unlock
                        </DropdownItem>
                      </Restricted>
                      <Restricted to="commands:BUZZ_VEHICLE">
                        <DropdownItem onClick={() => buzzVehicle(vehicle.IMEI)}>
                          Buzz
                        </DropdownItem>
                      </Restricted>
                    </DropdownMenu>
                  </Dropdown>
                </li>
              </ul>
            </Col>
          </Row>
          {isMobile ? (
            <>
              <Row>
                <Col xs={7} className="h-100">
                  <h2 className="text-center">{vehicle.scooterId}</h2>
                  <p className="text-muted text-center text-capitalize ">
                    {vehicle.GPS_Model} {vehicle.vehicleType}
                  </p>
                </Col>

                <Col xs={5} className="m-auto">
                  <div>
                    <img
                      src={vehicle.vehicleType === "scooter" ? scooter : bike}
                      alt=""
                      className="img-fluid mb-4"
                      height="60px"
                      width="60px"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <dl className="mb-0">
                  <dt className="col-sm-3">IMEI</dt>
                  <dd className="col-sm-9">{vehicle.IMEI}</dd>
                  <dt className="col-xs-5">Status</dt>
                  <dd className="col-xs-7">
                    <Restricted to="vehicles:UPDATE_STATUS">
                      <select
                        className="form-select form-select-sm"
                        value={status || vehicle.status}
                        onFocus={() => setIsEdit(true)}
                        onChange={saveEdit}
                      >
                        <option value={vehicle.status} defaultValue>
                          {vehicle.status}
                        </option>
                        {vehicleStatus.map((s, i) => {
                          if (s.value !== vehicle.status) {
                            return (
                              <option key={i} value={s.value}>
                                {s.label}
                              </option>
                            )
                          }
                        })}
                      </select>
                    </Restricted>
                    <Restricted to="vehicles:VIEW_STATUS">
                      {vehicle.status}
                    </Restricted>
                  </dd>
                  <Restricted to="map:VIEW_LAST_USER">
                    {showDetails ? (
                      <></>
                    ) : vehicle.lastUser !== "NIL" ? (
                      <LoadingButton
                        className="my-2"
                        onClick={() => fetchUserDetails(vehicle.lastUser)}
                        // id="moreToggler"
                        color="dark"
                        size="sm"
                        outline
                      >
                        View Last User
                      </LoadingButton>
                    ) : (
                      "No last user"
                    )}
                    {showDetails ? showUser() : <></>}
                  </Restricted>
                </dl>

                {/* <UncontrolledCollapse toggler="#moreToggler"> */}

                {/* </UncontrolledCollapse> */}
              </Row>
              {/* <Row> */}
              <ul className="list-inline user-chat-nav mb-0">
                <li className="list-inline-item">
                  <i
                    className={`${getBatteryIconVariant(
                      convertScooterBattery(vehicle.Battery)
                    )} me-1`}
                  />
                  {convertScooterBattery(vehicle.Battery)} %
                </li>
                <li className="list-inline-item">
                  <i className={`mdi mdi-map-marker me-1`} />
                  {vehicle.stationId}
                </li>
              </ul>
              {/* </Row> */}
            </>
          ) : (
            <>
              <Row className="mt-2">
                <Col lg="7" sm="12">
                  <div>
                    <dl className="text-muted row">
                      <dt className="col-sm-5">IMEI</dt>
                      <dd className="col-sm-7"> {vehicle.IMEI}</dd>
                      <dt className="col-sm-5">Battery</dt>
                      <dd className="col-sm-7">
                        <i
                          className={`mdi mdi-circle text-${
                            vehicle.vehicleType === "scooter"
                              ? getBatteryVariant(
                                  convertScooterBattery(vehicle.Battery)
                                )
                              : getBatteryVariant(
                                  convertBikeBattery(vehicle.lockBattery)
                                )
                          } align-middle me-1`}
                        />
                        {`${
                          vehicle.vehicleType === "scooter"
                            ? convertScooterBattery(vehicle.Battery)
                            : convertBikeBattery(vehicle.lockBattery) || "N/A"
                        }%`}
                      </dd>
                      {/* <dt className="col-xs-5">Last Status Change</dt>
                <dd className="col-xs-7">
                  {" "}
                  {formatStandard(vehicle.lastStatusChange)}
                </dd> */}
                      <dt className="col-sm-5">Station</dt>
                      <dd className="col-sm-7"> {vehicle.stationId}</dd>
                      <dt className="col-sm-5">Status</dt>
                      <dd className="col-sm-7">
                        {" "}
                        <Restricted to="vehicles:UPDATE_STATUS">
                          <select
                            className="form-select form-select-sm"
                            value={status || vehicle.status}
                            onFocus={() => setIsEdit(true)}
                            onChange={saveEdit}
                          >
                            <option value={vehicle.status} defaultValue>
                              {
                                vehicleStatus.find(
                                  v => v.value === vehicle.status
                                )?.label
                              }
                            </option>
                            {vehicleStatus.map((s, i) => {
                              if (s.value !== vehicle.status) {
                                return (
                                  <option key={i} value={s.value}>
                                    {s.label}
                                  </option>
                                )
                              }
                            })}
                          </select>
                        </Restricted>
                        <Restricted to="vehicles:VIEW_STATUS">
                          {vehicle.status}
                        </Restricted>
                      </dd>
                      <Restricted to="map:VIEW_LAST_USER">
                        {showDetails ? (
                          <></>
                        ) : (
                          <dt className="col-sm-5">Last User</dt>
                        )}

                        {vehicle.lastUser !== "NIL" ? (
                          showDetails ? (
                            showUser()
                          ) : (
                            <dd className="col-sm-7">
                              <LoadingButton
                                // className="btn btn-sm btn-outline-dark"
                                color="dark"
                                size="sm"
                                outline
                                onClick={() =>
                                  fetchUserDetails(vehicle.lastUser)
                                }
                              >
                                Show Details
                              </LoadingButton>
                            </dd>
                          )
                        ) : (
                          "No last user"
                        )}
                      </Restricted>
                    </dl>
                  </div>
                </Col>
                <Col
                  lg="5"
                  sm="12"
                  className="align-self-center text-center d-none d-lg-block"
                >
                  <>
                    <h2 className="text-center">{vehicle.scooterId}</h2>
                    <p className="text-muted text-center text-capitalize ">
                      {vehicle.GPS_Model} {vehicle.vehicleType}
                    </p>
                  </>

                  <div>
                    <img
                      src={vehicle.vehicleType === "scooter" ? scooter : bike}
                      alt=""
                      className="img-fluid d-block m-auto"
                      height="100px"
                      width="100px"
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
        </CardBody>
      )}
      {/* </div> */}
      {/* </CardBody> */}
    </>
  )
}

export default VehicleDetails
