import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import LoadingButton from "components/Common/LoadingButton"
import { getBlockReasons, createCustomerBlockLog } from "helpers/backend_helper"
import Select from "react-select"
import { Col, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { editCustomer } from "store/actions"

const FormBlockCustomer = ({ user, refresh, close, showToastMessage }) => {
  const dispatch = useDispatch()
  const [blockReasons, setBlockReasons] = useState([])
  const [selectedReason, setSelectedReason] = useState({})
  const { account } = useSelector(state => ({
    account: state.Profile.account,
  }))
  useEffect(() => {
    const fetchReasons = async () => {
      const res = await getBlockReasons()
      const reasons = res.data
      const selectObj = []
      for (const uuid in reasons) {
        selectObj.push({
          value: uuid,
          label: reasons[uuid].reason,
        })
      }
      setBlockReasons(selectObj)
      setSelectedReason(selectObj[0])
    }

    fetchReasons().catch(_ => {
      showToastMessage({ success: false, message: "Something went wrong" })
    })
  }, [])

  const blockCustomer = async () => {
    if (!selectedReason) {
      showToastMessage({
        success: false,
        message: "Please select a blocking reason",
      })
      return
    }
    const partiallyBlockedAt = user?.partiallyBlockedAt
      ? [...user.partiallyBlockedAt, account.b2bPartner]
      : [account.b2bPartner]

    const update = {
      ...user,
      partiallyBlockedAt: [...new Set(partiallyBlockedAt)],
    }
    // await updateCustomer(user.userId, update)
    dispatch(editCustomer(update))

    await createCustomerBlockLog(user.userId, {
      reason: selectedReason.label,
      isPartial: true,
    })
    showToastMessage({
      success: true,
      message: "Employee Blocked successfully",
    })
    refresh()
    close()
  }

  return (
    <>
      <Row>
        <p className="sub-header">Please select a reason:</p>
        <Col>
          <Select
            value={selectedReason}
            options={blockReasons}
            onChange={newVal => setSelectedReason(newVal)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-end mt-4">
          <LoadingButton className="btn btn-success" onClick={blockCustomer}>
            Submit
          </LoadingButton>
        </Col>
      </Row>
    </>
  )
}

FormBlockCustomer.propTypes = {
  user: PropTypes.object,
  refresh: PropTypes.func,
  close: PropTypes.func,
  showToastMessage: PropTypes.func,
}

export default FormBlockCustomer
