import { call, put, takeEvery } from "redux-saga/effects"

// Trips Redux States
import { GET_TRIPS, GET_ACTIVE_TRIPS } from "./actionTypes"

import {
  getTripsSuccess,
  getTripsFail,
  getActiveTripsSuccess,
  getActiveTripsFail,
} from "./actions"

//Include Both Helper File with needed methods
import { getTrips, getActiveTrips } from "../../helpers/backend_helper"

function* fetchTrips({ IMEI = null, userId = null }) {
  try {
    const response = yield call(getTrips, IMEI, userId)
    yield put(getTripsSuccess(response.data))
  } catch (error) {
    yield put(getTripsFail(error))
  }
}

function* fetchActiveTrips() {
  try {
    const response = yield call(getActiveTrips)
    yield put(getActiveTripsSuccess(response.data)) // TODO - should this be paginated?
  } catch (error) {
    yield put(getActiveTripsFail(error))
  }
}

function* tripsSaga() {
  yield takeEvery(GET_TRIPS, fetchTrips)
  yield takeEvery(GET_ACTIVE_TRIPS, fetchActiveTrips)
}

export default tripsSaga
