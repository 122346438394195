import { call, put, takeEvery } from "redux-saga/effects"

// Vehicles Redux States
import { GET_CUSTOMERS, EDIT_CUSTOMER } from "./actionTypes"

import {
  getCustomersSuccess,
  getCustomersFail,
  editCustomerSuccess,
  editCustomerFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getB2bPartnerCustomers,
  updateCustomer,
} from "../../helpers/backend_helper"

function* fetchCustomers() {
  try {
    const response = yield call(getB2bPartnerCustomers)
    yield put(getCustomersSuccess(response))
  } catch (error) {
    yield put(getCustomersFail(error))
  }
}

function* editCustomer({ updates }) {
  try {
    const response = yield call(updateCustomer, updates.userId, updates)
    yield put(editCustomerSuccess(response.data))
  } catch (error) {
    yield put(editCustomerFail(error))
  }
}

function* customersSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers),
    yield takeEvery(EDIT_CUSTOMER, editCustomer)
}

export default customersSaga
