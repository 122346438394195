import React from "react"
import PropTypes from "prop-types"

import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Alert,
  Spinner,
} from "reactstrap"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import CarouselPage from "./CarouselPage"
import { motion } from "framer-motion"
import { withRouter } from "react-router-dom"

import logo from "assets/images/logo.png"
import { useDispatch, useSelector } from "react-redux"
import { userForgetPassword } from "store/actions"

const Recoverpw2 = props => {
  const dispatch = useDispatch()
  const { forgetError, forgetSuccessMsg, loading } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    loading: state.ForgetPassword.loading,
  }))
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: values => {
      dispatch(userForgetPassword(values, props.history))
    },
  })
  const boxVariant = {
    // hidden: {
    //   x: "-100vw", //move out of the site
    // },
    visible: {
      x: 0, // bring it back to nrmal
      transition: {
        delay: 0.05,
        when: "beforeChildren", //use this instead of delay
        staggerChildren: 0.1, //apply stagger on the parent tag
      },
    },
  }

  const listVariant = {
    hidden: {
      x: 10, //move out of the site
      opacity: 0,
    },
    visible: {
      x: 0, // bring it back to nrmal
      opacity: 1,
    },
  }
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Recover Password 2 | Gazal - B2B Dashboard</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4 bg-white">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <div className="mb-4">
                        <span className="bg-light rounded-pill p-3 font-size-14 font-weight-semibold">
                          <i className="mdi mdi-web me-2 text-primary font-size-20 align-middle" />
                          B2B Portal
                        </span>
                      </div>
                    </div>
                    <div className="my-auto">
                      <div className="mb-4 mb-md-5">
                        <a
                          href="https://gazal.app"
                          className="d-block auth-logo"
                        >
                          <img
                            // style={{ float: "right" }}
                            src={logo}
                            alt=""
                            height="90"
                            className="auth-logo-dark mt-4"
                          />
                        </a>
                      </div>
                      <div>
                        <h5 className="text-primary"> Reset Password</h5>
                      </div>

                      {/* <CardBody className="pt-0"> */}
                      <div>
                        <motion.div
                          variants={boxVariant}
                          animate="visible"
                          initial="hidden"
                        >
                          <motion.div variants={listVariant}>
                            {forgetError && forgetError ? (
                              <Alert
                                color="danger"
                                className="text-center mb-4"
                              >
                                {forgetError}
                              </Alert>
                            ) : null}
                            {forgetSuccessMsg ? (
                              <Alert
                                color="success"
                                className="text-center mb-4"
                              >
                                {forgetSuccessMsg}
                              </Alert>
                            ) : (
                              <div className="text-muted mb-4" role="alert">
                                Enter your email and instructions will be sent
                                to you!
                              </div>
                            )}
                          </motion.div>
                          <Form
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              validation.handleSubmit()
                              return false
                            }}
                          >
                            <motion.div variants={listVariant}>
                              <div className="mb-3">
                                <Label className="form-label">Email</Label>
                                <Input
                                  name="email"
                                  className="form-control"
                                  placeholder="Enter email"
                                  type="email"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.email || ""}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.email &&
                                validation.errors.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </motion.div>
                            <motion.div variants={listVariant}>
                              <div className="text-end">
                                <button
                                  className="btn btn-primary w-md "
                                  type="submit"
                                  disabled={loading}
                                >
                                  {loading ? <Spinner size="sm" /> : ""} Reset
                                </button>
                              </div>
                            </motion.div>
                          </Form>
                          <div className="mt-5 text-center">
                            <p>
                              Remember It ?{" "}
                              <Link
                                to="login"
                                className="fw-medium text-primary"
                              >
                                {" "}
                                Sign In here
                              </Link>{" "}
                            </p>
                          </div>
                        </motion.div>
                      </div>
                      {/* </CardBody> */}
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} GAZAL.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Recoverpw2.propTypes = {
  history: PropTypes.object,
}

export default withRouter(Recoverpw2)
