import React, { useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import {
  CardBody,
  Col,
  Row,
  Badge,
  UncontrolledTooltip,
  Button,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import classNames from "classnames"
import DatePicker from "react-datepicker"
import logoSecondary from "../../assets/images/logo-secondary.png"
import { formatStandard, formatSeconds } from "../../utils/formatDate"
import { tripStatus } from "constants/tripStatus"
import TotalPreview from "components/Common/Pagination/TotalPreview"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import dropbox from "../../assets/images/brands/dropbox.png"
import Modal from "components/Common/Modal"
import overlayFactory from "react-bootstrap-table2-overlay"
import useQuery from "hooks/useQuery"
import { debounce, isEmpty } from "lodash"
import filterFactory, {
  textFilter,
  customFilter,
  selectFilter,
  multiSelectFilter,
  dateFilter,
  Comparator,
} from "react-bootstrap-table2-filter"
import EmptyState from "components/Common/EmptyState"
import FormRefundTrip from "components/Customer/Details/form-refund-trip"
import { showToastMessage } from "components/Common/ToastWrapper"
import CopyToClipboard from "components/Common/CopyToClipboard"

const emptyDataMessage = () => {
  return <EmptyState text="No data found" />
}

const TripsTable = ({
  trips,
  loading,
  isUserView = false,
  stations = [],
  history,
  refetch,
}) => {
  let url = new URL(window.location.href)
  const query = useQuery()
  const queryStartTime = query.get("startDate")

  const queryEndTime = query.get("endTime")

  const sizePerPage = 10
  const page = query.get("page") ? Number(query.get("page")) : 1
  const pageSize = query.get("pageSize")
    ? Number(query.get("pageSize"))
    : sizePerPage

  const [pageInput, setPageInput] = useState(page)
  const [currentPage, setCurrentPage] = useState(page)
  const [currentPageSize, setCurrentPageSize] = useState(pageSize)

  const [refundModal, setRefundModal] = useState(false)
  const [selectedTrip, setSelectedTrip] = useState({})

  const { SearchBar } = Search
  const { ExportCSVButton } = CSVExport

  const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
    <li key={text} role="presentation" className="dropdown-item">
      <a
        tabIndex="-1"
        role="menuitem"
        data-page={page}
        onMouseDown={e => {
          e.preventDefault()
          onSizePerPageChange(page)
          setCurrentPageSize(page)
          url.searchParams.set("pageSize", page)
          window.history.replaceState(null, null, url)
        }}
        // style={{ color: "red" }}
        className="text-dark"
      >
        {text}
      </a>
    </li>
  )
  const customTotal = (from, to, size) => (
    <TotalPreview from={from} to={to} size={size} entity="Trips" />
  )
  const pageOptions = {
    sizePerPage: currentPageSize,
    totalSize: trips?.length, // replace later with size(users),
    custom: true,
    paginationTotalRenderer: customTotal,
    sizePerPageOptionRenderer,
    page: currentPage,
  }
  const defaultSorted = [
    {
      dataField: "pickUpTime", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ]

  let tripIdFilter = () => {}
  let userIdFilter = () => {}
  let startTimeFilter = () => {}
  let endTimeFilter = () => {}
  let statusFilter = () => {}
  let pickUpStationFilter = () => {}
  let dropOffStationFilter = () => {}
  let totalFareFilter = () => {}
  let totalDurationFilter = () => {}
  const tripsListColumns = [
    {
      text: "Status",
      dataField: "status",
      filter: selectFilter({
        options: tripStatus,
        getFilter: filter => {
          statusFilter = filter
        },
      }),
      sort: true,
      classes: function callback(cell, row, rowIndex, colIndex) {
        // const parentTrip = trips.find(trip => trip.tripId === row.tripId);
        if (row?.tripId !== row?.tripRefId) {
          return "bg-light"
        } else if (
          rowIndex <= tripsListColumns.length &&
          trips[rowIndex - 1]?.tripId !== trips[rowIndex - 1]?.tripRefId &&
          row?.tripId === row?.tripRefId
        ) {
          return "bg-success"
        }
      },
      // formatter: (cellContent, trip) => (
      //   <Badge
      //     className={
      //       "font-size-12 badge-soft-" +
      //       tripStatus.find(s => s.value == trip.status)?.color
      //     }
      //     pill
      //   >
      //     {trip.status}
      //   </Badge>
      // ),
      // formatter: function callback(cell, row, rowIndex, colIndex) {
      //   return (
      //     rowIndex <= tripsListColumns.length &&
      //     JSON.stringify(trips.find(trip => trip.tripId === row.tripId))
      //   )
      //   // if (row.tripId !== row.tripRefId) {
      //   //   return "bg-light"
      //   // } else if (
      //   //   rowIndex <= tripsListColumns.length &&
      //   //   tripsListColumns[rowIndex - 1].tripId !==
      //   //     tripsListColumns[rowIndex - 1].tripRefId &&
      //   //   row.tripId === row.tripRefId
      //   // ) {
      //   //   return "bg-success"
      //   // }
      // },
    },

    {
      text: "Trip ID",
      dataField: "tripId",
      sort: true,
      filter: textFilter({
        getFilter: filter => (tripIdFilter = filter),
      }),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => {
        return (
          <h5 className="font-size-14 mb-1">
            <CopyToClipboard text={trip?.tripId}>
              {trip?.tripId}
            </CopyToClipboard>
          </h5>
        )
      },
    },
    {
      text: "Trip Ref ID",
      dataField: "tripRefId",
      sort: true,
      filter: textFilter({
        getFilter: filter => (tripIdFilter = filter),
      }),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => {
        return (
          <h5 className="font-size-14 mb-1">
            <CopyToClipboard text={trip?.tripRefId}>
              {trip?.tripRefId}
            </CopyToClipboard>
          </h5>
        )
      },
    },
    {
      text: "Vehicle ID",
      dataField: "scooterId",
      sort: true,
      filter: textFilter({
        getFilter: filter => (tripIdFilter = filter),
      }),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <h5 className="font-size-14 mb-1">
          <Link to={`/vehicle-detail/${trip.scooterId}`}>{trip.scooterId}</Link>
        </h5>
      ),
    },
    {
      text: "User",
      dataField: "userId",
      sort: true,
      hidden: isUserView,
      filter: textFilter({
        getFilter: filter => {
          userIdFilter = filter
        },
      }),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => {
        return (
          <span
            className="text-success"
            style={{
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Link to={`/customer-detail/${trip.userId}`}>{cellContent}</Link>
          </span>
        )
      },
    },
    {
      text: "Start Time",
      dataField: "pickUpTime",
      // type: date, // TODO add types everywhere
      sort: true,
      filter: dateFilter({
        getFilter: filter => {
          startTimeFilter = filter
        },
        withoutEmptyComparatorOption: true,
        defaultValue: {
          date: queryStartTime ? new Date(Number(queryStartTime)) : "",
          comparator: queryStartTime ? Comparator.GE : Comparator.EQ,
        },
      }),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      filterValue: (cellContent, trip) => new Date(trip.pickUpTime),
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <>
          <h5 className="font-size-14 mb-1">
            {formatStandard(trip.pickUpTime)}
          </h5>
        </>
      ),
    },
    {
      text: "End Time",
      dataField: "dropOffTime",
      filter: dateFilter({
        getFilter: filter => {
          endTimeFilter = filter
        },
        withoutEmptyComparatorOption: true,
        defaultValue: {
          date: queryEndTime ? new Date(Number(queryEndTime)) : "",
          comparator: queryEndTime ? Comparator.LE : Comparator.EQ,
        },
      }),
      sort: true,
      filterValue: (cellContent, trip) => new Date(trip.dropOffTime),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      formatter: (cellContent, trip) => (
        <>
          {trip.dropOffTime !== -1 ? (
            <h5 className="font-size-14 mb-1">
              {formatStandard(trip.dropOffTime)}
            </h5>
          ) : (
            "-"
          )}
        </>
      ),
    },

    {
      text: "Pickup Station",
      dataField: "pickUpStationId",
      filter: selectFilter({
        options: stations,
        getFilter: filter => {
          pickUpStationFilter = filter
        },
      }),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      formatter: (cellContent, trip) => (
        <>
          <h5 className="font-size-14 mb-1">{trip.pickUpStationId}</h5>
        </>
      ),
    },
    {
      text: "Dropoff Station",
      dataField: "dropOffStationId",
      filter: selectFilter({
        options: stations,
        getFilter: filter => {
          dropOffStationFilter = filter
        },
      }),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      formatter: (cellContent, trip) => (
        <>
          {" "}
          {trip.status !== "TRIP_STARTED" ? (
            <h5 className="font-size-14 mb-1">{trip.dropOffStationId}</h5>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Total Fare (SAR)",
      dataField: "totalFare",
      filter: textFilter({
        getFilter: filter => {
          totalFareFilter = filter
        },
      }),
      filterValue: (cellContent, trip) => parseFloat(trip.totalFare / 100),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      formatter: (cellContent, trip) => (
        <>
          {trip.status !== "TRIP_STARTED" ? (
            <>
              <h5 className="font-size-14 mb-1 text-dark">
                {parseFloat(trip.totalFare / 100).toFixed(2)} SAR
              </h5>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Discount",
      dataField: "totalFare",
      filter: textFilter({
        getFilter: filter => {
          totalFareFilter = filter
        },
      }),
      filterValue: (cellContent, trip) => parseFloat(trip.discountAmount / 100),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "" : "bg-light"
      },
      formatter: (cellContent, trip) => (
        <>
          {trip.status !== "TRIP_STARTED" ? (
            <>
              {trip.discountAmount ? (
                <h5 className="font-size-14 mb-1 text-dark">
                  <span>
                    <i className="mdi mdi-sale font-size-16 text-primary" />
                  </span>
                  {parseFloat(trip.discountAmount / 100).toFixed(2)} SAR
                </h5>
              ) : (
                "-"
              )}
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      text: "Total Duration",
      dataField: "totalDuration",
      filter: textFilter({
        getFilter: filter => {
          totalDurationFilter = filter
        },
      }),
      filterValue: (cellContent, trip) =>
        formatSeconds(trip.totalDuration / 1000),
      classes: function callback(cell, row, rowIndex, colIndex) {
        return row.tripId === row.tripRefId ? "bg-success" : "bg-light"
      },
      formatter: (cellContent, trip) => (
        <>
          {trip.status !== "TRIP_STARTED" ? (
            <h5 className="font-size-14 mb-1 text-dark">
              {formatSeconds(trip.totalDuration / 1000)}
            </h5>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, trip) => (
        <ul className="list-inline user-chat-nav text-end mb-0">
          <li className="list-inline-item d-sm-inline-block">
            <a
              type="button"
              color="info"
              className="btn btn-sm btn-soft-primary"
              onClick={() => {
                history.push(
                  `/trip-detail?imei=${trip.IMEI}&start=${
                    trip.bookingTime
                  }&end=${trip.dropOffTime}&tripId=${
                    trip?.tripId || trip?.tripRefId
                  }&userId=${trip.userId}`
                )
              }}
              id={"viewtooltip"}
            >
              <i className="mdi mdi-eye-outline font-size-16" />
            </a>
            <UncontrolledTooltip placement="top" target="viewtooltip">
              View
            </UncontrolledTooltip>
          </li>
          {isUserView && (
            <li className="list-inline-item d-sm-inline-block">
              <UncontrolledDropdown
                className="ms-2"
                id="moretooltip"
                direction="right"
              >
                <DropdownToggle
                  className="btn btn-sm btn-soft-secondary"
                  // color="white"
                  type="button"
                >
                  <i className="mdi mdi-dots-vertical font-size-16"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem header>Finance</DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      setSelectedTrip(trip)
                      setRefundModal(true)
                    }}
                  >
                    Refund
                  </DropdownItem>
                  <DropdownItem header>Trip</DropdownItem>
                  {/* <DropdownItem onClick={() => {}} disabled={trip.status !== "TRIP_STARTED"}>
                    End Ride
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledTooltip placement="top" target="moretooltip">
                More
              </UncontrolledTooltip>
            </li>
          )}
        </ul>
      ),
    },
  ]

  const toggle = () => {
    setRefundModal(!refundModal)
  }

  const clearFilters = () => {
    tripIdFilter("")
    userIdFilter("")
    startTimeFilter("")
    endTimeFilter("")
    statusFilter("")
    pickUpStationFilter("")
    dropOffStationFilter("")
    totalFareFilter("")
    totalDurationFilter("")
  }

  const handleDebouncePageChange = newPage => {
    url.searchParams.set("page", newPage)
    window.history.replaceState(null, null, url)
  }
  const debounceFn = useCallback(debounce(handleDebouncePageChange, 1000), [])
  const handlePageChange = (event, lastPage) => {
    setPageInput(event.target.value)
    const page = event.target.value ? Number(event.target.value) : currentPage
    let newPage
    if (page > lastPage) {
      newPage = lastPage
    } else {
      newPage = page
    }
    setCurrentPage(newPage)
    if (event.target.value) {
      debounceFn(newPage)
    }
  }

  var node = useRef()

  const keyField = "tripId"
  const animatedComponents = makeAnimated()
  return (
    <React.Fragment>
      <Modal
        show={refundModal}
        onCloseClick={toggle}
        title={`Refund Trip #${
          selectedTrip?.tripId || selectedTrip?.tripRefId
        }`}
      >
        <FormRefundTrip
          trip={selectedTrip}
          showToastMessage={showToastMessage}
          close={() => setRefundModal(false)}
          refetch={refetch}
        />
      </Modal>
      {/* <Container fluid> */}
      <Row>
        {/* <Col lg="12"> */}
        {/* <Card> */}
        {/* {children && <CardBody>{children}</CardBody>} */}

        <CardBody>
          <PaginationProvider
            pagination={paginationFactory(pageOptions)}
            keyField={keyField}
            columns={tripsListColumns}
            data={trips}
          >
            {({ paginationProps, paginationTableProps }) => {
              return (
                <ToolkitProvider
                  keyField={keyField}
                  data={trips}
                  columns={tripsListColumns}
                  exportCSV
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      {/* <Row>
                        <button
                          id="PopoverClick"
                          type="button"
                          className="btn btn-outline-dark"
                        >
                          Filters
                        </button>
                      </Row> */}
                      {/* <Row>
                        <ExportCSVButton {...toolkitProps.csvProps}>
                          Export CSV!!
                        </ExportCSVButton>
                      </Row> */}
                      <Row className="mb-2">
                        <Col lg={6}>
                          <PaginationTotalStandalone {...paginationProps} />
                        </Col>
                        <Col>
                          <div className="search-box ms-2 mb-2 float-end">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>

                          {/* <Col>
                          <div className="float-end">
                            <button
                              className="btn btn-light text-decoration-underline text-capitalize m-2"
                              onClick={clearFilters}
                            >
                              clear filters
                            </button>
                          </div> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="float-end">
                            <button
                              className="btn btn-light text-decoration-underline text-capitalize m-2"
                              onClick={clearFilters}
                              disabled
                            >
                              clear filters
                            </button>
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12 mt-4">
                          <div className="table-responsive invert-scroll">
                            <BootstrapTable
                              keyField={keyField}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              // selectRow={selectRow}
                              defaultSorted={defaultSorted}
                              classes={
                                "table align-middle table-nowrap table-hover top-horizontal-scroll"
                              }
                              headerClasses="table-light"
                              bordered={false}
                              striped={false}
                              responsive
                              ref={node}
                              loading={loading}
                              // rowStyle={{ backgroundColor: "red" }}
                              // noDataIndication={emptyDataMessage}
                              // remote={{
                              //   filter: true,
                              //   pagination: true,
                              //   sort: true,
                              //   cellEdit: false,
                              // }}
                              // overlay={overlayFactory({
                              //   spinner: true,
                              //   background: "rgba(192,192,192,0.3)",
                              // })}
                              // pagination={paginationFactory(pageOptions)}
                              filter={filterFactory()}
                              filterPosition="top"
                              // condensed
                              // hover
                            />
                          </div>
                          {trips.length === 0 && (
                            <Row>
                              <Col lg={12} className="text-center m-auto">
                                <img
                                  src={logoSecondary}
                                  alt="logo"
                                  height="100"
                                />
                                <p className="text-muted">No trips found</p>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                      {/* <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row> */}
                      <Row className="justify-content-md-end justify-content-center align-items-center">
                        <Col className="col-md-auto">
                          <div className="d-flex gap-1">
                            <Button
                              color="primary"
                              onClick={() => setCurrentPage(1)}
                              disabled={paginationProps.page === 1}
                            >
                              {"<<"}
                            </Button>
                            <Button
                              color="primary"
                              onClick={() => setCurrentPage(prev => prev - 1)}
                              disabled={paginationProps.page === 1}
                            >
                              {"<"}
                            </Button>
                          </div>
                        </Col>
                        <Col className="col-md-auto d-none d-md-block">
                          Page{" "}
                          <strong>
                            {paginationProps.page} of{" "}
                            {Math.ceil(
                              Number(
                                paginationProps.totalSize /
                                  paginationProps.sizePerPage
                              )
                            )}
                          </strong>
                        </Col>
                        <Col className="col-md-auto">
                          <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={Math.ceil(
                              Number(
                                paginationProps.totalSize /
                                  paginationProps.sizePerPage
                              )
                            )}
                            defaultValue={page}
                            value={pageInput}
                            onChange={e =>
                              handlePageChange(
                                e,
                                Math.ceil(
                                  Number(
                                    paginationProps.totalSize /
                                      paginationProps.sizePerPage
                                  )
                                )
                              )
                            }
                            onBlur={() =>
                              !pageInput && setPageInput(currentPage)
                            }
                          />
                        </Col>

                        <Col className="col-md-auto">
                          <div className="d-flex gap-1">
                            <Button
                              color="primary"
                              onClick={() => setCurrentPage(prev => prev + 1)}
                              disabled={
                                paginationProps.page ===
                                Math.ceil(
                                  Number(
                                    paginationProps.totalSize /
                                      paginationProps.sizePerPage
                                  )
                                )
                              }
                            >
                              {">"}
                            </Button>
                            <Button
                              color="primary"
                              onClick={() =>
                                setCurrentPage(
                                  Math.ceil(
                                    Number(
                                      paginationProps.totalSize /
                                        paginationProps.sizePerPage
                                    )
                                  )
                                )
                              }
                              disabled={
                                paginationProps.page ===
                                Math.ceil(
                                  Number(
                                    paginationProps.totalSize /
                                      paginationProps.sizePerPage
                                  )
                                )
                              }
                            >
                              {">>"}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )
            }}
          </PaginationProvider>
        </CardBody>
        {/* </Card> */}
        {/* </Col> */}
      </Row>
      {/* </Container> */}
    </React.Fragment>
  )
}

TripsTable.propTypes = {
  trips: PropTypes.array,
  children: PropTypes.node,
  loading: PropTypes.bool,
  isUserView: PropTypes.bool,
  stations: PropTypes.array,
  tripFilters: PropTypes.object,
  history: PropTypes.object,
  refetch: PropTypes.func,
}

export default withRouter(TripsTable)
