import React from "react"
import { Card, CardBody, Col } from "reactstrap"
import { Link } from "react-router-dom"
import logoSecondary from "../../assets/images/logo-secondary.png"

const ResellersPreview = () => {
  const resellers = [
    { img: logoSecondary, title: "test", country: "SA", vacancy: 1 },
    { img: logoSecondary, title: "test", country: "SA", vacancy: 3 },
    { img: logoSecondary, title: "test", country: "SA", vacancy: 6 },
    { img: logoSecondary, title: "test", country: "SA", vacancy: 22 },
    { img: logoSecondary, title: "test", country: "SA", vacancy: 8 },
    { img: logoSecondary, title: "test", country: "SA", vacancy: 12 },
  ]
  return (
    <React.Fragment>
      <Col lg={12}>
        <div className="d-flex">
          <h4 className="card-title mb-4 flex-grow-1">New</h4>
          <div>
            <Link to="/resellers/all" className="btn btn-primary btn-sm">
              View All <i className="bx bx-right-arrow-alt"></i>
            </Link>
          </div>
        </div>
      </Col>
      {(resellers || []).map((item, key) => (
        <Col lg={2} key={key}>
          <Card>
            <CardBody className="p-4">
              <div className="text-center mb-3">
                <img src={item.img} alt="" className="avatar-sm" />
                <Link to="/job-details" className="text-body">
                  <h5 className="mt-4 mb-2 font-size-15">{item.title}</h5>
                </Link>
                <p className="mb-0 text-muted">description</p>
              </div>

              <div className="d-flex">
                <p className="mb-0 flex-grow-1 text-muted">
                  <i className="bx bx-map text-body"></i> {item.country}
                </p>
                <p className="mb-0 text-muted">
                  <b>{item.vacancy}</b> Users
                </p>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  )
}

export default ResellersPreview
