/* eslint-disable react/prop-types */
import React, { useState } from "react"
import GoogleMapReact from "google-map-react"
import { defaultStyles } from "./mapStyles"
import { defaultZoom, defaultCenter } from "./constants"

const BaseMap = ({ children, ...props }) => {
  const createMapOptions = maps => {
    return {
      styles: defaultStyles,
      gestureHandling: "greedy",
      // disableDefaultUI: true,
    }
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_MAP_KEY,
        libraries: ["drawing"].join(","),
      }}
      options={createMapOptions}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}
      center={defaultCenter}
      yesIWantToUseGoogleMapApiInternals
      {...props}
    >
      {children}
    </GoogleMapReact>
  )
}

export default BaseMap
