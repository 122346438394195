import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"

import { Spinner, Card, CardBody, Col, Container, Row, Alert } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import "react-datepicker/dist/react-datepicker.css"
import classnames from "classnames"
import ActiveTripsTable from "components/Trip/active-trips-table"
import { getRtdbBackend } from "helpers/rtdb_helper"
import Restricted from "components/Common/Restricted"
import { useSelector, useDispatch } from "react-redux"
import {
  getTrips as onGetTrips,
  getActiveTrips as onGetActiveTrips,
} from "store/trips/actions"
import { getVehicles as onGetVehicles } from "store/vehicles/actions"
import LoadingTable from "components/Loading/loading-table"
import AccessDenied from "pages/Utility/access-denied"
import {
  getCustomerDetails,
  getVehicleDetails,
} from "../../helpers/backend_helper"

const ActiveTripsList = () => {
  const db = getRtdbBackend()
  const [activeTrips, setActiveTrips] = useState([])
  const [loadingActiveTrips, setLoadingActiveTrips] = useState(false)
  const [error, setError] = useState("")

  const { account } = useSelector(state => ({
    account: state.Profile.account,
  }))

  // const { activeTrips, loadingActiveTrips, trips } = useSelector(state => ({
  //   activeTrips: state.trips.activeTrips,
  //   loadingActiveTrips: state.trips.loadingActiveTrips,
  // }))
  // const loadingActiveTrips = false
  const reports = [
    {
      title: "Total Active Trips",
      iconClass: "mdi mdi-map-marker-path",
      description: activeTrips.length || "-",
    },
    {
      title: "Active Scooters",
      iconClass: "mdi mdi-human-scooter",
      description:
        activeTrips.filter(trip => trip?.vehicleType === "scooter").length ||
        "-",
    },
    {
      title: "Active Bikes",
      iconClass: "mdi mdi-bike-fast",
      description:
        activeTrips.filter(trip => trip?.vehicleType !== "scooter").length ||
        "-",
    },
  ]

  // useEffect(() => {
  //   if (activeTrips && !activeTrips.length) {
  //     dispatch(onGetActiveTrips())
  //   }
  // }, [])

  const mapAdditionalFields = async snapVal => {
    const scooterIds = Object.keys(snapVal)
    const activeTrips = Object.values(snapVal)

    const { b2bPartner } = account
    const mapped = await Promise.all(
      activeTrips.map(async (trip, i) => {
        const vehicleResult = await getVehicleDetails(scooterIds[i])
        if (vehicleResult.data?.vehicleConfig?.b2bPartner === b2bPartner) {
          const { vehicleType, Battery, lockBattery, GPS_Status, IMEI } =
            vehicleResult.data

          const userResult = await getCustomerDetails(trip.userId)
          const { contact, countryCode, credits } = userResult.data

          return {
            ...trip,
            countryCode: countryCode,
            contact: contact,
            balance: credits,
            vehicleType,
            battery: vehicleType === "scooter" ? Battery : lockBattery,
            GPS_Status,
            scooterId: scooterIds[i],
            IMEI,
          }
        } else {
          return null
        }
      })
    )

    const sorted = mapped
      .filter(elem => elem)
      .sort((a, b) => b.bookingTime - a.bookingTime)
    setActiveTrips(sorted)

    setLoadingActiveTrips(false)
  }

  useEffect(() => {
    setLoadingActiveTrips(true)
    db.allActiveTrips().on(
      "value",
      snapshot => {
        //
        if (snapshot.exists()) {
          mapAdditionalFields(snapshot.val())
        }
      },
      error => {
        setError(
          "Error loading trips, please contact your administrator. Error code: PDFB113"
        )
        setLoadingActiveTrips(false)
      }
    )
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Active Trips | Gazal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Trips" breadcrumbItem="Active Trips" />
          <Restricted
            to="trips:SEARCH_ACTIVE_TRIPS"
            fallback={<AccessDenied />}
          >
            <Row>
              {reports.map((report, key) => (
                <Col md="4" key={"_col_" + key}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{report.title}</p>
                          <h4 className="mb-0">{report.description}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                          <span className="avatar-title rounded-circle bg-primary">
                            <i
                              className={report.iconClass + " font-size-24"}
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    {error ? (
                      <Alert color="danger">{error}</Alert>
                    ) : loadingActiveTrips ? (
                      <LoadingTable />
                    ) : (
                      <ActiveTripsTable
                        trips={activeTrips}
                        loading={loadingActiveTrips}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Restricted>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ActiveTripsList
