import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  // GET_CURRENT_USER_SUCCESS,
  // GET_CURRENT_USER_FAIL,
  // GET_CURRENT_USER,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  isLoggedIn: false,
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: "",
        isLoggedIn: true,
      }
      break
    case LOGOUT_USER:
      state = { ...state, error: "" }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, error: "", isLoggedIn: false }
      break
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    // case GET_CURRENT_USER:
    //   state = { ...state, loadingConfig: true }
    //   break
    // case GET_CURRENT_USER_SUCCESS:
    //   state = {
    //     ...state,
    //     defaultRoute: action.payload.roleData.defaultRoute,
    //     allowedRoutes: action.payload.roleData.allowedRoutes,
    //     permissions: action.payload.roleData.permissions,
    //     account: action.payload,
    //     loadingConfig: false,
    //   }
    //   break
    // case GET_CURRENT_USER_FAIL:
    //   state = {
    //     ...state,
    //     error: action.payload,
    //     loadingConfig: false,
    //   }
    //   break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
