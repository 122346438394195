import {
  GET_TRIPS,
  GET_TRIPS_FAIL,
  GET_TRIPS_SUCCESS,
  GET_ACTIVE_TRIPS,
  GET_ACTIVE_TRIPS_SUCCESS,
  GET_ACTIVE_TRIPS_FAIL,
} from "./actionTypes"

export const getTrips = (IMEI, userId) => ({
  type: GET_TRIPS,
  IMEI,
  userId,
})

export const getTripsSuccess = trips => ({
  type: GET_TRIPS_SUCCESS,
  payload: trips,
})

export const getTripsFail = error => ({
  type: GET_TRIPS_FAIL,
  payload: error,
})

export const getActiveTrips = () => ({
  type: GET_ACTIVE_TRIPS,
})

export const getActiveTripsSuccess = activeTrips => ({
  type: GET_ACTIVE_TRIPS_SUCCESS,
  payload: activeTrips,
})

export const getActiveTripsFail = error => ({
  type: GET_ACTIVE_TRIPS_FAIL,
  payload: error,
})
