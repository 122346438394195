import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

export const formatStandard = date => {
  return dayjs(date).format("DD MMM YYYY hh:mm A")
}

export const formatTime = date => {
  return dayjs(date).format("hh:mm:ss A")
}

export const getTimeDifference = date => {
  return dayjs(date).fromNow()
}

export const getCurrentDuration = date => {
  return dayjs().diff(dayjs(date), "second")
}

export const addToCurrentTime = (value, unit = "m") => {
  const newTime = dayjs().add(value, unit).valueOf()
  return newTime
}

export const addToTime = (from, to, unit = "m") => {
  const newTime = dayjs(from).add(to, unit).valueOf()
  return newTime
}

function pad(val) {
  let valString = val + ""
  if (valString.length < 2) {
    return "0" + valString
  } else {
    return parseInt(valString)
  }
}
export const formatSeconds = totalSeconds => {
  const hours = parseInt(totalSeconds / 3600)
  const calcMinutes = totalSeconds / 60
  const minutes = parseInt(calcMinutes > 59 ? calcMinutes % 60 : calcMinutes)
  const seconds = totalSeconds % 60

  const hoursStr = hours >= 1 ? `${hours} hour${hours >= 1 ? "s, " : ", "}` : ""
  const minutesStr =
    minutes >= 1 ? `${pad(minutes)} min${minutes > 1 ? "s, " : ", "}` : ""
  const durationStr = `${hoursStr}${minutesStr} ${pad(seconds)} s`

  return durationStr
}
