/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import { Row, Col, Input, Label, InputGroup } from "reactstrap"
import { vehicleStatus } from "../../constants/vehicleStatus"
import Restricted from "components/Common/Restricted"
const AdvancedFilter = props => {
  const { handleFilter, points } = props

  const [filtersCount, setFiltersCount] = useState(0)
  const [batterySubmitDisabled, setBatterySubmitDisabled] = useState(true)
  const selectInputRef = useRef()

  const allRef = useRef()
  const scooterRef = useRef()
  const bikeRef = useRef()

  const animatedComponents = makeAnimated()

  const defaultFilters = {
    vehicleType: "",
    isRatedOne: null,
    inStation: null,
    vehicleStatus: null,
    startBattery: 0,
    endBattery: 100,
  }

  const [filters, setFilters] = useState(defaultFilters)

  const filterData = newFilters => {
    const activeFilters = Object.fromEntries(
      Object.entries(newFilters).filter(([_, v]) => v != null)
    )

    setFiltersCount(Object.values(activeFilters).length)
    handleFilter(newFilters)
  }
  const handleSelectChange = (e, { name }) => {
    let newFilters = { ...filters, [name]: e }
    setFilters(prev => ({
      ...prev,
      [name]: e,
    }))
    filterData(newFilters)
  }

  const handleCheckChange = e => {
    let newFilters = []
    if (e.target.checked) {
      newFilters = { ...filters, [e.target.name]: e.target.value }
      setFilters(newFilters)
    }
    // else {
    //   newFilters = { ...filters, [e.target.name]: null }
    //   setFilters(newFilters)
    // }

    filterData(newFilters)
  }

  const clearFilters = () => {
    if (selectInputRef?.current) {
      selectInputRef?.current?.select?.clearValue()
    }

    allRef.current.checked = false
    scooterRef.current.checked = false
    bikeRef.current.checked = false

    filterData(defaultFilters)
    setFilters(defaultFilters)
  }

  const handleNumberChange = e => {
    if (batterySubmitDisabled) {
      setBatterySubmitDisabled(false)
    }
    let newFilters = { ...filters, [e.target.name]: parseInt(e.target.value) }
    setFilters(newFilters)
  }

  return (
    <div>
      <Row className="">
        <Col lg={12}>
          <div className="p-2">
            <Restricted to="map:FILTER_VEHICLE_TYPE">
              <Row className="g-3">
                <Col xxl={12} lg={12}>
                  <div>
                    <Label
                      htmlFor="vehicleType"
                      className="form-label fw-semibold"
                    >
                      Vehicle Type
                    </Label>
                  </div>

                  <div className="d-flex flex-wrap gap-2">
                    <input
                      type="radio"
                      className="btn-check"
                      id="btncheck0"
                      autoComplete="off"
                      name="vehicleType"
                      onChange={handleCheckChange}
                      value={""}
                      ref={allRef}
                      // checked={filters.vehicleType === ""}
                    />
                    <label
                      className="btn btn-outline-secondary"
                      htmlFor="btncheck0"
                    >
                      <i className="mdi mdi-car-multiple font-size-16 me-2" />
                      All
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      id="btncheck1"
                      autoComplete="off"
                      name="vehicleType"
                      onChange={handleCheckChange}
                      value="scooter"
                      ref={scooterRef}
                      // defaultChecked
                    />
                    <label
                      className="btn btn-outline-secondary"
                      htmlFor="btncheck1"
                    >
                      <i className="mdi mdi-scooter font-size-16 me-2" />
                      Scooter
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      id="btncheck2"
                      autoComplete="off"
                      name="vehicleType"
                      onChange={handleCheckChange}
                      value="bicycle"
                      ref={bikeRef}
                    />
                    <label
                      className="btn btn-outline-secondary"
                      htmlFor="btncheck2"
                    >
                      <i className="mdi mdi-bike font-size-16 me-2" />
                      Bike
                    </label>
                  </div>
                </Col>
              </Row>
            </Restricted>
            <Restricted to="map:FILTER_VEHICLE_BATTERY">
              <Row className="mt-3">
                <Col xxl={12} lg={12}>
                  <div>
                    <Label
                      htmlFor="batteryLevel"
                      // className="form-label fw-semibold"
                    >
                      Battery Level (%)
                    </Label>
                    <InputGroup className="gap-2 d-flex align-items-center">
                      <Input
                        className="form-control"
                        type="number"
                        name="startBattery"
                        value={filters.startBattery}
                        onChange={handleNumberChange}
                        min={0}
                        max={100}
                      />

                      <div className="m-3 text-muted font-size-12">TO</div>
                      <Input
                        className="form-control"
                        type="number"
                        name="endBattery"
                        value={filters.endBattery}
                        onChange={handleNumberChange}
                        min={0}
                        max={100}
                      />
                      <button
                        className="btn btn-white"
                        disabled={batterySubmitDisabled}
                        onClick={() => filterData(filters)}
                      >
                        <i className="mdi mdi-magnify font-size-18 text-primary" />
                      </button>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
            </Restricted>
            <Restricted to="map:FILTER_VEHICLE_STATUS">
              <Row className="mt-3">
                <Col xxl={12} lg={12}>
                  <div>
                    <Label
                      htmlFor="vehicleStatus"
                      // className="form-label fw-semibold"
                    >
                      Vehicle Status
                    </Label>
                  </div>

                  <Select
                    key={`select-${filters["vehicleStatus"]}`}
                    value={filters["vehicleStatus"] || null}
                    ref={selectInputRef}
                    options={vehicleStatus}
                    isMulti
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                    name="vehicleStatus"
                    className="font-size-14"
                    onChange={handleSelectChange}
                  />
                </Col>
              </Row>
            </Restricted>

            <hr />
            <Row className="my-3">
              <Col>
                {filtersCount ? (
                  <>
                    Found <strong>{points.length}</strong> vehicles
                  </>
                ) : null}
              </Col>
              <Col>
                <div className="d-flex align-items-center">
                  <div className="card-title flex-grow-1"></div>
                  <div className="flex-shrink-0">
                    <a
                      href="#!"
                      className="btn btn-light me-1 text-decoration-underline"
                      onClick={() => {
                        clearFilters()
                      }}
                    >
                      Clear Filters
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default AdvancedFilter
