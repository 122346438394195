import PropTypes from "prop-types"
import React, { useState } from "react"
import { Col, Modal, ModalBody, Row, Card, CardBody } from "reactstrap"
import ChangePasswordForm from "./ChangePasswordForm"
import logo from "../../assets/images/logo-primary.png"
import { updateUser } from "helpers/backend_helper"
import { useSelector } from "react-redux"
import ChangeUserDetailsForm from "./ChangeUserDetailsForm"
import { motion } from "framer-motion/dist/es/index"
import { Link } from "react-router-dom"

const WelcomeModal = () => {
  const steps = ["profile", "password", "complete"]
  const [activeStep, setActiveStep] = useState(0)

  const { account, defaultRoute } = useSelector(state => ({
    account: state.Profile.account,
    defaultRoute: state.Profile.defaultRoute,
  }))
  const [show, setShow] = useState(true)
  const toggle = async () => {
    setShow(!show)
    const updates = { state: "active", verifiedAt: Date.now() }
    await updateUser(account.uid, updates)
  }

  const loadNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(prev => prev + 1)
    } else {
      toggle()
    }
  }

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      centered={true}
      className="p-0 border-none"
    >
      <React.Fragment>
        <div className="">
          <Row className="justify-content-center">
            <Col>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft p-2">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h3 className="text-primary">Welcome!</h3>
                        <h5 className="text-primary">Get Started with Gazal</h5>
                      </div>
                    </Col>

                    <Col className="col-5">
                      <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        animate={{ opacity: 1, x: 10 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 1.2 }}
                      >
                        <img src={logo} alt="" className="img-fluid m-2" />
                      </motion.div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mt-2">
                  <div className="d-flex gap-4 align-items-center justify-content-center">
                    <div className="avatar-md profile-user-wid ms-4">
                      <span
                        className={`avatar-title rounded-circle ${
                          activeStep === 0
                            ? "bg-primary"
                            : activeStep < 0
                            ? "bg-light"
                            : "border border-primary bg-light"
                        }`}
                      >
                        <i
                          className={`mdi mdi-account ${
                            activeStep === 0 ? "text-light" : "text-dark"
                          } font-size-24`}
                        />
                      </span>
                    </div>
                    <div className="avatar-md profile-user-wid ms-4">
                      <span
                        className={`avatar-title rounded-circle ${
                          activeStep === 1
                            ? "bg-primary"
                            : activeStep < 1
                            ? "bg-light"
                            : "border border-primary bg-light"
                        }`}
                      >
                        <i
                          className={`mdi mdi-lock ${
                            activeStep === 1 ? "text-light" : "text-dark"
                          } font-size-24`}
                        />
                      </span>
                      {/* <div className="text-center m-2">
                        {" "}
                        {activeStep <= 1 ? null : (
                          <i className="mdi mdi-check text-primary" />
                        )}
                      </div> */}
                    </div>

                    <div className="avatar-md profile-user-wid ms-4 ">
                      <span
                        className={`avatar-title rounded-circle ${
                          activeStep === 2
                            ? "bg-primary"
                            : activeStep < 2
                            ? "bg-light"
                            : "border border-primary bg-light"
                        }`}
                      >
                        <i
                          className={`mdi mdi-check ${
                            activeStep === 2 ? "text-light" : "text-dark"
                          } font-size-24`}
                        />
                      </span>
                    </div>
                  </div>
                  {/* <h4 className="card-title mb-4">Change Password</h4> */}
                </CardBody>
                {activeStep === 0 ? (
                  <CardBody>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.4 }}
                    >
                      <div className="card-title ms-2">Profile</div>
                      <ChangeUserDetailsForm loadNextStep={loadNextStep} />
                    </motion.div>
                  </CardBody>
                ) : null}
                {activeStep === 1 ? (
                  <CardBody>
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.4 }}
                    >
                      <div className="card-title ms-2">Change Password</div>
                      <ChangePasswordForm loadNextStep={loadNextStep} />
                    </motion.div>
                  </CardBody>
                ) : null}
                {activeStep === 2 ? (
                  <CardBody className="d-flex align-items-center justify-content-center  text-center">
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.4 }}
                    >
                      <div className="h4">Success! </div>
                      <div className="text-muted mb-4">
                        Your account has been set up
                      </div>
                      <div>
                        <Link
                          className="btn btn-primary w-md"
                          // type="submit"
                          // disabled={!validation.dirty || !validation.isValid || loading}
                          to={defaultRoute}
                          onClick={toggle}
                        >
                          Go to Home
                        </Link>
                      </div>
                    </motion.div>
                  </CardBody>
                ) : null}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </Modal>
  )
}

// ConfirmModal.propTypes = {
//   onCloseClick: PropTypes.func,
//   onConfirmClick: PropTypes.func,
//   confirmContents: PropTypes.object,
//   show: PropTypes.any,
// }

export default WelcomeModal
