import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAIL,
  DEACTIVATE_CUSTOMER,
  ACTIVATE_CUSTOMER,
} from "./actionTypes"

const INIT_STATE = {
  customers: [],
  loading: false,
  error: null,
  pageInfo: {},
  editSuccess: false,
}

const customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload.data,
        pageInfo: action.payload.pageInfo,
        loading: false,
      }
    case GET_CUSTOMERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case EDIT_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: state.customers.map(customer =>
          customer.userId.toString() === action.payload.userId.toString()
            ? { ...customer, ...action.payload }
            : customer
        ),
        loading: false,
        editSuccess: true,
      }
    case EDIT_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        editSuccess: false,
      }
    case DEACTIVATE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(
          customer => customer.userId !== action.payload.userId.toString()
        ),
      }
    case ACTIVATE_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.payload],
      }
    default:
      return state
  }
}

export default customers
